import { Helmet } from 'react-helmet-async';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import Form from './form';
import '../booking-flow.scss';

export default function BookingPetInformation() {
  return (
    <>
      <Helmet title="Pet information for booking | PawCare" />
      <div className="pet-information flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <Form />
        </MainPanel>
      </div>
    </>
  );
}
