import { useState, useEffect, FormEvent } from 'react';

import Button from '@/atoms/button';
import Input from '@/atoms/input';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { isEmailAddress, isNotEmptyArray } from '@/util';
import { editUser, loadUser } from '@/actions/user';
import { useNavigate } from 'react-router-dom';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';

export default function CompleteSignupDetails({
  showAppointmentPanel,
}: {
  showAppointmentPanel: boolean;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useAppSelector((state) => state.user);
  const requestAppointmentForm = useAppSelector(
    (state) => state.requestAppointmentForm,
  );
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const canContinue =
    email &&
    isEmailAddress(email) &&
    password &&
    confirmPassword &&
    password === confirmPassword;
  const { isLoadingUser, data: userData } = user;
  const {
    animals,
    email: userEmail,
    phone: { number: userPhoneNumber },
    isIncomplete,
  } = userData;

  if (!isIncomplete) {
    navigate(localStorage.getItem('homepageUrl') || '/');
  }

  useEffect(() => {
    if (userEmail !== `${userPhoneNumber}@mail.com`) setEmail(userEmail);
  }, [userEmail]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!canContinue) return;

    // User was requesting an appointment
    if (showAppointmentPanel) {
      sessionStorage.setItem(
        'requestAppointmentForm',
        JSON.stringify(requestAppointmentForm),
      );
    }

    dispatch(requestOngoing());
    const editUserRequest = await dispatch(editUser({ email, password }));
    dispatch(requestFulfilled());

    if (editUserRequest?.success) {
      dispatch(requestOngoing());
      await dispatch(loadUser());
      dispatch(requestFulfilled());

      if (isNotEmptyArray(animals)) {
        navigate(localStorage.getItem('homepageUrl') || '/');
      } else navigate('/create-pet');
    }
  };

  if (isLoadingUser) return <div>Loading...</div>;

  return (
    <div className="signup-complete">
      <h1 className="mobile-page-title mb-32">Complete account</h1>
      <form onSubmit={onSubmit}>
        <div className="mobile-page-container flex-column gap-24">
          <Input
            id="email"
            label="Email"
            type="email"
            onChange={(value) => setEmail(value)}
            value={email}
            variant="white"
            className="mt-0 w-100"
            autoComplete="email"
          />
          <Input
            id="password"
            label="Password"
            type="password"
            onChange={(value) => setPassword(value)}
            value={password}
            variant="white"
            className="mt-0 w-100"
          />
          <Input
            id="confirm-password"
            label="Confirm password"
            type="password"
            onChange={(value) => setConfirmPassword(value)}
            value={confirmPassword}
            variant="white"
            className="mt-0 w-100"
          />
        </div>
        <div className="mobile-next-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            type="submit"
            className="mt-35"
          />
        </div>
      </form>
    </div>
  );
}
