import './button.scss';

import { useAppSelector } from '@/hooks';
import { Link } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import classNames from 'classnames';
import { MouseEvent, ReactNode } from 'react';
import Icon from '@/atoms/icon';
import { IconName } from '../icon/icons';

export type ButtonVariant =
  | 'default'
  | 'reverse'
  | 'reverse-white'
  | 'reverse-orange'
  | 'reverse-disabled'
  | 'reverse-white-green'
  | 'light-gray'
  | 'disabled'
  | 'transparent'
  | 'green'
  | 'link'
  | 'white-outlined';

export type ButtonSize = 'large' | 'medium' | 'small' | 'extra-small';

interface ButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  shape?: 'round' | 'square';
  text?: string;
  className?: string;
  icon?: IconName;
  type?: 'button' | 'submit' | 'reset';
  to?: string;
  iconPosition?: 'start' | 'end';
  iconProps?: object;
  displayLoaderIcon?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  customElement?: ReactNode;
  disableLoadingState?: boolean;
}

export default function Button({
  size = 'large',
  variant = 'default',
  shape = 'round',
  text,
  className,
  icon,
  iconProps,
  type = 'button',
  to,
  iconPosition = 'end',
  customElement,
  displayLoaderIcon,
  disableLoadingState,
  disabled,
  ...props
}: ButtonProps) {
  const { requestLoading } = useAppSelector((state) => state.ui);

  const onClick = (e: MouseEvent) => {
    if (props.onClick) {
      e.stopPropagation();
      props.onClick();
    }
  };

  const buttonVariant = () => {
    if ((requestLoading && !disableLoadingState) || disabled) {
      if (variant === 'default') {
        return 'disabled';
      }
      if (variant === 'reverse') {
        return 'reverse-disabled';
      }
      if (variant === 'green') {
        return 'green-disabled';
      }
      if (variant === 'link') {
        return 'link-disabled';
      }
      if (variant === 'white-outlined') {
        return 'white-outlined-disabled';
      }
      return 'disabled';
    }

    return variant;
  };

  const Element = to ? Link : 'button';

  return (
    <Element
      type={type || 'text'}
      className={classNames(
        'button flex-row',
        `button--${size}`,
        `button--${shape}`,
        `button--${buttonVariant()}`,
        className,
      )}
      onClick={onClick}
      to={to || ''}
    >
      {icon && iconPosition === 'start' && (
        <Icon name={icon} className="mr-10" {...iconProps} />
      )}
      {customElement && iconPosition === 'start' && customElement}
      {text}
      {icon &&
        iconPosition === 'end' &&
        !displayLoaderIcon &&
        !requestLoading && (
          <Icon name={icon} className="ml-10" {...iconProps} />
        )}
      {displayLoaderIcon && requestLoading && <CircularProgress />}
      {customElement && iconPosition === 'end' && customElement}
    </Element>
  );
}
