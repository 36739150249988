import { DateInput } from '@/atoms/date-input';
import Dropdown from '@/atoms/dropdown';
import { useAppSelector } from '@/hooks';
import { Vaccination } from '@/types/composite';
import { Fragment } from 'react/jsx-runtime';

export function VaccinationList({
  selectedVaccinations,
  setSelectedVaccinations,
}: {
  selectedVaccinations: Vaccination[];
  setSelectedVaccinations: (vaccinations: Vaccination[]) => void;
}) {
  const { availableVaccinations } = useAppSelector(
    (state) => state.bookingFlow,
  );

  const vaccinationIdsList = selectedVaccinations
    ?.map((vaccination: any) =>
      vaccination?.id && vaccination?.value ? vaccination.id : null,
    )
    .filter((vaccination) => !!vaccination);

  return (
    <div className="vaccination-list grid grid-cols-3 gap-16">
      {selectedVaccinations?.map((vaccination, index: number) => (
        <Fragment key={vaccination.uuid}>
          <Dropdown
            label={index === 0 ? 'Type' : ''}
            placeholder="Vaccination"
            className="col-span-2"
            variant="white"
            items={availableVaccinations.filter(
              (vaccinationOption: any) =>
                vaccinationOption?.id === vaccination?.id ||
                !vaccinationIdsList.includes(vaccinationOption?.id),
            )}
            onClick={(value) => {
              const updatedVaccination = {
                ...selectedVaccinations?.[index],
                ...value,
              };
              const selectedVaccinationsCopy = selectedVaccinations?.slice(0);
              selectedVaccinationsCopy[index] = updatedVaccination;
              return setSelectedVaccinations(selectedVaccinationsCopy);
            }}
            value={vaccination?.id && vaccination?.value ? vaccination : null}
            noWrap
          />
          <DateInput
            label={index === 0 ? 'Expiration date' : ''}
            className="col-span-1"
            placeholder="Expiration date"
            onChange={(date: string | null) => {
              const updatedVaccination = {
                ...selectedVaccinations?.[index],
                expirationDate: date,
              };
              const selectedVaccinationsCopy = selectedVaccinations?.slice(0);
              selectedVaccinationsCopy[index] = updatedVaccination;
              return setSelectedVaccinations(selectedVaccinationsCopy);
            }}
            value={
              vaccination?.expirationDate ? vaccination.expirationDate : null
            }
            variant="white"
          />
        </Fragment>
      ))}
    </div>
  );
}
