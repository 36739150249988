import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { editForm, resetForm } from '@/store/reducers/booking-form';
import Button from '@/atoms/button';
import { isNotEmptyArray } from '@/util';
import { ServiceType } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import SelectableItem from '../components/selectable-item';
import useBookingFlow from '../hooks/use-booking-flow';
import '../booking-flow.scss';
import { getIcon } from './util';

export default function BookingServiceType() {
  const { processNextStep } = useBookingFlow();
  const dispatch = useAppDispatch();
  const { availableServiceTypes } = useAppSelector(
    (state) => state.bookingFlow,
  );
  const { serviceType: selectedServiceType } = useAppSelector(
    (state) => state.bookingForm,
  );
  const canContinue = !!selectedServiceType;

  const onSelectServiceType = (serviceType: ServiceType) => {
    if (selectedServiceType !== serviceType) {
      dispatch(resetForm({ keysToPreserve: ['location', 'animal'] }));
    }
    dispatch(editForm({ key: 'serviceType', value: serviceType }));
  };

  const onNext = async () => {
    if (!canContinue) return;
    await processNextStep();
  };

  return (
    <>
      <Helmet title="Select service type for booking | PawCare" />
      <div className="service-type flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32">
            <h2 className="f26 bold">What would you like to book?</h2>
            {isNotEmptyArray(availableServiceTypes) ? (
              <>
                <div className="flex-column gap-12">
                  {availableServiceTypes.map((serviceType) => (
                    <SelectableItem
                      key={serviceType}
                      onClick={() => onSelectServiceType(serviceType)}
                      isActive={serviceType === selectedServiceType}
                      size="large"
                      className="flex-row gap-16"
                    >
                      {getIcon(serviceType)}
                      <p className="f16 bold py-12 text-black">{serviceType}</p>
                    </SelectableItem>
                  ))}
                </div>
                <Button
                  text="Next"
                  onClick={onNext}
                  size="large"
                  className="desktop-next-btn w-fit"
                  variant={canContinue ? 'default' : 'disabled'}
                />
              </>
            ) : (
              <p className="f16 fw-400">No services available</p>
            )}
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            onClick={onNext}
            className="mt-25"
          />
        </div>
      </div>
    </>
  );
}
