import './booking-flow-loader.scss';

export default function BookingFlowLoader() {
  return (
    <div className="booking-flow-loader flex-column gap-32 w-100 max-w-600">
      <div className="title-loader" />
      <div className="flex-column gap-12">
        <div className="item-loader w-100" />
        <div className="item-loader w-100" />
        <div className="item-loader w-100" />
      </div>
      <div className="button-loader" />
    </div>
  );
}
