function Info({ fill = '#9191a8', width = 16, height = 16 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 10 11"
    >
      <path
        fill={fill}
        d="M5 1.438a4.063 4.063 0 1 0 0 8.125 4.063 4.063 0 0 0 0-8.126Zm0 7.5a3.438 3.438 0 1 1 0-6.876 3.438 3.438 0 0 1 0 6.876Zm.625-1.563a.312.312 0 0 1-.313.313.625.625 0 0 1-.625-.625V5.5a.312.312 0 1 1 0-.625.625.625 0 0 1 .625.625v1.563a.312.312 0 0 1 .313.312Zm-1.25-3.594a.469.469 0 1 1 .937 0 .469.469 0 0 1-.937 0Z"
      />
    </svg>
  );
}

export default Info;
