import Button from '@/atoms/button';
import { isNotEmptyArray } from '@/util';
import dayjs from 'dayjs';

export default function DateTimeSlots({
  date,
  timeSlots,
  onSelectSlot,
}: {
  date: string;
  timeSlots: string[];
  onSelectSlot: (slot: string) => void;
}) {
  if (!date || !isNotEmptyArray(timeSlots))
    return <p className="f16 fw-400">No time slots available</p>;

  return (
    <div className="grid grid-cols-autofill-minmax-150-1fr gap-x-12 gap-y-8">
      {timeSlots.map((time) => {
        const formattedTime = dayjs(`2000-01-01T${time}`).format('h:mm A');

        return (
          <Button
            key={time}
            onClick={() => onSelectSlot(time)}
            size="medium"
            text={formattedTime}
            className="w-100"
          />
        );
      })}
    </div>
  );
}
