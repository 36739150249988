import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import Checkout from '@/organisms/checkout';
import Landing from '@/organisms/landing';
import CompleteSignupDetails from './complete-signup-details';

export default function CompleteSignup() {
  const [searchParams] = useSearchParams();
  const showAppointmentPanel = searchParams.get('appointment');

  const LayoutComponent = showAppointmentPanel ? Checkout : Landing;

  return (
    <>
      <Helmet title="Complete account | PawCare">
        <link rel="canonical" href="https://mypawcare.com/signup" />
      </Helmet>
      <LayoutComponent removeOutterPadding>
        <CompleteSignupDetails showAppointmentPanel={!!showAppointmentPanel} />
      </LayoutComponent>
    </>
  );
}
