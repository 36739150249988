import { useAppDispatch, useAppSelector } from '@/hooks';
import { capitalizeFirstLetter } from '@/util';
import { useEffect, useState } from 'react';
import { invoiceCheck } from '@/actions/booking';
import { Invoice, Policy } from '@/types/composite';
import AppointmentDate from './appointment-date';
import { formatDuration } from '../utils';
import ProviderTitle from './provider-title';
import InvoiceDetails from './invoice-details';

export default function AppointmentDetails() {
  const dispatch = useAppDispatch();
  const {
    location,
    animal,
    service,
    addOns,
    dateStart,
    dateEnd,
    serviceType,
    locationUser,
  } = useAppSelector((state) => state.bookingForm);
  const { durationMinutes } = service || {};
  const [isLoading, setIsLoading] = useState(false);
  const [invoiceData, setInvoiceData] = useState<{
    invoice: Invoice | null;
    policy: Policy | null;
  } | null>(null);
  const formattedDuration = formatDuration({
    durationMinutes: durationMinutes || null,
    serviceType: serviceType || null,
  });

  useEffect(() => {
    const fetchInvoiceCheck = async () => {
      setIsLoading(true);
      const invoiceCheckCall = await dispatch(invoiceCheck());
      if (invoiceCheckCall?.success) {
        const invoice = invoiceCheckCall.data?.data;
        setInvoiceData(invoice);
      } else {
        setInvoiceData(null);
      }
      setIsLoading(false);
    };

    fetchInvoiceCheck();
  }, [location?.uuid, animal?.uuid, service?.uuid, addOns, dateStart, dateEnd]);

  return (
    <div className="flex-column gap-24">
      <ProviderTitle />

      <div className="flex-column gap-8">
        <div className="flex-column">
          <h3 className="f18 bold">Appointment details</h3>
          <AppointmentDate size="small" />
          {serviceType === 'Grooming' && (
            <p className="f16 fw-400 text-primary-purple">
              {locationUser?.user?.firstName
                ? `${capitalizeFirstLetter(locationUser.user.firstName)} (${formattedDuration})`
                : 'Groomer not selected'}
            </p>
          )}
        </div>

        <InvoiceDetails
          isLoading={isLoading}
          serviceType={serviceType}
          invoiceData={invoiceData}
          size="small"
        />
      </div>
    </div>
  );
}
