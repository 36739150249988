import { useAppDispatch, useAppSelector } from '@/hooks';
import { useLocation, useNavigate } from 'react-router-dom';

import { mfaInit, signupDataValidate } from '@/actions/authentication';
import Button from '@/atoms/button';
import Checkbox from '@/atoms/checkbox';
import Dropdown from '@/atoms/dropdown';
import Input from '@/atoms/input';
import { editForm } from '@/store/reducers/signup-form';

export default function SignupDetails({
  showAppointmentPanel,
}: {
  showAppointmentPanel: boolean;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const requestAppointmentForm = useAppSelector(
    (state) => state.requestAppointmentForm,
  );
  const { isLoadingValidation } = useAppSelector((state) => state.signupForm);
  const { signupLoading } = useAppSelector((state) => state.authentication);
  const { data } = useAppSelector((state) => state.signupForm);
  const countryIdOptions = useAppSelector(
    (state) => state.main.geo.countryCode,
  );
  const {
    countryCodeId,
    email,
    password,
    firstName,
    lastName,
    phoneNumber,
    isMarketable,
  } = data;
  const canContinue =
    countryCodeId &&
    email &&
    password &&
    firstName &&
    lastName &&
    phoneNumber &&
    isMarketable &&
    !signupLoading &&
    !isLoadingValidation;

  const onSignupValidate = (e: any) => {
    e.preventDefault();

    if (!canContinue) return;

    // User was requesting an appointment
    if (showAppointmentPanel) {
      sessionStorage.setItem(
        'requestAppointmentForm',
        JSON.stringify(requestAppointmentForm),
      );
    }

    dispatch(
      signupDataValidate({ ...data, countryCodeId: countryCodeId.id }),
    )?.then(({ success }) => {
      if (success) {
        dispatch(mfaInit({ countryCodeId: countryCodeId.id, phoneNumber }));
        navigate(`/signup-mfa${location.search}`);
      }
    });
  };

  return (
    <div className="signup">
      <h1 className="mb-0 f-14 mobile-page-title">Sign up</h1>
      <form onSubmit={onSignupValidate}>
        <div className="mobile-page-container">
          <div className="flex-row">
            <Input
              id="firstName"
              label="First name"
              className="mr-12 w-100"
              onChange={(value) =>
                dispatch(editForm({ key: 'firstName', value }))
              }
              value={firstName}
              autoFocus
              variant="white"
              autoComplete="given-name"
            />
            <Input
              id="lastName"
              label="Last name"
              className="w-100"
              onChange={(value) =>
                dispatch(editForm({ key: 'lastName', value }))
              }
              value={lastName}
              variant="white"
              autoComplete="family-name"
            />
          </div>
          <Input
            id="email"
            label="Email"
            type="email"
            onChange={(value) => dispatch(editForm({ key: 'email', value }))}
            value={email}
            variant="white"
            autoComplete="email"
          />
          <div className="phone-container mt-32 flex-row">
            <Dropdown
              id="countryCode"
              label="Phone"
              className="country-code-input"
              items={countryIdOptions}
              onClick={(value) =>
                dispatch(editForm({ key: 'countryCodeId', value }))
              }
              value={countryCodeId}
              variant="white"
            />
            <Input
              id="phone"
              className="mt-42 w-100 "
              onChange={(value) =>
                dispatch(editForm({ key: 'phoneNumber', value }))
              }
              value={phoneNumber}
              isNumber
              variant="white"
              autoComplete="tel-national"
            />
          </div>
          <Input
            id="password"
            label="Password"
            type="password"
            onChange={(value) => dispatch(editForm({ key: 'password', value }))}
            value={password}
            variant="white"
          />
          <div className="mt-25 mb-25">
            <Checkbox
              id="consent"
              value={isMarketable}
              onClick={({ isChecked }) =>
                dispatch(editForm({ key: 'isMarketable', value: isChecked }))
              }
              variant="consent"
            >
              <span className="f16">
                I have read and accept the{' '}
                <a
                  href="https://www.mypawcare.com/terms"
                  target="_blank"
                  rel="noreferrer"
                >
                  Terms of service
                </a>{' '}
                and{' '}
                <a
                  href="https://www.mypawcare.com/privacy.html"
                  target="_blank"
                  rel="noreferrer"
                >
                  Privacy Policy
                </a>
                .
              </span>
            </Checkbox>
          </div>
        </div>
        <div className="mobile-next-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            type="submit"
            className="mt-35"
          />
        </div>
      </form>
    </div>
  );
}
