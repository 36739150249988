import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { editForm } from '@/store/reducers/booking-form';
import DefaultAvatar from '@/atoms/default-avatar';
import Button from '@/atoms/button';
import { capitalizeFirstLetter, isNotEmptyArray } from '@/util';
import { LocationUser } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import SelectableItem from '../components/selectable-item';
import useBookingFlow from '../hooks/use-booking-flow';
import '../booking-flow.scss';

export default function BookingGroomerSelect() {
  const dispatch = useAppDispatch();
  const { availableGroomers } = useAppSelector((state) => state.bookingFlow);
  const { locationUser: selectedGroomer } = useAppSelector(
    (state) => state.bookingForm,
  );
  const { processNextStep } = useBookingFlow();
  const canContinue = !!selectedGroomer;

  const onSelectGroomer = (groomer: LocationUser) => {
    dispatch(editForm({ key: 'locationUser', value: groomer }));
  };

  const onNext = async () => {
    if (!canContinue) return;
    await processNextStep();
  };

  return (
    <>
      <Helmet title="Select groomer for booking | PawCare" />
      <div className="groomer-select flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32">
            <h2 className="f26 bold">Select groomer</h2>
            {isNotEmptyArray(availableGroomers) ? (
              <>
                <div className="flex-column gap-12">
                  <SelectableItem
                    onClick={() => onSelectGroomer({ user: { uuid: 'any' } })}
                    isActive={selectedGroomer?.user.uuid === 'any'}
                    className="gap-16"
                  >
                    <DefaultAvatar size="small" isUser />
                    <p className="f16 bold text-black text-left">Any groomer</p>
                  </SelectableItem>
                  {availableGroomers.map((groomer) =>
                    groomer.user?.uuid ? (
                      <SelectableItem
                        key={groomer.user.uuid}
                        onClick={() => onSelectGroomer(groomer)}
                        isActive={
                          groomer.user.uuid === selectedGroomer?.user.uuid
                        }
                        className="gap-16"
                      >
                        <DefaultAvatar
                          size="small"
                          avatar={
                            groomer.user.links?.image?.desktop?.large || ''
                          }
                        />
                        <p className="f16 bold">
                          {capitalizeFirstLetter(groomer.user.firstName || '')}
                        </p>
                      </SelectableItem>
                    ) : null,
                  )}
                </div>
                <Button
                  text="Next"
                  onClick={onNext}
                  size="large"
                  className="desktop-next-btn w-fit"
                  variant={canContinue ? 'default' : 'disabled'}
                />
              </>
            ) : (
              <p className="f16 fw-400">No groomers available</p>
            )}
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button
            text="Next"
            onClick={onNext}
            variant={canContinue ? 'default' : 'disabled'}
            className="mt-25"
          />
        </div>
      </div>
    </>
  );
}
