import { mfaInit } from '@/actions/authentication';
import Button from '@/atoms/button';
import Dropdown from '@/atoms/dropdown';
import Input from '@/atoms/input';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { editForm } from '@/store/reducers/mfa-login-form';
import { requestOngoing, requestFulfilled } from '@/store/reducers/ui';
import { FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';

export default function LoginPhoneNumber({
  goToEmailLogin,
}: {
  goToEmailLogin: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const countryIdOptions = useAppSelector(
    (state) => state.main.geo.countryCode,
  );
  const mfaLoginFormData = useAppSelector((state) => state.mfaLoginForm.data);
  const { countryCodeId, phoneNumber } = mfaLoginFormData;
  const canSubmit = countryCodeId && phoneNumber;

  const onLogIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!canSubmit) return;

    dispatch(requestOngoing());
    const mfaRequest = await dispatch(
      mfaInit(
        { countryCodeId: countryCodeId.id, phoneNumber },
        { showAPIerror: true },
      ),
    );
    dispatch(requestFulfilled());
    if (mfaRequest?.success) {
      navigate('/login/mfa');
    }
  };

  return (
    <>
      <h1 className="mb-32">Log in</h1>
      <form onSubmit={onLogIn}>
        <div className="flex-column gap-16">
          <p className="label">Phone number</p>
          <p className="f14 text-primary-purple">
            Enter the phone number tied to your account.
          </p>
          <div className="flex-row gap-12">
            <Dropdown
              id="countryCode"
              className="country-code-dropdown"
              items={countryIdOptions}
              onClick={(value) =>
                dispatch(editForm({ key: 'countryCodeId', value }))
              }
              value={countryCodeId}
              variant="white"
            />
            <Input
              id="phone"
              onChange={(value) =>
                dispatch(editForm({ key: 'phoneNumber', value }))
              }
              value={phoneNumber}
              isNumber
              autoComplete="tel-national"
              variant="white"
              className="mt-0 w-100"
            />
          </div>
          <span className="f14 text-primary-purple">
            Or,&nbsp;
            <Button
              className="inline-display"
              variant="link"
              onClick={goToEmailLogin}
              text="login with your email"
              size="small"
              disableLoadingState
            />
          </span>
        </div>
        <Button
          className="my-32"
          type="submit"
          text="Log in"
          disabled={!canSubmit}
        />
      </form>
    </>
  );
}
