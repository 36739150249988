import { FormEvent, useState } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';

import { logIn } from '@/actions/authentication';
import Button from '@/atoms/button';
import Input from '@/atoms/input';
import Link from '@/atoms/link';
import toast from '@/atoms/toast';
import { clearForm } from '@/store/reducers/request-appointment-form';
import { loadBootstrap } from '@/actions';
import InexistentUserModal from './inexistent-user-modal';

export default function LoginEmail({
  cameFromSignup,
  originPage,
  goToPhoneLogin,
}: {
  cameFromSignup: boolean;
  originPage: string;
  goToPhoneLogin: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const requestAppointmentForm = useAppSelector(
    (state) => state.requestAppointmentForm,
  );
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const canSubmit = email && password;

  const onLogIn = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // User was requesting an appointment
    if (requestAppointmentForm.dateStart && requestAppointmentForm.service) {
      sessionStorage.setItem(
        'requestAppointmentForm',
        JSON.stringify(requestAppointmentForm),
      );
    }
    const { success, data, status } = (await dispatch(
      logIn({ email, password }, { displayErrorToast: false }),
    )) as any;

    if (success) {
      if (cameFromSignup) dispatch(clearForm());
      const { token } = data.data;
      localStorage.setItem('token', token);
      dispatch(loadBootstrap());
      navigate(originPage);
      return;
    }
    if (!success) {
      if (status === 404) {
        setIsModalOpen(true);
      }
      if (status === 401) {
        toast({
          text: 'Wrong combination of user and password! Please try again.',
        });
      }
    }
  };

  return (
    <>
      <h1 className="mb-32">Log in</h1>
      <form onSubmit={onLogIn}>
        <p className="label mb-16">Email</p>
        <Input
          id="email"
          type="email"
          onChange={(value) => setEmail(value)}
          value={email}
          autoFocus
          className="mt-0"
          variant="white"
          autoComplete="email"
        />
        <div className="flex-row justify-between align-center mt-24 mb-16">
          <p className="label">Password</p>
          <Link
            to="/trigger-password-reset"
            className="f14 text-primary-purple"
          >
            Forgot password?
          </Link>
        </div>
        <Input
          id="password"
          type="password"
          onChange={(value) => setPassword(value)}
          value={password}
          className="mt-0 mb-24"
          variant="white"
        />
        <span className="f14 text-primary-purple">
          Or,&nbsp;
          <Button
            className="inline-display"
            variant="link"
            onClick={goToPhoneLogin}
            text="login with your phone number"
            size="small"
            disableLoadingState
          />
        </span>
        <Button
          className="my-32"
          type="submit"
          text="Log in"
          disabled={!canSubmit}
        />
      </form>
      <InexistentUserModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
}
