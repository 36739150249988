import { useAppDispatch } from '@/hooks';

import DefaultAvatar from '@/atoms/default-avatar';
import Dropdown from '@/atoms/dropdown';
import { setActivePet } from '@/store/reducers/pet-appointments';
import { Animal } from '@/types/composite';

export default function PetSelect({
  activePet,
  pets,
}: {
  activePet: Animal;
  pets: Animal[];
}) {
  const dispatch = useAppDispatch();

  const dropdownItems = [
    ...(pets || []).map((pet: Animal) => ({
      ...pet,
      value: pet.name,
      code: (
        <div className="flex-row">
          <DefaultAvatar
            size="extra-small"
            avatar={pet.links?.image?.desktop?.large}
          />
          <span className="ml-8 f13">{pet.name}</span>
        </div>
      ),
    })),
  ];

  return (
    <Dropdown
      placeholder="Select"
      className="pet-select"
      size="small"
      shape="square"
      items={dropdownItems}
      onClick={(selectedItem) => dispatch(setActivePet(selectedItem))}
      value={{ value: activePet.name }}
    />
  );
}
