import { Helmet } from 'react-helmet-async';
import { useAppSelector } from '@/hooks';
import DefaultAvatar from '@/atoms/default-avatar';
import { useNavigate } from 'react-router-dom';
import Button from '@/atoms/button';
import { capitalizeFirstLetter } from '@/util';
import { formatDuration } from '../utils';
import useBookingFlow from '../hooks/use-booking-flow';
import InvoiceDetails from '../components/invoice-details';
import AppointmentDate from '../components/appointment-date';

export default function BookingConfirmed() {
  const navigate = useNavigate();
  const { clearBookingFlow } = useBookingFlow();
  const { uuid } = useAppSelector((state) => state.user.data);
  const { serviceNameQuantified } = useAppSelector(
    (state) => state.bookingFlow,
  );
  const {
    location: providerLocation,
    animal,
    service,
    locationUser,
    serviceType,
  } = useAppSelector((state) => state.bookingForm);
  const { name: locationName, links, address1 } = providerLocation || {};
  const { name: serviceName, description, durationMinutes } = service || {};
  const formattedDuration = formatDuration({
    durationMinutes: durationMinutes || null,
    serviceType: serviceType || null,
  });
  const isDogPark = serviceType === 'Dog Park';

  const onFinish = () => {
    clearBookingFlow();
    navigate(`/user/${uuid}/pet/${animal?.uuid || ''}`);
  };

  return (
    <>
      <Helmet title="Booking confirmed | PawCare" />
      <div className="flex-row justify-center align-start py-40 w-100 overflow-scroll">
        <div className="flex-column gap-32 p-32 max-w-510 border-light-purple radius-24 w-100">
          <div className="flex-column">
            <h1 className="f26 bold">Booking confirmed</h1>
            {!isDogPark && (
              <p className="f16 fw-400">
                As a reminder, if you must cancel please do so more than 24
                hours before your appointment to avoid a cancellation fee.
              </p>
            )}
          </div>
          <div className="flex-row gap-8">
            <DefaultAvatar avatar={links?.image?.desktop?.large} size="big" />
            <div className="flex-column">
              <h2 className="f16 bold">{locationName}</h2>
              <p className="f13 fw-400 text-primary-purple">{address1}</p>
            </div>
          </div>
          <div className="flex-column gap-12">
            <div className="flex-column">
              <h2 className="f18 bold">
                {serviceNameQuantified || serviceName}
              </h2>
              <AppointmentDate size="large" />
              {locationUser?.user?.firstName ? (
                <p className="f13 fw-400">
                  {capitalizeFirstLetter(locationUser.user.firstName)} (
                  {formattedDuration})
                </p>
              ) : null}
            </div>
            <p className="f16 fw-400 text-primary-purple">{description}</p>
          </div>
          <InvoiceDetails size="large" serviceType={serviceType} />
          <Button onClick={onFinish} text="View your appointments" />
        </div>
      </div>
    </>
  );
}
