import { setStepData } from '@/store/reducers/booking-flow';
import { editForm } from '@/store/reducers/booking-form';
import {
  getAvailabilityOccupancy,
  getAvailableGroomers,
  getAvailableSlots,
  getServiceGroups,
  invoiceCheck,
} from '@/actions/booking';
import dayjs from 'dayjs';
import { isNotEmptyArray } from '@/util';
import { loadLastUserAppointment, loadUserAppointments } from '@/actions/user';
import { BookingFlowStep, StepDefinition } from '@/types/others';
import {
  Animal,
  LocationUser,
  ServiceGroup,
  ServiceType,
} from '@/types/composite';
import { areVaccinationsValidInDate, findIdByValue } from '../utils';
import { fetchAvailableServiceTypes } from './index';

export const STEPS: Record<BookingFlowStep, StepDefinition> = {
  'pet-information': {
    route: '/booking/pet-information',
  },

  'pet-select': {
    route: '/booking/pet-select',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { animals } = state.user.data;

      if (animals?.length > 0) {
        dispatch(setStepData({ key: 'availablePets', value: animals || [] }));
      }
    },
    shouldSkip: async (dispatch, getState) => {
      const state = getState();
      const pets = state.bookingFlow.availablePets;
      if (pets.length === 1) {
        dispatch(editForm({ key: 'animal', value: pets[0] }));
        return true;
      }
      return false;
    },
  },

  'service-type': {
    route: '/booking/service-type',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { location, animal } = state.bookingForm;
      const { data: newAnimal } = state.createPetForm;
      const { uuid: luid } = location || {};
      const selectedAnimal = animal?.uuid ? animal : newAnimal;

      if (!luid || !selectedAnimal?.name) {
        throw new Error('Location and pet are required for this step');
      }
      await fetchAvailableServiceTypes({
        dispatch,
        getState,
        animal: selectedAnimal,
        luid,
      });
    },
    shouldSkip: async (dispatch, getState) => {
      const state = getState();
      const types = state.bookingFlow.availableServiceTypes;
      if (types.length === 1) {
        dispatch(editForm({ key: 'serviceType', value: types[0] }));
        return true;
      }
      return false;
    },
  },

  'service-select': {
    route: '/booking/service',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { provider } = state.main;
      const { type: providerTypes } = provider || {};
      const { animal, location, serviceType } = state.bookingForm;
      const { uuid: luid } = location || {};
      const typeId = findIdByValue(providerTypes, serviceType);

      if (!luid || !animal?.name || !typeId) {
        throw new Error(
          'Location, pet and service type are required for this step',
        );
      }

      const getServiceGroupsCall = await dispatch(
        getServiceGroups({
          luid,
          animal,
          addOns: false,
          lowestPricedServiceOnly: ['Boarding', 'Daycare'].includes(
            serviceType || '',
          ),
          typeId,
        }),
      );
      if (getServiceGroupsCall?.success) {
        const serviceGroups = getServiceGroupsCall.data?.data || [];
        const services = serviceGroups.flatMap((group: ServiceGroup) =>
          group.services?.map((service) => ({
            ...service,
            description: group.description,
            type: group.type,
          })),
        );
        dispatch(setStepData({ key: 'availableServices', value: services }));
        if (services.length === 1) {
          dispatch(editForm({ key: 'service', value: services[0] }));
        }
      }
    },
  },

  'kennel-select': {
    route: '/booking/kennel',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { animal, location, service, dateStart, dateEnd } =
        state.bookingForm;
      const { data: newAnimal } = state.createPetForm;
      const { uuid: luid } = location || {};
      const { uuid: suid } = service || {};

      const selectedAnimal = animal?.uuid ? animal : newAnimal;

      if (!luid || !selectedAnimal.name || !suid || !dateStart || !dateEnd) {
        throw new Error(
          'Location, pet, service and date range are required for this step',
        );
      }

      const getAvailabilityOccupancyCall = await dispatch(
        getAvailabilityOccupancy({
          luid,
          suid,
          dateStart,
          dateEnd,
          animal: selectedAnimal as Animal,
        }),
      );
      if (getAvailabilityOccupancyCall?.success) {
        const kennelUpgrades =
          getAvailabilityOccupancyCall.data?.data?.services || [];
        dispatch(
          setStepData({
            key: 'availableKennelUpgrades',
            value: kennelUpgrades || [],
          }),
        );
      }
    },
    shouldSkip: async (_, getState) => {
      const state = getState();
      const kennelUpgrades = state.bookingFlow.availableKennelUpgrades;
      return kennelUpgrades.length <= 1;
    },
  },

  'add-ons': {
    route: '/booking/add-ons',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { provider } = state.main;
      const { type: providerTypes } = provider || {};
      const { animal, location, serviceType } = state.bookingForm;
      const { uuid: luid } = location || {};
      const typeId = findIdByValue(providerTypes, serviceType);

      if (!luid || !animal?.name || !typeId) {
        throw new Error(
          'Location, pet and service type are required for this step',
        );
      }

      const getServiceGroupsCall = await dispatch(
        getServiceGroups({
          luid,
          animal,
          addOns: true,
          typeId,
        }),
      );
      if (getServiceGroupsCall?.success) {
        const serviceGroups = getServiceGroupsCall.data?.data || [];
        const addOns = serviceGroups.flatMap((group: ServiceGroup) =>
          group.services?.map((service) => ({
            ...service,
            description: group.description,
            type: group.type,
          })),
        );
        dispatch(setStepData({ key: 'availableAddOns', value: addOns || [] }));
      }
    },
    shouldSkip: async (dispatch, getState) => {
      const state = getState();
      const addOns = state.bookingFlow.availableAddOns;
      if (addOns.length === 0) {
        dispatch(editForm({ key: 'addOns', value: [] }));
        return true;
      }
      return false;
    },
  },

  'groomer-select': {
    route: '/booking/groomer',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { animal, location, service } = state.bookingForm;
      const { uuid: luid } = location || {};
      const { uuid: suid } = service || {};

      if (!luid || !animal?.name || !suid) {
        throw new Error('Location, pet and service are required for this step');
      }

      const getAvailableGroomersCall = await dispatch(
        getAvailableGroomers({
          luid,
          auid: animal?.uuid,
          suid,
          dateStart: dayjs().format('YYYY-MM-DD'),
          timeStart: dayjs().format('HH:mm:ss'),
          sortSoonestAvailable: true,
        }),
      );
      if (getAvailableGroomersCall?.success) {
        const groomers: LocationUser[] =
          getAvailableGroomersCall.data?.data || [];
        const availableGroomers = groomers.filter(
          (groomer) =>
            !!groomer.nextAvailableDate && !!groomer.nextAvailableTime,
        );
        dispatch(
          setStepData({ key: 'availableGroomers', value: availableGroomers }),
        );

        const { locationUser: lastBookedGroomer } = state.lastUserAppointment;
        if (lastBookedGroomer?.uuid) {
          const lastBookedGroomerMatch = availableGroomers.find(
            (groomer) => groomer.user.uuid === lastBookedGroomer.uuid,
          );
          if (lastBookedGroomerMatch) {
            dispatch(
              editForm({ key: 'locationUser', value: lastBookedGroomerMatch }),
            );
          }
        }
      }
    },
    shouldSkip: async (dispatch, getState) => {
      const state = getState();
      const groomers = state.bookingFlow.availableGroomers;
      if (groomers.length === 1) {
        dispatch(editForm({ key: 'locationUser', value: groomers[0] }));
        return true;
      }
      return false;
    },
  },

  'date-time': {
    route: '/booking/date-time',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { animal, location, service, locationUser, serviceType } =
        state.bookingForm;
      const { uuid: luid } = location || {};
      const { uuid: suid } = service || {};
      const { user, nextAvailableDate } = locationUser || {};
      const groomerUuid = user?.uuid;

      if (!luid || !animal?.name || !suid) {
        throw new Error('Location, pet and service are required for this step');
      }

      let dateStart;
      if (groomerUuid && groomerUuid !== 'any' && nextAvailableDate) {
        dateStart = nextAvailableDate;
      } else {
        dateStart = dayjs().format('YYYY-MM-DD');
      }

      const dateEnd = dayjs(dateStart)
        .add(1, 'month')
        .endOf('month')
        .format('YYYY-MM-DD');

      const getAvailableSlotsCall = await dispatch(
        getAvailableSlots({
          luid,
          suid,
          auid: animal?.uuid,
          sizeId: animal?.size?.id,
          uuid: groomerUuid && groomerUuid !== 'any' ? groomerUuid : undefined,
          dateStart,
          dateEnd,
        }),
      );

      if (getAvailableSlotsCall?.success) {
        const slots = getAvailableSlotsCall.data?.data?.calendar || [];
        dispatch(setStepData({ key: 'availableSlots', value: slots }));

        if (serviceType === 'Boarding') {
          dispatch(editForm({ key: 'dateStart', value: null }));
          dispatch(editForm({ key: 'dateEnd', value: null }));
          return;
        }

        if (slots.length > 0 && slots[0].date && slots[0].time?.length > 0) {
          const firstSlotDate = slots[0].date;
          const firstSlotTime = slots[0].time[0];
          const startDateTime = `${firstSlotDate}T${firstSlotTime}`;
          dispatch(editForm({ key: 'dateStart', value: startDateTime }));

          if (serviceType === 'Daycare') {
            const durationMinutes = service?.group?.durationMinutes;
            if (durationMinutes) {
              const endDateTime = dayjs(startDateTime)
                .add(durationMinutes, 'minutes')
                .format('YYYY-MM-DDTHH:mm:ss');
              dispatch(editForm({ key: 'dateEnd', value: endDateTime }));
            }
          }
        }
      }
    },
  },

  behavior: {
    route: '/booking/behavior',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { core } = state.main;

      dispatch(
        setStepData({
          key: 'availableBehaviors',
          value: core?.behavior || [],
        }),
      );
    },
    shouldSkip: async (_, getState) => {
      const state = getState();
      const { animal } = state.bookingForm;
      const { behaviors: animalBehaviors } = animal || {};

      if (isNotEmptyArray(animalBehaviors)) return true;

      return false;
    },
  },

  medications: {
    route: '/booking/medications',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { core } = state.main;
      const { animal } = state.bookingForm;
      const { data: newAnimal } = state.createPetForm;
      const typeId = animal?.uuid ? animal?.type?.id : newAnimal?.type?.id;

      // We'll pre-select the medications used in the last appointment
      dispatch(loadLastUserAppointment());

      const filteredMedication = {
        drug:
          core?.medication?.drug?.filter(
            (drug) => drug.animalTypeId === typeId,
          ) || [],
        frequency: core?.medication?.frequency || [],
      };

      dispatch(
        setStepData({
          key: 'availableMedications',
          value: filteredMedication,
        }),
      );
    },
  },

  vaccinations: {
    route: '/booking/vaccinations',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { core } = state.main;
      const { animal } = state.bookingForm;
      const { data: newAnimal } = state.createPetForm;
      const typeId = animal?.uuid ? animal?.type?.id : newAnimal?.type?.id;

      const availableVaccinations = core?.vaccination?.filter(
        (vaccination) => vaccination.animalTypeId === typeId,
      );

      dispatch(
        setStepData({
          key: 'availableVaccinations',
          value: availableVaccinations,
        }),
      );
    },
    shouldSkip: async (_, getState) => {
      const state = getState();
      const { animal, dateStart, location } = state.bookingForm;
      const { vaccinations } = animal || {};
      const { vaccinationRequiredForBookings } = location?.publicSettings || {};
      const appointmentDate = dayjs(dateStart);

      if (!vaccinationRequiredForBookings) {
        return true;
      }

      return (
        !!vaccinations &&
        areVaccinationsValidInDate(vaccinations, appointmentDate)
      );
    },
  },

  'user-information': {
    route: '/booking/user-information',
    shouldSkip: async (_, getState) => {
      const state = getState();
      const { token } = state.user;
      return !!token;
    },
  },

  payment: {
    route: '/booking/payment',
    fetchData: async (dispatch, getState) => {
      const state = getState();
      const { location: providerLocation } = state.bookingForm;
      const { publicSettings, uuid: luid } = providerLocation || {};

      if (publicSettings?.ccRequiredForNewClientsBookings) {
        const loadUserAppointmentsCall = await dispatch(
          loadUserAppointments({ luid }),
        );
        if (loadUserAppointmentsCall?.success) {
          const { active, cancelled, past } =
            loadUserAppointmentsCall.data?.data || {};
          const hasActiveApptWithProvider = isNotEmptyArray(active);
          const hasCancelledApptWithProvider = isNotEmptyArray(cancelled);
          const hasPastApptWithProvider = isNotEmptyArray(past);

          if (
            hasActiveApptWithProvider ||
            hasCancelledApptWithProvider ||
            hasPastApptWithProvider
          ) {
            dispatch(setStepData({ key: 'isNewCustomer', value: false }));
          }
        }
      }
    },
  },

  'booking-confirmation': {
    route: '/booking/confirmation',
    fetchData: async (dispatch) => {
      const invoiceCheckCall = await dispatch(invoiceCheck());
      if (invoiceCheckCall?.success) {
        const invoice = invoiceCheckCall.data?.data;
        dispatch(setStepData({ key: 'invoice', value: invoice }));
      }
    },
  },
};

export const BOOKING_FLOW_GENERAL_STEPS = [
  'pet-information',
  'pet-select',
  'service-type',
  'service-select',
];

export const BOOKING_FLOW_SERVICE_STEPS: Record<
  ServiceType,
  BookingFlowStep[]
> = {
  Boarding: [
    'date-time',
    'kennel-select',
    'add-ons',
    'behavior',
    'medications',
    'vaccinations',
    'user-information',
    'payment',
    'booking-confirmation',
  ],
  Daycare: [
    'date-time',
    'add-ons',
    'behavior',
    'medications',
    'vaccinations',
    'user-information',
    'payment',
    'booking-confirmation',
  ],
  'Dog Park': [
    'date-time',
    'vaccinations',
    'user-information',
    'payment',
    'booking-confirmation',
  ],
  Grooming: [
    'add-ons',
    'groomer-select',
    'date-time',
    'vaccinations',
    'user-information',
    'payment',
    'booking-confirmation',
  ],
};
