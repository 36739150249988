import { useAppDispatch } from '@/hooks';

import Dropdown from '@/atoms/dropdown';
import { editForm } from '@/store/reducers/create-pet-form';

export default function PetFilter({
  core,
  type,
}: {
  core: { type: { id: string; value: string }[] };
  type: string;
}) {
  const dispatch = useAppDispatch();

  const dropdownItems = core.type.map(
    ({ id, value }: { id: string; value: string }) => ({
      id,
      value: `${value === 'Dog' ? '🐶' : '🐱'} ${value}`,
    }),
  );

  return (
    <Dropdown
      placeholder="Select animal type"
      className="pet-filter"
      size="small"
      shape="square"
      items={dropdownItems}
      onClick={(value: string) => dispatch(editForm({ key: 'type', value }))}
      value={{ value: type }}
    />
  );
}
