import { useAppDispatch, useAppSelector } from '@/hooks';
import { editForm } from '@/store/reducers/booking-form';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { getProviderLocation, getServiceGroups } from '@/actions/booking';
import { setStepData } from '@/store/reducers/booking-flow';
import FullScreenLoader from '@/atoms/full-screen-loader';
import { Helmet } from 'react-helmet-async';
import Button from '@/atoms/button';
import { isNotEmptyArray } from '@/util';
import './provider-profile.scss';

import ServiceGroupsList from './components/service-groups-list';
import ProviderInfo from './components/provider-info';
import SidePanel from '../booking-flow/components/side-panel';
import ProviderTitle from '../booking-flow/components/provider-title';
import MainPanel from '../booking-flow/components/main-panel';
import useBookingFlow from '../booking-flow/hooks/use-booking-flow';
import EmptyState from './components/empty-state';

export default function BookingProviderProfile() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const params = useParams();
  const { slug } = params;
  const { clearBookingFlow, processNextStep } = useBookingFlow();
  const { animals } = useAppSelector((state) => state.user.data);
  const { availableServiceGroups } = useAppSelector(
    (state) => state.bookingFlow,
  );
  const { location: providerLocation } = useAppSelector(
    (state) => state.bookingForm,
  );
  const { name, uuid: luid } = providerLocation || {};
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!slug) return;

    const fetchLocationData = async () => {
      setIsLoading(true);
      const getProviderLocationCall = await dispatch(
        getProviderLocation({ slug }),
      );
      if (getProviderLocationCall?.success) {
        dispatch(
          editForm({
            key: 'location',
            value: getProviderLocationCall.data?.data || null,
          }),
        );
      }
    };

    clearBookingFlow();
    fetchLocationData();
  }, [dispatch, slug]);

  useEffect(() => {
    if (!luid) return;

    const fetchServiceGroups = async () => {
      const auid =
        isNotEmptyArray(animals) && animals.length === 1
          ? animals[0].uuid
          : undefined;

      setIsLoading(true);
      const getServiceGroupsCall = await dispatch(
        getServiceGroups({
          luid,
          addOns: false,
          ...(auid ? { auid } : {}),
          includeServicesPriceRange: true,
        }),
      );
      if (getServiceGroupsCall?.success) {
        dispatch(
          setStepData({
            key: 'availableServiceGroups',
            value: getServiceGroupsCall.data?.data || [],
          }),
        );
      }
      setIsLoading(false);
    };

    fetchServiceGroups();
  }, [luid]);

  const onBook = async () => {
    await processNextStep();
  };

  if (isLoading) return <FullScreenLoader />;

  if (!slug || !luid) return null;

  return (
    <>
      <Helmet>
        <title>{`${name} | PawCare`}</title>
        <meta name="title" content="Find best tailored pet grooming services" />
        <meta
          name="description"
          content="Find the best pet groomers for your furry friend’s every need. Round-the-clock, we ensure your pets have an excellent tail-wagging experience."
        />
        <link
          rel="canonical"
          href={`https://mypawcare.com${location.pathname}`}
        />
      </Helmet>
      <div className="provider-profile flex-row w-100">
        <SidePanel>
          <>
            <ProviderTitle />
            <ProviderInfo className="desktop" />
          </>
        </SidePanel>
        <MainPanel className="provider-profile-main-panel">
          {isNotEmptyArray(availableServiceGroups) ? (
            <div className="services flex-column">
              <div className="flex-row justify-between gap-16 w-100">
                <div className="flex-column gap-8">
                  <h2 className="f26 bold">Services</h2>
                  <p className="f16 fw-400">
                    Available services are listed below
                  </p>
                </div>
                <Button text="Book" onClick={onBook} size="medium" />
              </div>
              <ServiceGroupsList />
            </div>
          ) : (
            <EmptyState />
          )}
          <ProviderInfo className="mobile" />
        </MainPanel>
      </div>
    </>
  );
}
