import { Range, Type } from '@/types/basic';
import { ServiceType, Vaccination } from '@/types/composite';
import dayjs, { Dayjs } from 'dayjs';

const MINUTES_IN_HOUR = 60;

export const getServiceDuration = ({
  groomerDuration,
  otherDuration,
  serviceType,
}: {
  groomerDuration: number | null;
  otherDuration: number | null;
  serviceType: ServiceType | null;
}) => {
  if (serviceType === 'Grooming') {
    return formatDuration({
      durationMinutes: groomerDuration ?? null,
      serviceType: serviceType as ServiceType,
    });
  }
  if (serviceType === 'Dog Park') {
    return 'Unlimited';
  }

  // This will apply to Boarding and Daycare services
  return formatDuration({
    durationMinutes: otherDuration ?? null,
    serviceType: serviceType as ServiceType,
  });
};

export const formatDuration = ({
  durationMinutes,
  serviceType,
}: {
  durationMinutes: number | null;
  serviceType: ServiceType | null;
}) => {
  if (serviceType === 'Boarding') return '24 hours';

  if (!durationMinutes) return '-';

  if (serviceType === 'Daycare') {
    const hours = Math.round(durationMinutes / MINUTES_IN_HOUR);
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`;
  }

  return `${durationMinutes} ${durationMinutes === 1 ? 'min' : 'mins'}`;
};

export const formatRange = (range: Range | null) => {
  if (!range) return '-';

  if (range.min === range.max) {
    return range.localized.min;
  }

  return `${range.localized.min} — ${range.localized.max}`;
};

export const formatTime = (time: string) =>
  dayjs(time, 'HH:mm:ss').format('h:mm A');

export const findIdByValue = (
  items: Type[],
  value: string | null,
): number | null => {
  if (!value) return null;
  const match = items.find((item) => item.value === value);
  return match?.id ?? null;
};

export const areVaccinationsValidInDate = (
  vaccinations: Vaccination[],
  date: Dayjs,
) => {
  if (vaccinations.length === 0) return false;

  return (
    !!vaccinations &&
    !!date &&
    vaccinations.every(
      (vaccination) =>
        vaccination.expirationDate &&
        (dayjs(vaccination.expirationDate).isSame(date, 'day') ||
          dayjs(vaccination.expirationDate).isAfter(date, 'day')),
    )
  );
};
