import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { editForm } from '@/store/reducers/booking-form';
import { getRandomGroomer, isNotEmptyArray, prettifyHour } from '@/util';
import { useState } from 'react';
import Button from '@/atoms/button';
import dayjs from 'dayjs';
import { getAvailableGroomers } from '@/actions/booking';
import { LocationUser } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import useBookingFlow from '../hooks/use-booking-flow';
import TimeSelection from './components/time-selection';
import { getTitle } from './util';
import DateTimeCalendar from './components/date-time-calendar/index.tsx';
import '../booking-flow.scss';

export default function BookingDateTime() {
  const dispatch = useAppDispatch();
  const { processNextStep } = useBookingFlow();
  const { availableSlots } = useAppSelector((state) => state.bookingFlow);
  const { serviceType, service, location, animal, locationUser } =
    useAppSelector((state) => state.bookingForm);
  const { checkOutMinsGracePeriod, checkOutLateFee } =
    location?.publicSettings?.daycare || {};

  const [selectedDate, setSelectedDate] = useState<string>('');
  const [selectedTimeSlots, setSelectedTimeSlots] = useState<string[]>([]);
  const [selectedCheckin, setSelectedCheckin] = useState<string>('');
  const [selectedCheckout, setSelectedCheckout] = useState<string>('');
  const [dateRange, setDateRange] = useState<
    { from: string; to: string } | undefined
  >();

  const isBoarding = serviceType === 'Boarding';
  const isDaycare = serviceType === 'Daycare';
  const isGrooming = serviceType === 'Grooming';
  const isDogPark = serviceType === 'Dog Park';

  const durationMinutes = service?.group?.durationMinutes || 0;
  const isHourlyDaycare = isDaycare && durationMinutes === 60;
  const isFixedDaycare = isDaycare && !isHourlyDaycare;
  const checkOutTime = prettifyHour(
    location?.publicSettings?.boarding?.checkOutTime || '',
  );
  const checkInTime = prettifyHour(
    location?.publicSettings?.boarding?.checkInTime || '',
  );

  const onDateSelect = (date: string) => {
    if (isBoarding) return;

    setSelectedDate(date);
    const selectedDateSlots = availableSlots.find((slot) => slot.date === date);

    const selectedTimeSlots = selectedDateSlots?.time || [];
    if (isNotEmptyArray(selectedTimeSlots)) {
      setSelectedTimeSlots(selectedTimeSlots);

      const firstTimeSlot = selectedTimeSlots[0];
      setSelectedCheckin(firstTimeSlot);

      if (isDaycare && durationMinutes) {
        const checkoutTime = dayjs(`2000-01-01T${firstTimeSlot}`)
          .add(durationMinutes, 'minutes')
          .format('HH:mm:ss');
        setSelectedCheckout(checkoutTime);
      }
    } else {
      setSelectedTimeSlots([]);
      setSelectedCheckin('');
      setSelectedCheckout('');
    }
  };

  const onDateRangeSelect = (range: { from: string; to: string }) => {
    if (!isBoarding) return;

    setDateRange(range);
  };

  const canContinue = () => {
    if (isBoarding) {
      return (
        dateRange?.from &&
        dateRange?.to &&
        dayjs(dateRange.to).isAfter(dayjs(dateRange.from))
      );
    }

    if (!selectedDate) return false;

    if (isDogPark) {
      return true;
    }

    if (isHourlyDaycare) {
      return (
        selectedCheckin &&
        selectedCheckout &&
        dayjs(`2000-01-01T${selectedCheckout}`).isAfter(
          dayjs(`2000-01-01T${selectedCheckin}`),
        )
      );
    }

    return !!selectedCheckin;
  };

  const onNext = async (checkin?: string) => {
    if (!canContinue()) return;

    if (isBoarding) {
      const dateStart = `${dateRange!.from}`;
      const dateEnd = `${dateRange!.to}`;
      dispatch(editForm({ key: 'dateStart', value: dateStart }));
      dispatch(editForm({ key: 'dateEnd', value: dateEnd }));
    }

    if (isDaycare) {
      const dateStart = `${selectedDate}T${selectedCheckin}`;
      const dateEnd = `${selectedDate}T${selectedCheckout}`;
      dispatch(editForm({ key: 'dateStart', value: dateStart }));
      dispatch(editForm({ key: 'dateEnd', value: dateEnd }));
    }

    if (isDogPark) {
      const dateStart = selectedDate;
      dispatch(editForm({ key: 'dateStart', value: dateStart }));
      dispatch(editForm({ key: 'dateEnd', value: null }));
      setSelectedCheckin(checkin || selectedCheckin);
    }

    if (isGrooming) {
      const dateStart = `${selectedDate}T${checkin || selectedCheckin}`;
      dispatch(editForm({ key: 'dateStart', value: dateStart }));
      dispatch(editForm({ key: 'dateEnd', value: null }));
      setSelectedCheckin(checkin || selectedCheckin);

      if (locationUser?.user?.uuid === 'any') {
        const { uuid: luid } = location || {};
        const { uuid: auid } = animal || {};
        const { uuid: suid } = service || {};

        if (luid && auid && suid) {
          const getAvailableGroomersCall = await dispatch(
            getAvailableGroomers({
              luid,
              auid,
              suid,
              dateStart: selectedDate,
              timeStart: selectedCheckin,
            }),
          );
          if (getAvailableGroomersCall?.success) {
            const groomers: LocationUser[] =
              getAvailableGroomersCall.data?.data || [];
            const randomGroomer = getRandomGroomer(groomers);
            dispatch(editForm({ key: 'locationUser', value: randomGroomer }));
          } else return;
        }
      }
    }

    await processNextStep();
  };

  return (
    <>
      <Helmet title="Select date and time for booking | PawCare" />
      <div className="date-time flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32 min-w-386 max-w-768">
            <div className="flex-column">
              <h2 className="f26 bold">
                {getTitle({ isBoarding, isDogPark })}
              </h2>
              {isBoarding && (
                <p className="f14 fw-400 text-primary-purple">
                  For all boarding stays check-in begins at {checkInTime}, if
                  you need to check-in prior to {checkInTime} there may be an
                  early check-in fee. Similarly, checkout is at {checkOutTime}{' '}
                  on the last day of your stay. You will be charged the hourly
                  daycare rate for late checkouts.
                </p>
              )}
              {isDaycare && checkOutLateFee && checkOutMinsGracePeriod && (
                <p className="f14 fw-400 text-primary-purple">
                  Please pick up your pet within ${checkOutMinsGracePeriod} of
                  your scheduled check-out time. If you are late to pick up your
                  pet you may be charged a fee of {checkOutLateFee}.
                </p>
              )}
            </div>
            {isNotEmptyArray(availableSlots) ? (
              <>
                <DateTimeCalendar
                  mode={isBoarding ? 'range' : 'single'}
                  selectedDate={isBoarding ? undefined : selectedDate}
                  selectedDateRange={isBoarding ? dateRange : undefined}
                  onDateSelect={onDateSelect}
                  onDateRangeSelect={onDateRangeSelect}
                  disableDefaultStartDate={isBoarding}
                />
                {!isBoarding && !isDogPark && (
                  <TimeSelection
                    selectedDate={selectedDate}
                    selectedTimeSlots={selectedTimeSlots}
                    isBoarding={isBoarding}
                    isHourlyDaycare={isHourlyDaycare}
                    isFixedDaycare={isFixedDaycare}
                    selectedCheckin={selectedCheckin}
                    selectedCheckout={selectedCheckout}
                    setSelectedCheckin={setSelectedCheckin}
                    setSelectedCheckout={setSelectedCheckout}
                    durationMinutes={durationMinutes}
                    onNext={onNext}
                  />
                )}
                {(isDaycare || isBoarding || isDogPark) && (
                  <Button
                    onClick={onNext}
                    text="Next"
                    variant={canContinue() ? 'default' : 'disabled'}
                    className="desktop-next-btn w-fit"
                  />
                )}
              </>
            ) : (
              <p className="f16 fw-400">No time slots available</p>
            )}
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button
            text="Next"
            variant={canContinue() ? 'default' : 'disabled'}
            onClick={onNext}
            className="mt-25"
          />
        </div>
      </div>
    </>
  );
}
