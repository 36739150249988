import { setStepData } from '@/store/reducers/booking-flow';
import { AppDispatch, RootState } from '@/store';
import { getServiceGroups } from '@/actions/booking';
import { Animal, ServiceGroup } from '@/types/composite';

export const fetchAvailableServiceTypes = async ({
  dispatch,
  animal,
  luid,
}: {
  dispatch: AppDispatch;
  getState: () => RootState;
  animal: Omit<
    Animal,
    | 'medications'
    | 'behaviors'
    | 'uuid'
    | 'links'
    | 'deceased'
    | 'sterilization'
    | 'visits'
  >;
  luid: string;
}) => {
  const getServiceGroupsCall = await dispatch(
    getServiceGroups({
      luid,
      animal,
      addOns: false,
    }),
  );
  if (getServiceGroupsCall?.success) {
    const serviceGroups = getServiceGroupsCall.data?.data || [];

    const typeOrder = ['Boarding', 'Daycare', 'Dog Park', 'Grooming'] as const;

    const types = [
      ...new Set(
        serviceGroups
          .map((group: ServiceGroup) => group.type?.value)
          .filter(
            (type: string | undefined): type is string =>
              !!type && typeOrder.includes(type as (typeof typeOrder)[number]),
          ),
      ),
    ].sort((a, b) => {
      const aIndex = typeOrder.indexOf(a as (typeof typeOrder)[number]);
      const bIndex = typeOrder.indexOf(b as (typeof typeOrder)[number]);
      return aIndex - bIndex;
    });

    dispatch(setStepData({ key: 'availableServiceTypes', value: types }));
  }
};
