import './image-uploader.scss';

import { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';

import Icon from '@/atoms/icon';

import PlaceholderImage from './placeholder-image';
import { IconName } from '../icon/icons';

interface ImageUploaderProps {
  saveImage: (data: FormData) => void;
  placeholder?: IconName;
  variant?: 'default' | 'profile';
  size?: 'medium' | 'large' | 'big';
  showEditIcon?: boolean;
  showChangeImageText?: boolean;
  avatar?: string;
  className?: string;
  cameraVariant?: boolean;
  fileName?: string;
}

export default function ImageUploader({
  saveImage,
  placeholder = 'camera',
  variant = 'default',
  size = 'medium',
  showEditIcon,
  showChangeImageText,
  avatar,
  className,
  cameraVariant,
  fileName = 'file',
}: ImageUploaderProps) {
  const [image, setImage] = useState<File | null>(null);
  const [imageURL, setImageURL] = useState<string | null>(null);
  const defaultIconSize = size === 'medium' ? 57 : 80;

  useEffect(() => {
    if (!image) return;
    const newImage = URL.createObjectURL(image);
    setImageURL(newImage);

    const data = new FormData();
    data.append(fileName, image);

    saveImage(data);
  }, [image]);

  const onImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      setImage(file);
    }
  };

  return (
    <>
      <label
        htmlFor="file-upload"
        className={classNames(
          'image-uploader',
          `image-uploader--${variant}`,
          `image-uploader--${size}`,
          className,
        )}
      >
        {!imageURL && !avatar && (
          <PlaceholderImage
            cameraVariant={cameraVariant || false}
            placeholder={placeholder}
            defaultIconSize={defaultIconSize}
          />
        )}
        {showEditIcon && <Icon name="pencil" />}
        {showChangeImageText && (imageURL || avatar) && (
          <p className="text-orange pos-absolute change-text">Change photo</p>
        )}
      </label>
      <input
        id="file-upload"
        type="file"
        accept="image/*"
        onChange={onImageChange}
      />
      {(imageURL || avatar) && (
        <div
          className={classNames(
            'image-uploaded',
            `image-uploaded--${variant}`,
            `image-uploaded--${size}`,
          )}
          style={{ background: `url(${imageURL || avatar})` }}
        />
      )}
    </>
  );
}
