import dayjs from 'dayjs';

export const dayjsToDate = (date?: string) => {
  if (!date) return dayjs().toDate();
  return dayjs(date).toDate();
};

export const isOneDayApart = (from: Date, to: Date): boolean => {
  const diff = dayjs(to).diff(dayjs(from), 'day');
  return diff === 1;
};

export const getTitle = ({
  isBoarding,
  isDogPark,
}: {
  isBoarding: boolean;
  isDogPark: boolean;
}) => {
  if (isBoarding) return 'Choose the number of nights';
  if (isDogPark) return 'Choose a date';
  else return 'Choose a date and time';
};
