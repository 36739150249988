import KnobDown from './knob-down';
import Add from './add';
import AppleStore from './apple-store';
import Amex from './amex';
import ArrowLeft from './arrow-left';
import Bin from './bin';
import Burger from './burger';
import Camera from './camera';
import Calendar from './calendar';
import CalendarFilled from './calendar-filled';
import Cat from './cat';
import Card from './card';
import Clock from './clock';
import Close from './close';
import Email from './email';
import Exotic from './exotic';
import Filter from './filter';
import GooglePlay from './google-play';
import Info from './info';
import LargeAnimal from './large-animal';
import Lock from './lock';
import Account from './account';
import Neutered from './neutered';
import MapDrop from './map-drop';
import PawCare from './pawcare';
import Paw from './paw';
import PawFilled from './paw-filled';
import PawFilledReverse from './paw-filled-reverse';
import PawCareEmpty from './pawcare-empty';
import Pencil from './pencil';
import Phone from './phone';
import PocketPet from './pocket-pet';
import Search from './search';
import User from './user';
import Upload from './upload';
import Dog from './dog';
import Discover from './discover';
import Elo from './elo';
import Hiper from './hiper';
import Maestro from './maestro';
import Mastercard from './mastercard';
import Mir from './mir';
import Paypal from './paypal';
import Unionpay from './unionpay';
import Visa from './visa';
import World from './world';
import Mail from './mail';
import Grooming from './grooming';

export type IconName =
  | 'add'
  | 'arrow-down'
  | 'apple-store'
  | 'amex'
  | 'arrowLeft'
  | 'bin'
  | 'burger'
  | 'camera'
  | 'calendar'
  | 'calendar-filled'
  | 'cat'
  | 'card'
  | 'clock'
  | 'close'
  | 'email'
  | 'exotic'
  | 'filter'
  | 'grooming'
  | 'google-play'
  | 'info'
  | 'knobDown'
  | 'knobRight'
  | 'largeAnimal'
  | 'lock'
  | 'account'
  | 'neutered'
  | 'mail'
  | 'map-drop'
  | 'pawcare'
  | 'paw'
  | 'pawFilled'
  | 'pawFilledReverse'
  | 'pawcareEmpty'
  | 'pencil'
  | 'phone'
  | 'pocketPet'
  | 'search'
  | 'user'
  | 'upload'
  | 'dog'
  | 'discover'
  | 'elo'
  | 'hiper'
  | 'maestro'
  | 'mastercard'
  | 'mir'
  | 'paypal'
  | 'unionpay'
  | 'visa'
  | 'world';

const supportedIconNames = {
  add: Add,
  'arrow-down': KnobDown,
  'apple-store': AppleStore,
  amex: Amex,
  arrowLeft: ArrowLeft,
  bin: Bin,
  burger: Burger,
  camera: Camera,
  calendar: Calendar,
  'calendar-filled': CalendarFilled,
  cat: Cat,
  card: Card,
  clock: Clock,
  close: Close,
  email: Email,
  exotic: Exotic,
  filter: Filter,
  grooming: Grooming,
  'google-play': GooglePlay,
  info: Info,
  knobDown: KnobDown,
  knobRight: KnobDown, // Note: This seems to be using KnobDown, not KnobRight
  largeAnimal: LargeAnimal,
  lock: Lock,
  account: Account,
  neutered: Neutered,
  mail: Mail,
  'map-drop': MapDrop,
  pawcare: PawCare,
  paw: Paw,
  pawFilled: PawFilled,
  pawFilledReverse: PawFilledReverse,
  pawcareEmpty: PawCareEmpty,
  pencil: Pencil,
  phone: Phone,
  pocketPet: PocketPet,
  search: Search,
  user: User,
  upload: Upload,
  dog: Dog,
  discover: Discover,
  elo: Elo,
  hiper: Hiper,
  maestro: Maestro,
  mastercard: Mastercard,
  mir: Mir,
  paypal: Paypal,
  unionpay: Unionpay,
  visa: Visa,
  world: World,
};

export default supportedIconNames;
