import { useAppSelector } from '@/hooks';
import { AvatarWithInfo } from '@/organisms/avatar-with-info';
import { Appointment } from '@/types/composite';

export default function PetInfo({
  appointment,
  className,
}: {
  appointment: Appointment;
  className?: string;
}) {
  const user = useAppSelector((state) => state.user.data);
  const { animal } = appointment;
  const { name, size, breed, vaccinations } = animal;

  return (
    <AvatarWithInfo
      size="extra-large"
      className={className}
      avatar={appointment.animal.links?.image?.desktop?.large}
    >
      <div className="flex-column">
        <h5 className="bold f32">{name}</h5>
        <p className="f12 text-grey">
          {breed?.value} • {size?.value} •{' '}
          {vaccinations?.length > 0 ? 'Vaccinated' : 'Not vaccinated'}
        </p>
        <p className="f12 text-grey">
          {user?.firstName} {user?.lastName} • {user?.phone?.localized}
        </p>
      </div>
    </AvatarWithInfo>
  );
}
