import Dropdown from '@/atoms/dropdown';
import dayjs from 'dayjs';

export default function HourlyTimeSelect({
  selectedDate,
  timeSlots,
  selectedCheckin,
  selectedCheckout,
  onCheckinChange,
  onCheckoutChange,
}: {
  selectedDate: string;
  timeSlots: string[];
  selectedCheckin: string;
  selectedCheckout: string;
  onCheckinChange: (time: string) => void;
  onCheckoutChange: (time: string) => void;
}) {
  const selectedDateFormatted =
    selectedDate && dayjs(selectedDate).format('MMM D');

  const checkoutTimeSlots = timeSlots.map((time) => {
    const datetime = dayjs(`2000-01-01T${time}`).add(1, 'hour');
    return datetime.format('HH:mm:ss');
  });

  const checkinOptions = timeSlots.map((time) => ({
    id: time,
    value: dayjs(`2000-01-01T${time}`).format('h:mm A'),
  }));

  const checkoutOptions = checkoutTimeSlots.map((time) => ({
    id: time,
    value: dayjs(`2000-01-01T${time}`).format('h:mm A'),
  }));

  const handleCheckinChange = (newCheckinTime: string) => {
    onCheckinChange(newCheckinTime);

    if (selectedCheckout) {
      const checkin = dayjs(`2000-01-01T${newCheckinTime}`);
      const checkout = dayjs(`2000-01-01T${selectedCheckout}`);

      if (!checkout.isAfter(checkin)) {
        const newCheckout = checkin.add(1, 'hour').format('HH:mm:ss');
        onCheckoutChange(newCheckout);
      }
    }
  };

  const handleCheckoutChange = (newCheckoutTime: string) => {
    onCheckoutChange(newCheckoutTime);

    if (selectedCheckin) {
      const checkout = dayjs(`2000-01-01T${newCheckoutTime}`);
      const checkin = dayjs(`2000-01-01T${selectedCheckin}`);

      if (!checkin.isBefore(checkout)) {
        const newCheckin = checkout.subtract(1, 'hour').format('HH:mm:ss');
        onCheckinChange(newCheckin);
      }
    }
  };

  return (
    <div className="flex-row gap-16 w-100">
      <div className="flex-1 flex-column gap-16">
        <h3 className="f18 bold">
          Check in{selectedDateFormatted ? `: ${selectedDateFormatted}` : ''}
        </h3>
        <Dropdown
          placeholder="Select checkin time"
          variant="white"
          items={checkinOptions}
          value={checkinOptions.find((option) => option.id === selectedCheckin)}
          onClick={(value) => handleCheckinChange(value.id)}
        />
      </div>
      <div className="flex-1 flex-column gap-16">
        <h3 className="f18 bold">
          Checkout{selectedDateFormatted ? `: ${selectedDateFormatted}` : ''}
        </h3>
        <Dropdown
          placeholder="Select checkout time"
          variant="white"
          items={checkoutOptions}
          value={checkoutOptions.find(
            (option) => option.id === selectedCheckout,
          )}
          onClick={(value) => handleCheckoutChange(value.id)}
        />
      </div>
    </div>
  );
}
