import classNames from 'classnames';
import { ReactNode } from 'react';
import { useAppSelector } from '@/hooks';
import BookingFlowProgressBar from './booking-flow-progress-bar';
import BookingFlowLoader from './booking-flow-loader';

export default function MainPanel({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  const { isLoading } = useAppSelector((state) => state.bookingFlow);

  return (
    <div
      className={classNames(
        'flex-column elevation-8 w-100 h-100 overflow-scroll',
        className,
      )}
    >
      <BookingFlowProgressBar />
      <div className="children pt-72 px-72 pb-40">
        {isLoading ? <BookingFlowLoader /> : children}
      </div>
    </div>
  );
}
