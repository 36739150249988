import ReactModal from 'react-modal';
import Button from '@/atoms/button';
import { useNavigate } from 'react-router-dom';

export default function InexistentUserModal({
  isModalOpen,
  setIsModalOpen,
}: {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
}) {
  const navigate = useNavigate();

  return (
    <ReactModal
      className="user-not-found-modal"
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={() => setIsModalOpen(false)}
    >
      <div className="flex-column align-center text-center">
        <h2 className="f26">User does not exist</h2>
        <p className="mt-10">
          We cannot find an account with that email address.
        </p>
        <p> Please create a new account to get started.</p>
        <div className="flex-row mt-30">
          <Button
            size="medium"
            onClick={() => navigate('/signup')}
            text="Sign up"
            className="mr-10"
          />
          <Button
            size="medium"
            onClick={() => setIsModalOpen(false)}
            text="Close"
            variant="reverse"
          />
        </div>
      </div>
    </ReactModal>
  );
}
