import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface PetFormData {
  breed: {
    id: number;
    value: string;
  } | null;
  dateOfBirth: string | null;
  dateSterilized: string | null;
  gender: {
    id: number;
    value: string;
  } | null;
  hairLength: {
    id: number;
    value: string;
  } | null;
  name: string;
  size: {
    id: number;
    value: string;
  } | null;
  isSterilized: {
    value: string;
    id: number;
  } | null;
  type: {
    id: number;
    value: string;
  } | null;
  vaccinations: {
    uuid?: string;
    id: number;
    value: string;
    expirationDate: string | null;
  }[];
}

interface CreatePetFormState {
  data: PetFormData;
  validated: boolean;
  validationErrors: any;
}

const initialState: CreatePetFormState = {
  data: {
    breed: null,
    dateOfBirth: null,
    dateSterilized: null,
    gender: null,
    hairLength: null,
    name: '',
    size: null,
    isSterilized: null,
    type: null,
    vaccinations: [],
  },
  validated: false,
  validationErrors: null,
};

const createPetForm = createSlice({
  name: 'createPetForm',
  initialState,
  reducers: {
    signupDataValidateSuccess(state) {
      state.validated = true;
    },
    signupDataValidateFailure(state, { payload }) {
      state.validationErrors = payload;
    },
    editForm(state, { payload }) {
      const { key, value } = payload as { key: keyof PetFormData; value: any };
      (state.data[key] as any) = value;
    },
    initializeForm(state, action: PayloadAction<PetFormData>) {
      state.data = action.payload;
    },
    clearForm(state) {
      state.data = initialState.data;
    },
  },
});

const { actions, reducer } = createPetForm;

export const {
  editForm,
  clearForm,
  initializeForm,
  signupDataValidateSuccess,
  signupDataValidateFailure,
} = actions;

export default reducer;
