import { useState } from 'react';
import { useAppSelector } from '@/hooks';
import { useLocation } from 'react-router-dom';

import useQuery from '@/hooks/useQuery';
import { checkoutFlowURLs } from '@/util/constants';
import LoginEmail from './login-email';
import LoginPhoneNumber from './login-phone-number';

const loggedOutFlowURLs = [
  ...checkoutFlowURLs,
  '/booking-select-groomer',
  '/booking-select-slot',
];

export default function LoginInfo() {
  const location = useLocation();
  const query = useQuery();
  const requestAppointmentForm = useAppSelector(
    (state) => state.requestAppointmentForm,
  );
  const [loginType, setLoginType] = useState<'email' | 'phone'>('email');
  const homepageWithQueryParams = `/${query.size > 0 ? `?${query.toString()}` : ''}`;
  const locationStatePathname = location.state?.from?.pathname;
  const fromSignup =
    locationStatePathname?.includes('signup') ||
    loggedOutFlowURLs?.includes(locationStatePathname);
  const { providerPath } = requestAppointmentForm;
  const fromHomepageCheck =
    locationStatePathname === '/' || fromSignup
      ? homepageWithQueryParams
      : locationStatePathname;
  const from =
    fromSignup && !!providerPath
      ? providerPath
      : fromHomepageCheck || homepageWithQueryParams; // default to home page if no referrer

  return (
    <div className="login">
      {loginType === 'email' && (
        <LoginEmail
          cameFromSignup={fromSignup}
          originPage={from}
          goToPhoneLogin={() => setLoginType('phone')}
        />
      )}
      {loginType === 'phone' && (
        <LoginPhoneNumber goToEmailLogin={() => setLoginType('email')} />
      )}
    </div>
  );
}
