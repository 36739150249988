import { createSlice } from '@reduxjs/toolkit';

interface Drawer {
  isOpen: boolean;
  type: string;
  uuid: string | null;
  data: any | null;
  position: 'right' | 'left';
}

interface BottomSheet {
  isBottomSheetOpen: boolean;
  bottomSheetType: string | null;
}

interface MapLocation {
  lat: number | null;
  lng: number | null;
}

interface VerifyNumberModal {
  isOpen: boolean | null;
  data: any | null;
}

interface UIState {
  suggestions: any[];
  isFetching: boolean;
  isMobileMenuOpen: boolean;
  requestLoading: boolean;
  drawer: Drawer;
  bottomSheet: BottomSheet;
  mapLocation: MapLocation;
  verifyNumberModal: VerifyNumberModal;
  isRequestModalOpen: boolean | null;
  fullScreenImage: {
    isOpen: boolean;
    data: any | null;
  };
}

const initialState: UIState = {
  suggestions: [],
  isFetching: false,
  isMobileMenuOpen: false,
  requestLoading: false,
  drawer: {
    isOpen: false,
    type: '',
    uuid: null,
    data: null,
    position: 'right',
  },
  bottomSheet: {
    isBottomSheetOpen: false,
    bottomSheetType: null,
  },
  mapLocation: {
    lat: null,
    lng: null,
  },
  verifyNumberModal: {
    isOpen: false,
    data: null,
  },
  fullScreenImage: {
    isOpen: false,
    data: null,
  },
  isRequestModalOpen: null,
};

const ui = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    geoLocationsRequest(state) {
      state.isFetching = true;
    },
    geoLocationsSuccess(state, { payload }) {
      const suggestions: any[] = [];
      if (payload.data?.[0]) {
        Object.entries(payload.data[0]).forEach(([id, data]: any) => {
          suggestions.push(data.map((el: any) => ({ ...el, dataType: id })));
        });
      }

      state.isFetching = false;
      state.suggestions = suggestions.flat();
    },
    geoLocationsFailure(state) {
      state.isFetching = false;
    },
    openDrawer(state, { payload }) {
      const {
        uuid = null,
        type = null,
        position = 'right',
        data = null,
      } = payload;
      state.drawer = {
        isOpen: true,
        type,
        uuid,
        data,
        position,
      };
    },
    closeDrawer(state) {
      state.drawer = { ...initialState.drawer };
    },
    setMobileMenuStatus(state, { payload }) {
      state.isMobileMenuOpen = payload.status;
    },
    requestOngoing(state) {
      state.requestLoading = true;
    },
    requestFulfilled(state) {
      state.requestLoading = false;
    },
    toggleBottomSheet(state, { payload }) {
      state.bottomSheet = {
        isBottomSheetOpen: payload.isOpen,
        bottomSheetType: payload.type,
      };
    },
    setMapLocation(state, { payload }) {
      state.mapLocation = {
        lat: payload.lat,
        lng: payload.lng,
      };
    },
    openRequestModal(state, { payload }) {
      state.isRequestModalOpen = payload;
    },
    toggleVerifyNumberModal(state, { payload }) {
      state.verifyNumberModal = payload;
    },
    toggleFullScreenImage(state, { payload }) {
      state.fullScreenImage = payload;
    },
  },
});

const { actions, reducer } = ui;

export const {
  closeDrawer,
  geoLocationsFailure,
  geoLocationsRequest,
  geoLocationsSuccess,
  openDrawer,
  openRequestModal,
  requestFulfilled,
  requestOngoing,
  setMapLocation,
  setMobileMenuStatus,
  toggleBottomSheet,
  toggleVerifyNumberModal,
  toggleFullScreenImage,
} = actions;

export default reducer;
