import { createSlice } from '@reduxjs/toolkit';

interface ProviderState {
  data: {
    services: any[];
    links: any;
    name: string;
    publicSettings: {
      vaccinationRequiredForBookings: boolean;
      policyPriceMessage: string;
    };
  };
  isLoading: boolean;
  providerUuid: string | null;
}

const initialState: ProviderState = {
  data: {
    services: [],
    links: null,
    name: '',
    publicSettings: {
      vaccinationRequiredForBookings: false,
      policyPriceMessage: '',
    },
  },
  isLoading: false,
  providerUuid: null,
};

const provider = createSlice({
  name: 'provider',
  initialState,
  reducers: {
    providerLocationRequest(state) {
      state.isLoading = true;
    },
    providerLocationSuccess(state, { payload }) {
      state.providerUuid = payload.data.uuid;
      // eslint-disable-next-line prefer-destructuring
      state.data = payload.data.locations?.[0] || payload.data;
      state.isLoading = false;
    },
    providerLocationFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = provider;

export const {
  providerLocationRequest,
  providerLocationSuccess,
  providerLocationFailure,
} = actions;

export default reducer;
