import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { editForm, resetForm } from '@/store/reducers/booking-form';
import DefaultAvatar from '@/atoms/default-avatar';
import Button from '@/atoms/button';
import { isNotEmptyArray } from '@/util';
import { Animal } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import SelectableItem from '../components/selectable-item';
import useBookingFlow from '../hooks/use-booking-flow';
import '../booking-flow.scss';

export default function BookingPetSelect() {
  const dispatch = useAppDispatch();
  const { availablePets } = useAppSelector((state) => state.bookingFlow);
  const { animal: selectedPet } = useAppSelector((state) => state.bookingForm);
  const { processNextStep } = useBookingFlow();
  const canContinue = !!selectedPet;

  const onSelectPet = (pet: Animal) => {
    if (selectedPet?.uuid !== pet.uuid) {
      dispatch(resetForm({ keysToPreserve: ['location'] }));
    }
    dispatch(editForm({ key: 'animal', value: pet }));
  };

  const onNext = async () => {
    if (!canContinue) return;
    await processNextStep();
  };

  return (
    <>
      <Helmet title="Select pet for booking | PawCare" />
      <div className="pet-select flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32">
            <h2 className="f26 bold">Select pet</h2>
            {isNotEmptyArray(availablePets) ? (
              <>
                <div className="flex-column gap-12">
                  {availablePets.map((pet) => (
                    <SelectableItem
                      key={pet.uuid}
                      onClick={() => onSelectPet(pet)}
                      isActive={pet.uuid === selectedPet?.uuid}
                      className="gap-16"
                    >
                      <DefaultAvatar
                        size="small"
                        avatar={pet.links?.image?.desktop?.large || ''}
                      />
                      <p className="f16 bold text-black text-left">
                        {pet.name}
                      </p>
                    </SelectableItem>
                  ))}
                </div>
                <Button
                  text="Next"
                  onClick={onNext}
                  size="large"
                  className="desktop-next-btn w-fit"
                  variant={canContinue ? 'default' : 'disabled'}
                />
              </>
            ) : (
              <p className="f16 fw-400">No pets available</p>
            )}
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            onClick={onNext}
            className="mt-25"
          />
        </div>
      </div>
    </>
  );
}
