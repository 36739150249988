import './provider-card.scss';

import { useEffect, useRef } from 'react';
import { useAppDispatch } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import { UserDetails } from '@/organisms/user-details';
import { clearForm, editForm } from '@/store/reducers/request-appointment-form';

interface ProviderCardProps {
  item: any;
  className?: any;
  isActive: any;
  onSelectProvider: any;
  cardLink: any;
}

export function ProviderCard({
  item,
  className,
  isActive,
  onSelectProvider,
  cardLink,
}: ProviderCardProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const { width } = useWindowDimensions();
  const isMobile = width < 1175;

  useEffect(() => {
    if (isActive) {
      const refItem = ref.current as any;
      if (isMobile) {
        const offset = 290;
        const elementPosition = refItem?.getBoundingClientRect().top;
        const offsetPosition = elementPosition - offset;

        window.scrollBy({
          top: offsetPosition,
          behavior: 'smooth',
        });
      } else {
        refItem?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [isActive]);

  const onClickCard = () => {
    dispatch(clearForm());
    dispatch(editForm({ key: 'dateStart', value: null }));
    onSelectProvider();
    navigate(cardLink);
  };

  return (
    <div
      role="button"
      className={classNames(
        'search-provider-card pointer',
        isActive && 'search-provider-card--active',
        className,
      )}
      ref={ref}
      onClick={onClickCard}
      tabIndex={0}
    >
      <div>
        <UserDetails item={item} />
      </div>
    </div>
  );
}
