import { StayLog } from '@/types/composite';
import dayjs from 'dayjs';
import { Note } from '@phosphor-icons/react';
import { toggleFullScreenImage } from '@/store/reducers/ui';
import { useAppDispatch } from '@/hooks';

export default function Logs({ stayLogs }: { stayLogs: StayLog[] }) {
  const dispatch = useAppDispatch();

  return stayLogs.map((log) => (
    <div key={log.uuid}>
      <div className="flex-row align-start gap-8">
        <Note size={24} color="#d5d3d8" weight="fill" />
        <div className="flex-column">
          <p className="f12">{log.note}</p>
          <p className="f11 text-light-grey semibold mt-8">
            {dayjs(log.dateCreated).format('MMM DD, YYYY')}
          </p>
          {log.images.length > 0 && (
            <div className="flex-row gap-8 mt-14">
              {log.images.map((image) => (
                <button
                  className="no-style-btn pointer"
                  onClick={() =>
                    dispatch(
                      toggleFullScreenImage({
                        isOpen: true,
                        data: { image: image?.links?.image?.desktop?.large },
                      }),
                    )
                  }
                >
                  <img
                    src={image?.links?.image?.desktop?.small}
                    alt={image.uuid}
                    className="radius-8"
                  />
                </button>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  ));
}
