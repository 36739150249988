import './input.scss';

import React, { ChangeEvent, useEffect, useState } from 'react';
import classNames from 'classnames';
import Button, { ButtonSize, ButtonVariant } from '@/atoms/button';
import Icon from '@/atoms/icon';
import { prettifyNumber } from '@/util';
import { IconName } from '../icon/icons';

interface InputProps {
  placeholder?: string;
  inputType?: string;
  isMoney?: boolean;
  isPercentage?: boolean;
  id?: string;
  className?: string;
  error?: boolean;
  label?: string;
  maxLength?: number | string;
  onPaste?: (e: ClipboardEvent) => void;
  readOnly?: boolean;
  icon?: IconName;
  iconColor?: string;
  variant?: string;
  value?: string;
  size?: string;
  textAlignment?: string;
  displaySubmitButton?: boolean;
  submitButtonProps?: {
    text: string;
    size: ButtonSize;
    shape: 'round' | 'square';
    variant: ButtonVariant;
  };
  autoComplete?: string;
  isNumber?: boolean;
  onChange?: (value: string) => void;
  onKeyDown?: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  type?: string;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      placeholder,
      inputType = 'input',
      isMoney,
      isPercentage,
      id,
      className,
      label,
      maxLength = '',
      onPaste,
      readOnly,
      icon,
      iconColor = 'grey',
      variant,
      size,
      textAlignment = 'left',
      displaySubmitButton,
      submitButtonProps,
      autoComplete,
      ...props
    },
    ref,
  ) => {
    const [inputValue, setValue] = useState('');
    const Component = inputType;

    useEffect(() => {
      let str = props.value || '';
      if (isPercentage) {
        str += '%';
      }
      setValue(str);
    }, [props.value]);

    const onChange = (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      let formattedValue = value.replace(/%/g, '');

      if (isMoney || isPercentage) {
        if (Number.isNaN(+formattedValue)) return;
      }

      if (props.isNumber) {
        formattedValue = prettifyNumber(formattedValue);
      }

      if (isMoney) {
        formattedValue = formattedValue.trim().replace(/,/g, '');
      }

      if (isPercentage) {
        formattedValue += '%';
      }

      setValue(formattedValue);
      if (props.onChange) {
        props.onChange(formattedValue.trim().replace(/%/g, ''));
      }
    };

    const onKeyDown = (e: any) => {
      if (props.onKeyDown && e.key === 'Enter') {
        props.onKeyDown(e.target.value);
        setValue('');
      }
    };

    return (
      <div
        className={classNames(
          'input',
          className,
          `input--${variant}`,
          `input--${size}`,
        )}
      >
        {label && <p className="bold f18 mb-16 mt-0">{label}</p>}
        <div className="input-field" style={icon ? { paddingLeft: 24 } : {}}>
          {icon && <Icon name={icon} className="mr-10" fill={iconColor} />}
          <div className="justify-center input-field-container">
            <span className="currency">{isMoney && '$'}</span>
            <Component
              // @ts-expect-error Type mismatch between Component and ref
              ref={ref}
              className={classNames(
                'input-component',
                icon && 'input-component--disable-focus',
                isMoney && 'input-component--with-currency',
                textAlignment === 'center' && 'text-center',
                textAlignment === 'right' && 'text-right',
                props.disabled && 'c-secondary-purple',
              )}
              id={id}
              onChange={onChange}
              type={props.type || 'text'}
              value={inputValue}
              placeholder={placeholder}
              autoComplete={autoComplete}
              onKeyDown={onKeyDown}
              maxLength={maxLength}
              onPaste={onPaste}
              readOnly={readOnly}
              autoFocus={props.autoFocus}
              disabled={props.disabled}
            />
          </div>
          {displaySubmitButton && (
            <Button
              text={submitButtonProps?.text || undefined}
              shape={submitButtonProps?.shape || 'square'}
              size={submitButtonProps?.size || 'medium'}
              variant={submitButtonProps?.variant || 'green'}
              className="pos-absolute search-input-submit-button"
              onClick={() => {
                if (props.onKeyDown) {
                  props.onKeyDown(inputValue);
                }
              }}
            />
          )}
        </div>
      </div>
    );
  },
);
export default Input;
