import { useAppSelector } from '@/hooks';
import Icon from '@/atoms/icon';
import classNames from 'classnames';
import { prettifyUrl } from '@/util';
import OpenHours from './open-hours';

export default function ProviderInfo({ className }: { className?: string }) {
  const { location: providerLocation } = useAppSelector(
    (state) => state.bookingForm,
  );
  const { locality, address1, region, postalCode, links } =
    providerLocation || {};

  const mapImage = `https://maps.googleapis.com/maps/api/staticmap?size=600x250&maptype=terrain&zoom=14&markers=${encodeURIComponent(
    `icon:https://i.imgur.com/ESZho3g.png|${address1},${locality},${region}`,
  )}&key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
  const mapLink = `https://maps.google.com/maps?q=${address1}, ${region}, ${locality}`;

  return (
    <div className={classNames('flex-column gap-56 provider-info', className)}>
      <OpenHours />
      <div className="provider-info-map border-light-purple radius-12 overflow-hidden w-100">
        <a href={mapLink} target="_blank" rel="noreferrer">
          <div
            className="background-cover background-position-50 h-250 w-100"
            style={{ backgroundImage: `url(${mapImage})` }}
          />
        </a>
        <div className="flex-column p-16">
          <h2 className="f20 bold">Location details</h2>
          <p className="f13 fw-400">{address1}</p>
          <p className="f13 fw-400">{`${locality}, ${region} ${postalCode}`}</p>
        </div>
        <div className="p-16 border-top-light-purple">
          <a
            href={mapLink}
            target="_blank"
            rel="noreferrer"
            className="flex-row gap-12"
          >
            <Icon name="map-drop" fill="#FF9838" />
            <p className="f13 fw-400">Get directions</p>
          </a>
        </div>
        {links?.website && (
          <div className="p-16 border-top-light-purple">
            <a
              href={links.website}
              target="_blank"
              rel="noreferrer"
              className="flex-row gap-12"
            >
              <Icon name="world" fill="#FF9838" />
              <p className="f13 fw-400">{prettifyUrl(links.website)}</p>
            </a>
          </div>
        )}
      </div>
    </div>
  );
}
