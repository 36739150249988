import { useAppSelector } from '@/hooks';
import { formatTime } from '@/pages/booking-flow/utils';

export default function OpenHours() {
  const dowOptions = useAppSelector((state) => state.main.calendar.dayOfWeek);
  const { location: providerLocation } = useAppSelector(
    (state) => state.bookingForm,
  );
  const { hours = [] } = providerLocation || {};

  return (
    <div className="flex-column gap-16">
      <h2 className="f20 bold">Open hours</h2>
      <div className="flex-column gap-12">
        {dowOptions.map((dayOption) => {
          const hourForDay = hours.find(
            (hour) => hour.dow?.id === dayOption.id,
          );
          return (
            <div
              key={dayOption.id}
              className="flex-row justify-between gap-16 w-100"
            >
              <p className="f16 fw-400">{dayOption.value}</p>
              <p className="f16 fw-400">
                {hourForDay ? (
                  <>
                    {formatTime(hourForDay.time.start)}
                    {' — '}
                    {formatTime(hourForDay.time.end)}
                  </>
                ) : (
                  'Closed'
                )}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
