import { MedicationOptions, Behavior } from '@/types/composite';
import { createSlice } from '@reduxjs/toolkit';

interface MainState {
  isLoading: boolean;
  paymentInitData: any;
  calendar: {
    dayOfWeek: any[];
  };
  core: {
    age: any[];
    behavior: Behavior[];
    breed: any[];
    gender: any[];
    hair: {
      length: any[];
    };
    medication: MedicationOptions;
    size: any[];
    sterilization: any[];
    type: any[];
    vaccination: any[];
  };
  geo: {
    country: any[];
    countryCode: any[];
    state: any[];
    timeZone: any[];
  };
  provider: {
    service: {
      tag: any[];
    };
    type: any[];
  };
  market: {
    default: any;
    illinois: any;
    chicago: any;
  };
  search: {
    appointmentDateMin: any;
  };
}

const initialState: MainState = {
  isLoading: false,
  paymentInitData: null,
  calendar: {
    dayOfWeek: [],
  },
  core: {
    age: [],
    behavior: [],
    breed: [],
    gender: [],
    hair: {
      length: [],
    },
    medication: {
      drug: [],
      frequency: [],
    },
    size: [],
    sterilization: [],
    type: [],
    vaccination: [],
  },
  geo: {
    country: [],
    countryCode: [],
    state: [],
    timeZone: [],
  },
  provider: {
    service: {
      tag: [],
    },
    type: [],
  },
  market: {
    default: {},
    illinois: {},
    chicago: {},
  },
  search: {
    appointmentDateMin: null,
  },
};

const main = createSlice({
  name: 'main',
  initialState,
  reducers: {
    bootstrapRequest(state) {
      state.isLoading = true;
    },
    bootstrapSuccess(state, { payload }) {
      const { calendar, core, geo, provider, market, search } = payload.data;

      const UStimeZones = [374, 519, 528, 498];
      const timeZones = geo.timeZone.reduce((acc: any, element: any) => {
        if (UStimeZones.includes(element.id)) {
          return [element, ...acc];
        }
        return [...acc, element];
      }, []);
      const countryCodes = geo.countryCode.map((el: any) => ({
        ...el,
        value: el.country,
      }));
      const unitedStates = countryCodes.find(
        (el: any) => el.country === 'United States',
      );
      const fromIndex = countryCodes.indexOf(unitedStates);
      const toIndex = 0;
      const element = countryCodes.splice(fromIndex, 1)[0];
      countryCodes.splice(toIndex, 0, element);

      state.market = market;
      state.calendar = calendar;
      state.core = {
        ...core,
        type: core.type.map((_: any, index: any) => core.type[(index + 1) % 2]),
      };
      state.geo = geo;
      state.geo.countryCode = countryCodes;
      state.geo.timeZone = timeZones;
      state.provider = provider;
      state.isLoading = false;
      state.search = search;
    },
    bootstrapFailure(state) {
      state.isLoading = false;
    },
    paymentInitSuccess(state, { payload }) {
      state.paymentInitData = payload.data;
    },
  },
});

const { actions, reducer } = main;

export const {
  bootstrapRequest,
  bootstrapSuccess,
  bootstrapFailure,
  paymentInitSuccess,
} = actions;

export default reducer;
