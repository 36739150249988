import { getStayReport } from '@/actions/report';
import Button from '@/atoms/button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DefaultAvatar from '@/atoms/default-avatar';
import dayjs from 'dayjs';
import FullScreenLoader from '@/atoms/full-screen-loader';
import toast from 'react-hot-toast';
import Logs from './components/logs';
import PetInfo from './components/pet-info';

function Report() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { data, isLoading } = useAppSelector((state: any) => state.report);
  const { stayLogs, appointment } = data || {};

  useEffect(() => {
    if (!uuid) return;
    dispatch(getStayReport(uuid))?.then(({ data }) => {
      if (!data?.data?.appointment) {
        toast.error(
          'Oops, we couldn’t find that report. Please get in touch with us.',
        );
        navigate('/');
      }
    });
  }, [uuid]);

  const onBookAgain = () => {
    navigate(`/chicago/p/${appointment.location.slug}`);
  };

  if (isLoading || !appointment) return <FullScreenLoader />;

  return (
    <div className="w-100 margin-center max-w-600 pb-32">
      <Helmet title="Report | PawCare">
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DOMAIN}${location.pathname}`}
        />
      </Helmet>
      <div className="grid gap-16 p-20">
        <div className="flex-column align-center gap-16 mt-32 mb-16">
          <DefaultAvatar
            size="big"
            avatar={appointment.location.avatarUrls?.desktop?.large}
          />
          <h1 className="f26">Pet Report Card</h1>
        </div>
        <PetInfo appointment={appointment} className="pt-15 pb-15" />
        <div className="flex-row justify-between p-24 border-light-purple radius-24">
          <div className="flex-column">
            <h2 className="f16">Reservation details</h2>
            <p className="f10 text-grey">
              {dayjs(appointment?.dateStart).format('MMM DD, YYYY')}
            </p>
          </div>
          <Button text="Book again" size="medium" onClick={onBookAgain} />
        </div>
        {stayLogs.length > 0 && (
          <div className="flex-column p-24 border-light-purple radius-24 gap-22">
            <h2 className="f16">Stay recap</h2>
            <Logs stayLogs={stayLogs} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Report;
