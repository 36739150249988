import Checkbox from '@/atoms/checkbox';
import { ReactNode } from 'react';
import './checkbox-with-actions.scss';

export default function CheckboxWithActions({
  id,
  value,
  onClick,
  actions,
  children,
}: {
  id: string;
  value: boolean;
  onClick: () => void;
  actions: ReactNode;
  children: ReactNode;
}) {
  return (
    <button
      onClick={onClick}
      className="checkbox-with-actions flex-row justify-between gap-4 w-100 radius-12 border-light-purple py-16 pr-24 pl-32 b-white pointer"
    >
      <div>
        <Checkbox
          id={id}
          value={value}
          onClick={onClick}
          disableInternalCheck
          checkmarkColor="orange"
        >
          {children}
        </Checkbox>
      </div>
      <div
        role="toolbar"
        className="checkbox-actions flex-row gap-4 ml-auto"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        {actions}
      </div>
    </button>
  );
}
