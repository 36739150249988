import { isNotEmptyArray } from '@/util';
import dayjs from 'dayjs';
import DateTimeSlots from './date-time-slots';
import FixedTimeSelect from './fixed-time-select';
import HourlyTimeSelect from './hourly-time-select';

export default function TimeSelection({
  selectedDate,
  selectedTimeSlots,
  isBoarding,
  isHourlyDaycare,
  isFixedDaycare,
  selectedCheckin,
  selectedCheckout,
  setSelectedCheckin,
  setSelectedCheckout,
  durationMinutes,
  onNext,
}: {
  selectedDate: string;
  selectedTimeSlots: string[];
  isBoarding: boolean;
  isHourlyDaycare: boolean;
  isFixedDaycare: boolean;
  selectedCheckin: string;
  selectedCheckout: string;
  setSelectedCheckin: (checkin: string) => void;
  setSelectedCheckout: (checkout: string) => void;
  durationMinutes: number;
  onNext: (checkin?: string) => void;
}) {
  if (isBoarding) return null;

  if (!selectedDate || !isNotEmptyArray(selectedTimeSlots)) {
    return <p className="f16 fw-400">No time slots available</p>;
  }

  if (isHourlyDaycare) {
    return (
      <HourlyTimeSelect
        selectedDate={selectedDate}
        timeSlots={selectedTimeSlots}
        selectedCheckin={selectedCheckin}
        selectedCheckout={selectedCheckout}
        onCheckinChange={setSelectedCheckin}
        onCheckoutChange={setSelectedCheckout}
      />
    );
  }

  if (isFixedDaycare) {
    return (
      <FixedTimeSelect
        selectedDate={selectedDate}
        timeSlots={selectedTimeSlots}
        durationMinutes={durationMinutes}
        selectedCheckin={selectedCheckin}
        selectedCheckout={selectedCheckout}
        onTimeChange={(time) => {
          setSelectedCheckin(time);
          const endTime = dayjs(`2000-01-01T${time}`)
            .add(durationMinutes, 'minutes')
            .format('HH:mm:ss');
          setSelectedCheckout(endTime);
        }}
      />
    );
  }

  return (
    <DateTimeSlots
      date={selectedDate}
      timeSlots={selectedTimeSlots}
      onSelectSlot={(time) => {
        onNext(time);
      }}
    />
  );
}
