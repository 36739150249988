import { Helmet } from 'react-helmet-async';
import Button from '@/atoms/button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { isNotEmptyArray } from '@/util';
import Checkbox from '@/atoms/checkbox';
import { useState, useEffect } from 'react';
import { editPet } from '@/actions/pet';
import { editForm } from '@/store/reducers/booking-form';
import { loadUser } from '@/actions/user';
import { Behavior } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import useBookingFlow from '../hooks/use-booking-flow';
import '../booking-flow.scss';

export default function BookingBehavior() {
  const dispatch = useAppDispatch();
  const { processNextStep } = useBookingFlow();
  const { availableBehaviors } = useAppSelector((state) => state.bookingFlow);
  const { animal } = useAppSelector((state) => state.bookingForm);
  const { behaviors: animalBehaviors, uuid: auid } = animal || {};
  const [selectedBehaviors, setSelectedBehaviors] = useState<Behavior[]>([]);

  useEffect(() => {
    setSelectedBehaviors(animalBehaviors || []);
  }, [animalBehaviors]);

  const onBehaviorChange = (behavior: Behavior) => {
    setSelectedBehaviors((previousState) =>
      previousState.some(
        (selectedBehavior) => selectedBehavior.id === behavior.id,
      )
        ? previousState.filter(
            (selectedBehavior) => selectedBehavior.id !== behavior.id,
          )
        : [...previousState, behavior],
    );
  };

  const onNext = async () => {
    if (auid) {
      const editPetCall = await dispatch(
        editPet({ behaviors: selectedBehaviors }, auid),
      );
      if (editPetCall?.success) {
        const updatedPet = editPetCall.data?.data;
        dispatch(editForm({ key: 'animal', value: updatedPet }));
        await dispatch(loadUser());
      }
    } else {
      dispatch(
        editForm({
          key: 'animal',
          value: { ...animal, behaviors: selectedBehaviors },
        }),
      );
    }
    await processNextStep();
  };

  return (
    <>
      <Helmet title="Social behavior for booking | PawCare" />
      <div className="behavior flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32 max-w-600">
            <div className="flex-column">
              <h2 className="f26 bold">Social behavior</h2>
              <p className="f16 fw-400">
                Tell us a bit about how your pet behaves
              </p>
            </div>
            <div className="flex-column gap-16">
              <p className="f18 bold">Select one or more</p>
              {isNotEmptyArray(availableBehaviors) ? (
                availableBehaviors.map((behavior) => (
                  <Checkbox
                    id={`behavior-${behavior.id}`}
                    key={behavior.id}
                    value={selectedBehaviors.some(
                      (selectedBehavior) => selectedBehavior.id === behavior.id,
                    )}
                    onClick={() => onBehaviorChange(behavior)}
                    variant="selectable"
                  >
                    {behavior.value}
                  </Checkbox>
                ))
              ) : (
                <p className="f16 fw-400">No behavior options available</p>
              )}
            </div>

            <Button
              text="Next"
              onClick={onNext}
              size="large"
              className="desktop-next-btn w-fit"
              variant="default"
            />
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button text="Next" onClick={onNext} className="mt-25" />
        </div>
      </div>
    </>
  );
}
