export default function Grooming() {
  return (
    <svg
      width="23"
      height="24"
      viewBox="0 0 23 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8089 7.25472V7.79399C19.8089 7.93206 19.9208 8.04399 20.0589 8.04399H20.9367C21.0748 8.04399 21.1867 7.93206 21.1867 7.79399V7.02255C21.1867 6.18299 20.5055 5.49812 19.6618 5.49812H16.1961C16.058 5.49812 15.9461 5.61004 15.9461 5.74812V6.62551C15.9461 6.76358 16.058 6.87551 16.1961 6.87551H19.4295C19.6386 6.87168 19.8089 7.04192 19.8089 7.25472Z"
        fill="#150828"
      />
      <path
        d="M8.19383 4.30254H14.6108C15.0946 4.30254 15.4894 3.90786 15.4894 3.42425V0.386955C15.4894 0.174155 15.3152 0 15.1023 0C14.8894 0 14.7152 0.174147 14.7152 0.386955V2.92502H14.2546L14.2547 0.386955C14.2547 0.174155 14.0805 0 13.8676 0C13.6548 0 13.4806 0.174147 13.4806 0.386955V2.92502H13.02L13.0201 0.386955C13.0201 0.174155 12.8458 0 12.633 0C12.4201 0 12.2459 0.174147 12.2459 0.386955V2.92502H11.7853L11.7854 0.386955C11.7854 0.174155 11.6112 0 11.3983 0C11.1854 0 11.0112 0.174147 11.0112 0.386955V2.92502H10.5506L10.5507 0.386955C10.5507 0.174155 10.3765 0 10.1636 0C9.95074 0 9.77654 0.174147 9.77654 0.386955V2.92502H9.31595L9.31603 0.386955C9.31603 0.174155 9.14183 0 8.92895 0C8.71607 0 8.54186 0.174147 8.54186 0.386955V2.92502H8.08127L8.08136 0.386955C8.08136 0.174155 7.90715 0 7.69427 0C7.48139 0 7.30719 0.174147 7.30719 0.386955V3.42425C7.31501 3.91177 7.70598 4.30254 8.1936 4.30254H8.19383Z"
        fill="#150828"
      />
      <path
        d="M7.1024 19.1715C7.05592 20.2781 6.87788 21.5819 6.33606 22.2978C6.33606 22.2978 4.76855 22.8085 4.88082 23.9962H6.33606C6.33606 23.9962 7.28042 23.1296 7.98481 21.6246C7.90357 20.9514 7.68296 20.0421 7.1024 19.1716V19.1715Z"
        fill="#150828"
      />
      <path
        d="M6.87783 10.2184C6.76557 10.4777 6.62239 10.7097 6.44817 10.8993C6.15015 11.2127 5.78636 11.3869 5.42248 11.3869C5.36053 11.3869 5.29475 11.383 5.2328 11.3714C4.3968 11.2321 3.8898 10.2262 4.0833 9.08082C4.15681 8.63975 4.3271 8.23735 4.57099 7.91623C4.70262 7.74599 4.94251 7.71507 5.11281 7.84657C5.2831 7.97816 5.31404 8.21797 5.18249 8.38821C5.0161 8.60493 4.89611 8.89502 4.8419 9.20849C4.71417 9.96293 5.01219 10.551 5.3567 10.6091C5.58505 10.6477 5.77853 10.4775 5.87924 10.3692C6.0882 10.1486 6.2392 9.81589 6.30106 9.45221C6.34364 9.18913 6.33973 8.92602 6.28559 8.69386C6.28169 8.67449 6.28559 8.65521 6.28169 8.63584C5.8211 7.39387 5.17476 7.22744 5.17476 7.22744C3.4253 6.442 1.74946 8.16768 1.74946 8.16768H0V8.601C0 9.79268 0.967561 10.756 2.15574 10.756C4.84558 11.3132 5.32943 14.157 5.32943 14.157C5.43005 16.4165 7.25691 18.1151 7.25691 18.1151C9.08368 20.1696 8.77794 22.7309 8.77794 22.7309C7.91481 22.8315 7.83739 24 7.83739 24H9.56356C10.1983 22.6304 10.2215 18.088 10.2215 18.088C10.7789 18.0957 11.5723 17.9797 12.3852 17.8172L12.3853 13.4722C12.3853 13.1395 12.4395 12.8183 12.5402 12.5204C8.92135 12.4198 7.45446 11.0927 6.87779 10.2183L6.87783 10.2184ZM3.05382 8.90674C2.84486 8.90674 2.67838 8.7365 2.67838 8.53143C2.67838 8.32636 2.84868 8.15611 3.05382 8.15611C3.26278 8.15611 3.42926 8.32635 3.42926 8.53143C3.42926 8.7365 3.26279 8.90674 3.05382 8.90674Z"
        fill="#150828"
      />
      <path
        d="M12.2106 5.07649H10.5973L10.1059 8.99967C10.0092 9.78122 10.6166 10.4699 11.4059 10.4699C12.1914 10.4699 12.8027 9.78122 12.7059 8.99967L12.2106 5.07649Z"
        fill="#150828"
      />
      <path
        d="M15.3812 10.4159C15.4037 10.4159 15.4264 10.4119 15.4527 10.4119H19.5597C19.5822 10.4119 19.6049 10.4159 19.6312 10.4159V10.1102C19.6312 9.68852 19.2999 9.34804 18.8896 9.34804L16.1228 9.34796C15.7125 9.34796 15.3813 9.68844 15.3813 10.1101L15.3812 10.4159Z"
        fill="#150828"
      />
      <path
        d="M16.8751 7.64542H18.1251V8.64542H16.8751V7.64542Z"
        fill="#150828"
      />
      <rect x="13.1" y="11" width="9" height="13" rx="3" fill="#150828" />
    </svg>
  );
}
