import './footer.scss';

import { Link } from 'react-router-dom';

import Icon from '@/atoms/icon';
import { currentYear } from '@/util/constants';

export function Footer() {
  return (
    <div className="footer flex-row justify-between">
      <div className="flex-column align-start footer-left">
        <Link to={localStorage.getItem('homepageUrl') || '/'}>
          <Icon
            name="pawcare"
            width={125}
            height="100%"
            fill="#fff5eb"
            outterColor="black"
          />
        </Link>
        <p>
          PawCare makes it easy for you to connect with providers in your area
          and manage all of your pet care in one central place.
        </p>
        <div className="flex-row actions flex-wrap">
          <Link
            to="https://apps.apple.com/app/pawcare/id6446097633"
            className="mr-15"
          >
            <Icon name="apple-store" />
          </Link>
          <Link to="https://play.google.com/store/apps/details?id=com.mypawcare">
            <Icon name="google-play" />
          </Link>
        </div>
        <span>© {currentYear} PawCare. All rights reserved.</span>
      </div>
      <div className="flex-row align-start">
        <ul className="flex-column footer-list mr-96">
          <li>
            <Link
              to={`${localStorage.getItem('homepageUrl') || '/'}?home=true`}
            >
              Home
            </Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <a href="https://pawcare.pet/">Provider</a>
          </li>
          <li>
            <Link to="/about">About us</Link>
          </li>
        </ul>
        <ul className="flex-column footer-list">
          <li>
            <a href="mailto:hello@mypawcare.com">Contact</a>
          </li>
          <li>
            <a href="/privacy.html">Privacy Policy</a>
          </li>
          <li>
            <Link to="/terms">Terms of Service</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}
