import {
  setCurrentStep,
  setError,
  setIsLoading,
} from '@/store/reducers/booking-flow';
import { BookingFlowStep } from '@/types/others';
import {
  STEPS,
  BOOKING_FLOW_SERVICE_STEPS,
  BOOKING_FLOW_GENERAL_STEPS,
} from './flow-steps';

import { AppDispatch, RootState } from '../../../store';

export class FlowManager {
  static async processNextStep(
    dispatch: AppDispatch,
    getState: () => RootState,
    currentStep?: BookingFlowStep,
  ): Promise<BookingFlowStep> {
    dispatch(setIsLoading(true));
    dispatch(setError(null));

    try {
      const state = getState();
      const { token } = state.user;
      const isGuest = !token;
      const initialStep = isGuest ? 'pet-information' : 'pet-select';

      const { serviceType } = state.bookingForm;

      const flowSteps = serviceType
        ? [
            ...BOOKING_FLOW_GENERAL_STEPS,
            ...BOOKING_FLOW_SERVICE_STEPS[serviceType],
          ]
        : BOOKING_FLOW_GENERAL_STEPS;

      const currentStepIndex = flowSteps.indexOf(
        (currentStep || initialStep) as BookingFlowStep,
      );

      for (let i = currentStepIndex; i < flowSteps.length; i++) {
        const nextStep = !currentStep ? flowSteps[i] : flowSteps[i + 1];

        if (!nextStep) {
          dispatch(setCurrentStep('booking-confirmation'));
          return 'booking-confirmation';
        }
        const stepDefinition = STEPS[nextStep as BookingFlowStep];
        dispatch(setCurrentStep(nextStep));

        if (stepDefinition.fetchData) {
          // We need to await the fetchData function before continuing
          // eslint-disable-next-line no-await-in-loop
          await stepDefinition.fetchData(dispatch, getState);
        }

        if (stepDefinition.shouldSkip) {
          // We need to await the shouldSkip function to check if we should return or loop
          // eslint-disable-next-line no-await-in-loop
          const shouldSkip = await stepDefinition.shouldSkip(
            dispatch,
            getState,
          );
          if (shouldSkip) {
            // If the step should be skipped, we loop to process the next step
            // eslint-disable-next-line no-continue
            continue;
          }
        }

        return nextStep as BookingFlowStep;
      }
    } catch (error) {
      const errorMessage =
        error instanceof Error
          ? error.message
          : 'An unexpected error occurred, please try again later';
      dispatch(setError(errorMessage));
      throw error;
    } finally {
      dispatch(setIsLoading(false));
    }

    return 'booking-confirmation';
  }

  static getStepFromRoute(route: string): BookingFlowStep | undefined {
    const cleanRoute = route.split('?')[0].replace(/\/$/, '');

    return Object.entries(STEPS).find(
      ([, stepDef]) => stepDef.route === cleanRoute,
    )?.[0] as BookingFlowStep | undefined;
  }
}
