import { Helmet } from 'react-helmet-async';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import useBookingFlow from '../hooks/use-booking-flow';
import Form from './form';

export default function UserInformation() {
  const { processNextStep } = useBookingFlow();
  const canContinue = true;

  const onNext = async () => {
    if (!canContinue) return;
    await processNextStep();
  };

  return (
    <>
      <Helmet title="User information for booking | PawCare" />
      <div className="user-information w-100 flex-row">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32">
            <h2 className="f26 bold">User information</h2>
            <Form onNext={onNext} />
          </div>
        </MainPanel>
      </div>
    </>
  );
}
