import './checkout.scss';
import '@/organisms/landing/landing.scss';
import useWindowDimensions from '@/helpers/use-window-dimensions';
import { InfoBlock } from '@/atoms/info-block';
import { ReactNode } from 'react';
import classNames from 'classnames';
import AppointmentInfo from './appointment-info';

interface CheckoutProps {
  children: ReactNode;
  mobileTitle?: string;
  mobileSubtitle?: string;
  hideInvoice?: boolean;
  showProgressBar?: boolean;
  progressBarData?: {
    maxSteps: number;
    currentStep: number;
  };

  removeOutterPadding?: boolean;
}

export default function Checkout({
  children,
  mobileTitle,
  mobileSubtitle,
  hideInvoice = true,
  showProgressBar = false,
  progressBarData,
  removeOutterPadding,
}: CheckoutProps) {
  const { width } = useWindowDimensions();
  const isMobile = width < 600;

  if (hideInvoice && isMobile)
    return (
      <div className="flex-row w-100 checkout-container">
        <div className="page-container checkout">{children}</div>
      </div>
    );

  return (
    <div
      className={classNames(
        'flex-row w-100 checkout-container',
        removeOutterPadding && 'padding-0',
      )}
    >
      <InfoBlock>
        <AppointmentInfo
          showProgressBar={showProgressBar}
          progressBarData={progressBarData}
          mobileTitle={mobileTitle || ''}
          mobileSubtitle={mobileSubtitle || ''}
        />
      </InfoBlock>
      <div className="page-container checkout">{children}</div>
    </div>
  );
}
