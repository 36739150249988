import { Helmet } from 'react-helmet-async';
import Button from '@/atoms/button';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useEffect, useState } from 'react';
import { editForm } from '@/store/reducers/booking-form';
import { editForm as editCreatePetForm } from '@/store/reducers/create-pet-form';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';
import { editPet } from '@/actions/pet';
import { loadUser } from '@/actions/user';
import { Medication } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import useBookingFlow from '../hooks/use-booking-flow';
import AddMedicationModal from './components/add-medication-modal';
import { MedicationList } from './components/medication-list';
import '../booking-flow.scss';

export default function BookingMedications() {
  const dispatch = useAppDispatch();
  const { animal, medications } = useAppSelector((state) => state.bookingForm);
  const { medications: lastUsedMedications, animal: lastUsedAnimal } =
    useAppSelector((state) => state.lastUserAppointment);
  const { uuid: auid } = animal || {};
  const { processNextStep } = useBookingFlow();
  const [selectedMedications, setSelectedMedications] = useState<Medication[]>(
    [],
  );
  const [isAddMedicationModalOpen, setIsAddMedicationModalOpen] =
    useState(false);
  const [editedMedication, setEditedMedication] = useState<Medication | null>(
    null,
  );

  useEffect(() => {
    if (animal?.medications) {
      dispatch(editForm({ key: 'medications', value: animal.medications }));
      if (animal.uuid === lastUsedAnimal?.uuid)
        setSelectedMedications(
          animal?.medications?.filter((medication) =>
            lastUsedMedications.some(
              (lastUsed: Medication) => lastUsed.id === medication.id,
            ),
          ),
        );
    }
  }, [animal?.medications]);

  const onMedicationChange = (medication: Medication) => {
    setSelectedMedications((previousState) =>
      previousState.some(
        (selectedMedication) => selectedMedication.id === medication.id,
      )
        ? previousState.filter(
            (selectedMedication) => selectedMedication.id !== medication.id,
          )
        : [...previousState, medication],
    );
  };

  const onDeleteMedication = async (deletedMedication: Medication) => {
    const updatedMedications = medications?.filter(
      (medication: Medication) => medication.id !== deletedMedication.id,
    );

    if (auid) {
      dispatch(
        editForm({
          key: 'medications',
          value: updatedMedications,
        }),
      );
    } else {
      dispatch(
        editCreatePetForm({
          key: 'medications',
          value: updatedMedications,
        }),
      );
    }
  };

  const onEditMedication = (medication: Medication) => {
    setEditedMedication(medication);
    setIsAddMedicationModalOpen(true);
  };

  const onAddMedicine = () => {
    setEditedMedication(null);
    setIsAddMedicationModalOpen(true);
  };

  const onNext = async () => {
    if (auid) {
      dispatch(
        editForm({
          key: 'medications',
          value: selectedMedications,
        }),
      );
      postMedications();
    } else {
      dispatch(
        editCreatePetForm({
          key: 'medications',
          value: selectedMedications,
        }),
      );
    }

    await processNextStep();
  };

  const postMedications = async () => {
    dispatch(requestOngoing());

    const editedPet = await dispatch(editPet({ medications }, auid));

    if (editedPet?.success) {
      await dispatch(loadUser());
    }

    dispatch(requestFulfilled());
  };

  return (
    <>
      <Helmet title="Medications for booking | PawCare" />
      <div className="medications flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32 max-w-660">
            <div className="flex-column">
              <h2 className="f26 bold">Medications</h2>
              <p className="f16 fw-400">
                Please tell us about any medications your animal is taking
              </p>
            </div>
            <div className="flex-column gap-24">
              <div className="flex-column gap-16">
                <h3 className="f18 bold">Medicine</h3>
                <MedicationList
                  medications={[...(medications || [])]}
                  selectedMedications={selectedMedications}
                  onMedicationChange={onMedicationChange}
                  onDeleteMedication={onDeleteMedication}
                  onEditMedication={onEditMedication}
                />
              </div>

              <Button
                text="Add medicine"
                size="medium"
                variant="white-outlined"
                icon="add"
                iconPosition="start"
                onClick={onAddMedicine}
                className="w-fit"
              />
            </div>
            <Button
              text="Next"
              onClick={onNext}
              size="large"
              className="desktop-next-btn w-fit"
              variant="default"
            />
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button text="Next" onClick={onNext} className="mt-25" />
        </div>
      </div>
      {isAddMedicationModalOpen && (
        <AddMedicationModal
          isOpen={isAddMedicationModalOpen}
          onClose={() => setIsAddMedicationModalOpen(false)}
          editedMedication={editedMedication}
        />
      )}
    </>
  );
}
