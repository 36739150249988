import classNames from 'classnames';
import { ReactNode } from 'react';

export default function SelectableItem({
  onClick,
  isActive,
  size = 'small',
  children,
  className,
}: {
  onClick: () => void;
  isActive?: boolean;
  size?: 'small' | 'large';
  children: ReactNode;
  className?: string;
}) {
  return (
    <button
      onClick={onClick}
      className={classNames(
        'flex-row align-center justify-start border-light-purple border-2 radius-12 b-white pointer',
        isActive && 'border-primary-orange b-light-orange',
        size === 'small' && 'py-16 px-20 max-w-420',
        size === 'large' && 'p-16 max-w-600',
        className,
      )}
    >
      {children}
    </button>
  );
}
