import './article.scss';

export function Article({
  article,
}: {
  article: { url: string; image: string; title: string; summary: string };
}) {
  return (
    <a
      className="flex-row mb-25 article"
      key={article.url}
      href={article.url}
      target="_blank"
      rel="noreferrer"
    >
      <div
        className="article-img"
        style={{ backgroundImage: `url(${article.image})` }}
      />
      <div className="article-content">
        <h2 className="mb-10">{article.title}</h2>
        <p className="mb-15 article-content-summary">{article.summary}</p>
        <p className="orange-text f16">View more</p>
      </div>
    </a>
  );
}
