import './cancel-appointment-modal.scss';

import ReactModal from 'react-modal';
import dayjs from 'dayjs';

import Button from '@/atoms/button';

interface CancelAppointmentModalProps {
  dateStart: Date;
  isOpen: boolean;
  openModal: (isOpen: boolean) => void;
  onCancelAppointment: () => void;
}

export default function CancelAppointmentModal({
  dateStart,
  isOpen,
  openModal,
  onCancelAppointment,
}: CancelAppointmentModalProps) {
  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => openModal(false)}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
      className="cancel-appointment-modal"
    >
      <div className="text-center cancel-appointment-modal-content">
        <h2 className="mb-15 f26">Cancel appointment?</h2>
        <p className="mb-32 f15">
          Are you sure you want to cancel your appointment on{' '}
          {dayjs(dateStart).format('dddd, MMMM D, h:mm a')}?
        </p>
        <div className="flex-row justify-between cancel-appointment-modal-content-actions margin-center">
          <Button
            size="medium"
            onClick={onCancelAppointment}
            text="Yes, cancel"
          />
          <Button
            size="medium"
            variant="reverse"
            onClick={() => openModal(false)}
            text="No, don't cancel"
          />
        </div>
      </div>
    </ReactModal>
  );
}
