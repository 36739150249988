import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { isNotEmptyArray } from '@/util';
import Button from '@/atoms/button';
import { useEffect, useState } from 'react';
import { editPet } from '@/actions/pet';
import { loadUser } from '@/actions/user';
import { editForm } from '@/store/reducers/booking-form';
import dayjs from 'dayjs';
import { Vaccination } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import useBookingFlow from '../hooks/use-booking-flow';
import { areVaccinationsValidInDate } from '../utils';
import { VaccinationList } from './vaccination-list';
import './vaccinations.scss';
import '../booking-flow.scss';

export default function BookingVaccinations() {
  const dispatch = useAppDispatch();
  const { processNextStep } = useBookingFlow();
  const { animal } = useAppSelector((state) => state.bookingForm);
  const { data: newAnimal } = useAppSelector((state) => state.createPetForm);
  const { vaccinations, name } = animal?.uuid ? animal : newAnimal;
  const { uuid: auid } = animal || {};
  const [selectedVaccinations, setSelectedVaccinations] = useState<
    Vaccination[]
  >([]);

  const canContinue =
    name && areVaccinationsValidInDate(selectedVaccinations, dayjs());

  useEffect(() => {
    const vaccinationsPresent = vaccinations && isNotEmptyArray(vaccinations);
    setSelectedVaccinations(
      vaccinationsPresent
        ? vaccinations.map((vaccination: any) => ({
            ...vaccination,
            uuid: vaccination.uuid || crypto.randomUUID(),
          }))
        : [],
    );
    if (!vaccinationsPresent) {
      onAddVaccine();
    }
  }, [vaccinations]);

  const onAddVaccine = () => {
    setSelectedVaccinations((currentVaccinations: any) => [
      ...currentVaccinations,
      { uuid: crypto.randomUUID() },
    ]);
  };

  const onNext = async () => {
    if (!canContinue) return;

    if (auid) {
      const editPetCall = await dispatch(
        editPet({ vaccinations: selectedVaccinations }, auid),
      );
      if (editPetCall?.success) {
        const updatedPet = editPetCall.data?.data;
        dispatch(editForm({ key: 'animal', value: updatedPet }));
        await dispatch(loadUser());
      }
    } else {
      dispatch(
        editForm({
          key: 'animal',
          value: { vaccinations: selectedVaccinations },
        }),
      );
    }
    await processNextStep();
  };

  return (
    <>
      <Helmet title="Select vaccinations for booking | PawCare" />
      <div className="vaccinations flex-row w-100 gap-32">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32 max-w-600">
            <div className="flex-column">
              <h2 className="f26 bold">Vaccinations</h2>
              <p className="f16 fw-400">
                You are required to bring an up-to-date copy of all vaccinations
                on the day of your appointment. Failing to do so may result in
                cancellation and being charged for the service.
              </p>
            </div>
            <div className="flex-column gap-24">
              <VaccinationList
                selectedVaccinations={selectedVaccinations}
                setSelectedVaccinations={setSelectedVaccinations}
              />
              <Button
                text="Add vaccine"
                size="medium"
                variant="white-outlined"
                icon="add"
                iconPosition="start"
                onClick={onAddVaccine}
                className="w-fit"
              />
            </div>
            <Button
              text="Next"
              onClick={onNext}
              size="large"
              className="desktop-next-btn w-fit"
              variant={canContinue ? 'default' : 'disabled'}
            />
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            onClick={onNext}
            className="mt-25"
          />
        </div>
      </div>
    </>
  );
}
