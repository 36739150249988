import './user-pets-bottom-modal.scss';

import { useState } from 'react';
import { Input } from 'react-bootstrap-typeahead';
import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';

import Button from '@/atoms/button';
import DefaultAvatar from '@/atoms/default-avatar';
import Icon from '@/atoms/icon';
import { setActivePet } from '@/store/reducers/pet-appointments';
import { Animal } from '@/types/composite';

export default function UserPetsBottomModal({
  toggleModal,
  isOpen,
}: {
  toggleModal: (bool: boolean) => void;
  isOpen: boolean;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { data: user } = useAppSelector((state) => state.user);
  const { activePet } = useAppSelector((state) => state.petAppointments);
  const [itemChecked, setItemChecked] = useState(activePet);

  const toggleCheck = (uuid: string) => {
    setItemChecked(uuid);
  };

  const onSelectPet = () => {
    dispatch(setActivePet(itemChecked));
    toggleModal(false);
  };

  const onCreatePet = () => {
    navigate('/create-pet');
  };

  if (!user.animals) return;

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={() => toggleModal(false)}
    >
      <div className="users-pets-bottom-modal h-100 flex-column justify-between">
        <div className="flex-column">
          <Icon
            name="close"
            fill="black"
            onClick={() => toggleModal(false)}
            className="mb-20 p-10 align-self-end"
          />
          <h4 className="f20 text-left">Pets</h4>
          <ul>
            {user.animals.map((pet: Animal) => (
              <li key={pet.uuid} className="pt-15 pb-15">
                <button
                  onClick={() => toggleCheck(pet.uuid)}
                  className="flex-row no-style-btn w-100 justify-between text-black"
                >
                  <div className="flex-row">
                    <DefaultAvatar
                      avatar={pet.links?.image?.desktop?.large}
                      animal={pet}
                      size="small"
                    />
                    <div className="pl-12 text-left">
                      <p className="bold f17">{pet.name}</p>
                      <p className="f13 fw-100">{pet.type?.value}</p>
                    </div>
                  </div>
                  <Input
                    type="radio"
                    checked={itemChecked?.uuid === pet.uuid}
                    onChange={() => toggleCheck(pet)}
                  />
                </button>
              </li>
            ))}
            <li className="pt-15 pb-15" key="create-pet">
              <button
                onClick={onCreatePet}
                className="flex-row fw-100 text-black no-style-btn f16"
              >
                <Icon
                  name="close"
                  fill="black"
                  className="plus-icon ml-10 mr-20"
                />{' '}
                <p>Add pet</p>
              </button>
            </li>
          </ul>
        </div>
        <Button text="Select" className="w-100 mt-15" onClick={onSelectPet} />
      </div>
    </ReactModal>
  );
}
