import { ServiceType } from '@/types/composite';
import { MoonStars, Park, Sun } from '@phosphor-icons/react';

import Icon from '@/atoms/icon';

export const getIcon = (serviceType: ServiceType) => {
  switch (serviceType) {
    case 'Boarding':
      return <MoonStars size={32} color="#150828" weight="fill" />;
    case 'Daycare':
      return <Sun size={32} color="#150828" weight="fill" />;
    case 'Grooming':
      return <Icon name="grooming" stroke="black" />;
    case 'Dog Park':
      return <Park size={32} color="#150828" weight="fill" />;
    default:
      return null;
  }
};
