import dayjs from 'dayjs';

import {
  hardInventoryFailure,
  hardInventoryRequest,
  hardInventorySuccess,
} from '@/store/reducers/hard-inventory';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';

import { RootState } from '@/store';
import { Dispatch } from '@reduxjs/toolkit';
import { addDays, getProviderType } from '@/util';
import { fetchData } from './fetchData';

type AppointmentFinderData = {
  location?: string;
  lat?: number;
  lng?: number;
  dateStart?: string;
  dateEnd?: string;
  radius?: number;
  query?: string;
  includeAllProviders?: boolean;
  providerType?: string;
  displayAllTime?: boolean;
};
export const appointmentFinder =
  (
    data: AppointmentFinderData,
    actions?: [() => void, () => void, () => void],
  ) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { advancedSearch } = getState();
    const { location } = data;
    const latitude = data.lat || advancedSearch.latitude;
    const longitude = data.lng || advancedSearch.longitude;

    const today = data.dateStart || dayjs().format('YYYY-MM-DD');
    const radius = `radius=${data.radius || 25}`;
    const dateStart = `&date_start=${today}`;
    const dateEnd = `&date_end=${
      data.dateEnd || dayjs(addDays(today, 84)).format('YYYY-MM-DD')
    }`;
    const query = data.query ? `&query=${data.query}` : '';
    const includeAllProviders = data.includeAllProviders
      ? '&show_hi_only=False'
      : '';
    const lat = latitude ? `&latitude=${latitude}` : '';
    const lng = longitude ? `&longitude=${longitude}` : '';
    const providerType = getProviderType(data.providerType || '')
      ? `&provider_type=${getProviderType(data.providerType || '')}`
      : '';
    const time = data.displayAllTime
      ? ''
      : advancedSearch.time
        ? `&time_of_day=${advancedSearch.time}`
        : '';

    const queries = `${radius}${dateStart}${dateEnd}${includeAllProviders}${providerType}${
      location ? `&location_uuid=${location}` : query
    }${lat}${lng}${time}`;

    return fetchData({
      endpoint: `appointment/finder?${queries}`,
      actions: actions || [
        hardInventoryRequest,
        hardInventorySuccess,
        hardInventoryFailure,
      ],
      httpMethod: 'get',
    })?.(dispatch, getState);
  };

type NewAppointmentFinderData = {
  dateStart: string;
  dateEnd: string;
  groomer: string;
  luid: string;
  suid: string;
  animal?: string;
};
export const newAppointmentFinder =
  (
    {
      dateStart,
      dateEnd,
      groomer,
      luid,
      suid,
      animal,
    }: NewAppointmentFinderData,
    actions: any,
  ) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    const dateQuery = `?date_start=${dateStart}&date_end=${dateEnd}`;
    const serviceQuery = suid ? `&service_uuid=${suid}` : '';
    const userQuery = groomer ? `&user_uuid=${groomer}` : '';
    const animalQuery = animal ? `&animal_uuid=${animal}` : '';

    const queries = `${dateQuery}${serviceQuery}${userQuery}${animalQuery}`;

    return fetchData({
      endpoint: `location/${luid}/availability/consumer${queries}`,
      actions,
      httpMethod: 'get',
    })?.(dispatch, getState);
  };

type LocationFinderData = {
  postCode: string;
  breed: { id: string; value: string };
  size: { id: string; value: string };
  type: { id: string; value: string };
};

export const locationFinder =
  ({ postCode, breed, size, type }: LocationFinderData) =>
  (dispatch: Dispatch<any>, getState: () => RootState) => {
    const state = getState();
    const { activePet } = state.petAppointments;

    // Use activePet values as fallbacks.
    const fallbackBreed = activePet?.breed;
    const fallbackSize = activePet?.size;
    const fallbackType = activePet?.type;
    const breedId = breed?.id
      ? `&breed_id=${breed?.id}`
      : fallbackBreed
        ? `&breed_id=${fallbackBreed.id}`
        : '';
    const sizeId = size?.id
      ? `&size_id=${size?.id}`
      : fallbackSize
        ? `&size_id=${fallbackSize.id}`
        : '';
    const typeId = type?.id
      ? `&animal_type_id=${type.id}`
      : fallbackType
        ? `&animal_type_id=${fallbackType.id}`
        : '';

    // Construct the query string.
    const queries = `${typeId}&postal_code=${postCode}${sizeId}${breedId}`;

    return fetchData({
      endpoint: `provider/location/finder?${queries}`,
      actions: [null, null, null],
      httpMethod: 'get',
    })?.(dispatch, getState);
  };

type LocalitySearchData = {
  service?: string;
  market?: string;
  locality: string;
};

export const localitySearch =
  ({
    service = 'groomers',
    market = 'chicago',
    locality = 'all',
  }: LocalitySearchData) =>
  (dispatch: Dispatch<any>, getState: () => RootState) =>
    fetchData({
      endpoint: `search/market/${service}/${market}/${locality}`,
      actions: [
        hardInventoryRequest,
        hardInventorySuccess,
        hardInventoryFailure,
      ],
      httpMethod: 'get',
    })?.(dispatch, getState);

export const requestAppointment =
  () => (dispatch: Dispatch<any>, getState: () => RootState) => {
    const { location, dateStart, service, locationUser, addOns } =
      getState().requestAppointmentForm;
    const { promotionCode } = getState().invoice;
    const state = getState();
    // UUID fix for new users
    let animalUuid;

    const { activePet } = state.petAppointments;
    const userAnimals = state.user.data.animals;
    const advancedSearchPetUuid = state.advancedSearch.petUuid;

    if (activePet && activePet.uuid) {
      animalUuid = activePet.uuid;
    } else if (userAnimals?.length) {
      animalUuid = userAnimals[0].uuid || advancedSearchPetUuid;
    }

    return fetchData({
      endpoint: 'appointment/consumer',
      actions: [null, null, null],
      httpMethod: 'post',
      body: {
        uuid: {
          animal: animalUuid,
          location,
          service: service?.uuid,
          locationUser: locationUser.user.uuid,
        },
        addOns,
        dateStart: `${dateStart}.000Z`,
        note: '',
        promotionCode,
      },
    })?.(dispatch, getState);
  };

export const getAppointment =
  (appointmentId: string) =>
  (dispatch: Dispatch<any>, getState: () => RootState) =>
    fetchData({
      endpoint: `appointment/${appointmentId}/consumer`,
      actions: [null, null, null],
      httpMethod: 'get',
    })?.(dispatch, getState);

export const cancelAppointment =
  (appointmentId: string) =>
  (dispatch: Dispatch<any>, getState: () => RootState) =>
    fetchData({
      endpoint: `appointment/${appointmentId}/consumer`,
      actions: [null, null, null],
      httpMethod: 'delete',
    })?.(dispatch, getState);

export const patchAppointment =
  (appointmentId: string, body: object) =>
  (dispatch: Dispatch<any>, getState: () => RootState) =>
    fetchData({
      endpoint: `appointment/${appointmentId}/consumer`,
      actions: [null, null, null],
      httpMethod: 'patch',
      body,
    })?.(dispatch, getState);

export const makeGeneralRequest =
  (body: object) => (dispatch: Dispatch<any>, getState: () => RootState) =>
    fetchData({
      endpoint: 'appointment/consumer/general/request',
      actions: [null, null, null],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);

export const patchGeneralRequest =
  (body: object, appointmentId: string) =>
  (dispatch: Dispatch<any>, getState: () => RootState) =>
    fetchData({
      endpoint: `appointment/consumer/general/request/${appointmentId}`,
      actions: [requestOngoing, requestFulfilled, requestFulfilled],
      bypassImpersonation: true,
      httpMethod: 'patch',
      body,
    })?.(dispatch, getState);
