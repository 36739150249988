import { useAppSelector, useAppDispatch } from '@/hooks';
import { FormEvent } from 'react';
import Input from '@/atoms/input';
import Dropdown from '@/atoms/dropdown';
import { DateInput } from '@/atoms/date-input';
import { Typeahead } from 'react-bootstrap-typeahead';
import { startsWithFilter } from '@/pages/create-pet/util';
import Button from '@/atoms/button';
import { editForm } from '@/store/reducers/create-pet-form';
import { setStepData } from '@/store/reducers/booking-flow';
import useBookingFlow from '../hooks/use-booking-flow';

export default function PetInformationForm({
  isMobile,
}: {
  isMobile?: boolean;
}) {
  const dispatch = useAppDispatch();
  const { processNextStep } = useBookingFlow();
  const { core } = useAppSelector((state) => state.main);
  const { data: animal } = useAppSelector((state) => state.createPetForm);
  const {
    breed,
    dateOfBirth,
    gender,
    hairLength,
    name,
    size,
    isSterilized,
    type,
  } = animal;
  const breedOptions = core.breed.filter((el) => el.animalTypeId === type?.id);
  const hairLengthOptions = core.hair.length.filter(
    (el) => el.animalTypeId === type?.id,
  );

  const canContinue = name && type && size && breed && gender && dateOfBirth;

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    dispatch(setStepData({ key: 'availablePets', value: [animal] }));
    await processNextStep();
  };

  return (
    <div className="flex-column gap-32">
      <h1 className="mb-0 f-14">Pet information</h1>
      <form onSubmit={onSubmit} className="flex-column gap-24">
        <Input
          id="name"
          label="Pet name"
          className="w-100 mt-0"
          onChange={(value) => dispatch(editForm({ key: 'name', value }))}
          value={name}
          variant="white"
          autoFocus
        />
        <div className="flex-row align-end gap-16 w-100">
          <Dropdown
            label="Pet type"
            placeholder="Pet type"
            className="w-100"
            items={core.type}
            onClick={(value) => {
              dispatch(editForm({ key: 'type', value }));
              dispatch(editForm({ key: 'dateOfBirth', value: null }));
              dispatch(editForm({ key: 'size', value: null }));
              dispatch(editForm({ key: 'breed', value: null }));
            }}
            value={type?.value ? { value: type.value } : null}
            variant="white"
          />
          <DateInput
            label="Date of birth"
            className="date-of-birth-input w-100"
            placeholder="02/02/2020"
            maxDate={new Date() as any}
            onChange={(value) =>
              dispatch(editForm({ key: 'dateOfBirth', value }))
            }
            value={dateOfBirth}
            variant="white"
          />
        </div>
        <div className="w-100">
          <h3 className="mb-12">Breed</h3>
          <Typeahead
            id="breed"
            labelKey="value"
            options={breedOptions}
            placeholder="Select"
            filterBy={startsWithFilter}
            onChange={(value) =>
              dispatch(editForm({ key: 'breed', value: value[0] }))
            }
            selected={breed ? [breed] : []}
            className="w-100 input-field white"
          />
        </div>
        <div className="flex-row align-end gap-16">
          <Dropdown
            label="Size"
            placeholder="Select"
            className="w-100"
            items={core.size}
            onClick={(value) => dispatch(editForm({ key: 'size', value }))}
            value={size?.value ? { value: size.value } : null}
            variant="white"
          />
          <Dropdown
            label="Hair length"
            placeholder={
              hairLengthOptions[0]?.value ? 'Select' : 'Not applicable'
            }
            className="w-100"
            items={hairLengthOptions}
            disableDropdown={!hairLengthOptions.length}
            onClick={(value) =>
              dispatch(editForm({ key: 'hairLength', value }))
            }
            value={hairLength?.value ? { value: hairLength.value } : null}
            variant="white"
          />
        </div>
        <div className="flex-row align-end gap-16">
          <Dropdown
            label="Gender"
            placeholder="Select"
            className="w-100"
            items={core.gender}
            onClick={(value) => dispatch(editForm({ key: 'gender', value }))}
            value={gender?.value ? { value: gender.value } : null}
            variant="white"
          />
          <Dropdown
            label="Spay/Neuter"
            placeholder="Select"
            className="w-100"
            items={[
              { id: true, value: 'Yes' },
              { id: false, value: 'No' },
            ]}
            onClick={({ id }) =>
              dispatch(editForm({ key: 'isSterilized', value: id }))
            }
            value={isSterilized ? { value: 'Yes' } : { value: 'No' }}
            variant="white"
          />
        </div>
        {!isMobile && (
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            type="submit"
            className="desktop-next-btn w-fit mt-12"
          />
        )}
        <div className="mobile-next-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            type="submit"
            className="mt-25"
          />
        </div>
      </form>
    </div>
  );
}
