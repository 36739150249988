import { ServiceType, PublicSettings } from '@/types/composite';

export const getDisclaimerMessage = ({
  serviceType,
  publicSettings,
}: {
  serviceType: ServiceType | null;
  publicSettings: PublicSettings;
}) => {
  if (serviceType === 'Grooming') {
    return publicSettings.policyPriceMessage;
  }
  if (serviceType === 'Daycare') {
    return publicSettings.daycare?.policyPriceMessage;
  }
  if (serviceType === 'Boarding') {
    return publicSettings.boarding?.policyPriceMessage;
  } else return null;
};
