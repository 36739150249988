import { AppDispatch, RootState } from '@/store';
import {
  providerLocationRequest,
  providerLocationSuccess,
  providerLocationFailure,
} from '@/store/reducers/provider';

import { Animal } from '@/types/composite';
import { fetchData } from './fetchData';

export const getProviderLocation =
  ({ slug }: { slug: string }) =>
  (dispatch: AppDispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `provider/location/${slug}`,
      actions: [
        providerLocationRequest,
        providerLocationSuccess,
        providerLocationFailure,
      ],
    })?.(dispatch, getState);

export const getServiceGroups =
  ({
    luid,
    addOns,
    auid,
    animal,
    includeServicesPriceRange,
    lowestPricedServiceOnly,
    typeId,
  }: {
    luid: string;
    addOns?: boolean;
    auid?: string;
    animal?: Omit<
      Animal,
      | 'medications'
      | 'behaviors'
      | 'uuid'
      | 'links'
      | 'deceased'
      | 'sterilization'
      | 'visits'
    >;
    includeServicesPriceRange?: boolean;
    lowestPricedServiceOnly?: boolean;
    typeId?: number;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const params = new URLSearchParams();
    if (addOns !== undefined) params.append('add_ons', addOns.toString());
    if (includeServicesPriceRange !== undefined)
      params.append(
        'include_services_price_range',
        includeServicesPriceRange.toString(),
      );
    if (lowestPricedServiceOnly !== undefined)
      params.append(
        'lowest_priced_service_only',
        lowestPricedServiceOnly.toString(),
      );
    if (auid) params.append('animal_uuid', auid);
    if (animal?.type?.id)
      params.append('animal_type_id', animal.type.id.toString());
    if (animal?.breed?.id)
      params.append('breed_id', animal.breed.id.toString());
    if (animal?.hairLength?.id)
      params.append('hair_length_id', animal.hairLength.id.toString());
    if (animal?.size?.id) params.append('size_id', animal.size.id.toString());
    if (typeId) params.append('type_id', typeId.toString());

    const query = params.toString() ? `?${params.toString()}` : '';

    return fetchData({
      endpoint: `location/${luid}/service_group${query}`,
      actions: [null, null, null],
    })?.(dispatch, getState);
  };

export const getAvailabilityOccupancy =
  ({
    luid,
    suid,
    dateStart,
    dateEnd,
    auid,
    animal,
  }: {
    luid: string;
    suid: string;
    dateStart: string;
    dateEnd: string;
    auid?: string;
    animal?: Animal;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const params = new URLSearchParams();
    if (suid) params.append('service_uuid', suid);
    if (dateStart) params.append('date_start', dateStart);
    if (dateEnd) params.append('date_end', dateEnd);
    if (auid) params.append('animal_uuid', auid);
    if (animal?.type?.id)
      params.append('animal_type_id', animal.type.id.toString());
    if (animal?.size?.id)
      params.append('animal_size_id', animal.size.id.toString());

    const query = params.toString() ? `?${params.toString()}` : '';

    return fetchData({
      endpoint: `location/${luid}/availability/occupancy/consumer${query}`,
      actions: [null, null, null],
    })?.(dispatch, getState);
  };

export const getAvailableGroomers =
  ({
    luid,
    auid,
    suid,
    dateStart,
    timeStart,
    sortSoonestAvailable,
  }: {
    luid: string;
    auid?: string;
    suid?: string;
    dateStart?: string;
    timeStart?: string;
    sortSoonestAvailable?: boolean;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const params = new URLSearchParams();
    if (auid) params.append('animal_uuid', auid);
    if (suid) params.append('service_uuid', suid);
    if (dateStart) params.append('date_start', dateStart);
    if (timeStart) params.append('time_start', timeStart);
    if (sortSoonestAvailable !== undefined)
      params.append('sort_soonest_available', sortSoonestAvailable.toString());

    const query = params.toString() ? `?${params.toString()}` : '';

    return fetchData({
      endpoint: `location/${luid}/user/availability/consumer${query}`,
      actions: [null, null, null],
    })?.(dispatch, getState);
  };

export const getAvailableSlots =
  ({
    luid,
    auid,
    suid,
    sizeId,
    uuid,
    dateStart,
    dateEnd,
  }: {
    luid: string;
    auid?: string;
    suid?: string;
    uuid?: string;
    sizeId?: number;
    dateStart?: string;
    dateEnd?: string;
  }) =>
  (dispatch: AppDispatch, getState: () => RootState) => {
    const params = new URLSearchParams();
    if (auid) params.append('animal_uuid', auid);
    if (suid) params.append('service_uuid', suid);
    if (uuid) params.append('user_uuid', uuid);
    if (dateStart) params.append('date_start', dateStart);
    if (dateEnd) params.append('date_end', dateEnd);
    if (!auid && sizeId) params.append('size_id', sizeId.toString());

    const query = params.toString() ? `?${params.toString()}` : '';

    return fetchData({
      endpoint: `location/${luid}/availability/consumer${query}`,
      actions: [null, null, null],
    })?.(dispatch, getState);
  };

export const bookAppointment =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const {
      location,
      animal,
      service,
      locationUser,
      addOns,
      dateStart,
      dateEnd,
      medications,
      note,
    } = state.bookingForm;

    const body = {
      uuid: {
        animal: animal?.uuid || '',
        location: location?.uuid || '',
        ...(locationUser?.user?.uuid
          ? { location_user: locationUser.user.uuid }
          : {}),
        service: service?.uuid || '',
      },
      date_start: dateStart || null,
      ...(dateEnd ? { date_end: dateEnd } : {}),
      note: note || '',
      add_ons: addOns?.map((addOn) => addOn.uuid) || [],
      medication_ids: medications?.map((medication) => medication.id) || [],
      promotion_code: '',
    };

    return fetchData({
      endpoint: 'appointment/consumer',
      actions: [null, null, null],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);
  };

export const invoiceCheck =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const {
      location,
      animal,
      service,
      addOns,
      dateStart,
      dateEnd,
      serviceType,
    } = state.bookingForm;
    const suid = service?.uuid;

    if (!suid) return null;
    if (
      ['Boarding', 'Daycare'].includes(serviceType || '') &&
      !dateStart &&
      !dateEnd
    )
      return null;

    const body = {
      location: location?.uuid || '',
      service: suid,
      add_ons: addOns?.map((addOn) => addOn.uuid) || [],
      ...(animal?.uuid ? { animal: animal.uuid } : {}),
      ...(dateStart ? { date_start: dateStart } : {}),
      ...(dateEnd ? { date_end: dateEnd } : {}),
    };

    return fetchData({
      endpoint: 'appointment/invoice/check',
      actions: [null, null, null],
      httpMethod: 'post',
      body,
      displayErrorToast: false,
    })?.(dispatch, getState);
  };
