import { setAdminData } from '@/store/reducers/admin-user';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';
import {
  userFailure,
  userRequest,
  userSetCredentials,
  userSuccess,
} from '@/store/reducers/user';

import { RootState } from '@/store';
import { Dispatch } from '@reduxjs/toolkit';
import {
  lastUserAppointmentFailure,
  lastUserAppointmentRequest,
  lastUserAppointmentSuccess,
} from '@/store/reducers/last-user-appointment';
import { fetchData } from './fetchData';

export const loadUser =
  (userId?: string, actions?: [() => void, () => void, () => void]) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { uuid, token } = getState().user;
    return fetchData({
      endpoint: `user/${userId || uuid}`,
      actions: actions || [userRequest, userSuccess, userFailure],
    })?.(dispatch, getState).then(({ success, data }) => {
      if (success) {
        const { isAdmin, name } = data.data;
        const { uuid: adminUuid } = getState().adminUser;
        // Set admin data if user is admin & there's no other admin data stored (this
        // would mean you're impersonating an admin)
        if (isAdmin && !adminUuid) {
          dispatch(setAdminData({ uuid, token, name }));
        }
      }
      return { success, data };
    });
  };

export const editUser =
  (body: any, userId?: any, actions?: any) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { uuid } = getState().user;

    return fetchData({
      endpoint: `user/${userId || uuid}`,
      actions: actions || [userRequest, userSuccess, userFailure],
      httpMethod: 'patch',
      body,
    })?.(dispatch, getState);
  };

export const impersonateUser =
  (uuid: any) => (dispatch: Dispatch, getState: () => RootState) =>
    fetchData({
      endpoint: `user/${uuid}/impersonate`,
      actions: [null, null, null],
    })?.(dispatch, getState).then(({ success, data }) => {
      if (success) {
        dispatch(userSetCredentials(data.data));
        dispatch(loadUser(data.data.uuid));
      }
      return { success, data };
    });

export const loadUserAppointments =
  ({ luid }: { luid: any }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const { uuid } = getState().user;
    const luidQs = luid ? `?location_uuid=${luid}` : '';
    return fetchData({
      endpoint: `user/${uuid}/appointments${luidQs}`,
      actions: [null, null, null],
    })?.(dispatch, getState);
  };

export const loadLastUserAppointment =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const { uuid } = getState().user;
    return fetchData({
      endpoint: `user/${uuid}/appointments/last`,
      actions: [
        lastUserAppointmentRequest,
        lastUserAppointmentSuccess,
        lastUserAppointmentFailure,
      ],
      displayErrorToast: false,
    })?.(dispatch, getState);
  };

export const signWaiver =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const { uuid } = getState().user;
    dispatch(requestOngoing());
    fetchData({
      endpoint: 'user/waiver',
      actions: [null, null, null],
      httpMethod: 'post',
    })?.(dispatch, getState).then(async ({ success }) => {
      if (success) await dispatch(loadUser(uuid));
      dispatch(requestFulfilled());
      return { success };
    });
  };

export const deleteWaiver =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const { uuid } = getState().user;
    dispatch(requestOngoing());
    fetchData({
      endpoint: 'user/waiver',
      actions: [null, null, null],
      httpMethod: 'delete',
    })?.(dispatch, getState).then(async ({ success }) => {
      if (success) await dispatch(loadUser(uuid));
      dispatch(requestFulfilled());
      return { success };
    });
  };

export const unsubscribe =
  ({ email, code }: { email: string; code: string }) =>
  (dispatch: Dispatch, getState: () => RootState) => {
    const body = { email, code };
    return fetchData({
      endpoint: 'user/email/unsubscribe',
      actions: [null, null, null],
      httpMethod: 'post',
      body,
    })?.(dispatch, getState);
  };
