import { useAppSelector } from '@/hooks';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import Button from '@/atoms/button';
import useQuery from '@/hooks/useQuery';

import { ListItem } from './list-item';
import NoAppointmentsCTA from './no-appointments-cta';

export default function Appointments({
  noAppointments,
}: {
  noAppointments?: boolean;
}) {
  const navigate = useNavigate();
  const query = useQuery();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { petAppointments } = useAppSelector((state) => state.petAppointments);
  const { active = [], past = [], request, animal } = petAppointments;
  const pastAppointments = past;
  const pendingGeneralRequests = request?.general?.filter(
    (appt: any) =>
      appt.location && appt.service && appt.status.value === 'requested',
  );

  const bookedAppointments = active.filter((appt: any) => appt.status.id === 1);
  const requestedAppointments = active.filter(
    (appt: any) => appt.status.id === 2,
  );

  // User is coming from the Confirm New Appointment Time email
  const action = query.get('action');
  const apuid = query.get('apuid');
  const date = query.get('date');
  const time = query.get('time');
  const isFromReviewRescheduleAction =
    !!action && action === 'review_reschedule' && !!apuid && !!date && !!time;
  const selectedSuggestedTime = `${date}T${time}`;

  if (noAppointments) return <NoAppointmentsCTA />;

  return (
    <>
      {!isMobile ? (
        <div className="book-appointment-button-container flex-row justify-end">
          <Button
            variant="reverse-orange"
            size="small"
            className="book-appointment-button-profile"
            text="Book appointment"
            onClick={() => {
              navigate(localStorage.getItem('homepageUrl') || '/');
            }}
          />
        </div>
      ) : (
        <div className="mt-40" />
      )}
      <div>
        {(requestedAppointments.length > 0 ||
          pendingGeneralRequests.length > 0) && (
          <div className="mb-75 mt-n40">
            <h2 className="mb-15">Pending</h2>
            {pendingGeneralRequests.map((item: any) => (
              <ListItem key={item.uuid} item={item} animal={animal} isGeneral />
            ))}
            {requestedAppointments.map((item: any) => (
              <ListItem
                key={item.uuid}
                item={item}
                animal={animal}
                canCancel
                isNew
              />
            ))}
          </div>
        )}
        {bookedAppointments.length > 0 && (
          <div className="mb-75 mt-n40">
            <h2 className="mb-15">Scheduled</h2>
            {bookedAppointments.map((item: any) => (
              <ListItem
                key={item.uuid}
                item={item}
                animal={animal}
                canCancel
                selectedSuggestedTime={
                  item.uuid === apuid &&
                  isFromReviewRescheduleAction &&
                  item.suggested !== null
                    ? selectedSuggestedTime
                    : null
                }
              />
            ))}
          </div>
        )}
        {pastAppointments.length > 0 && (
          <div className="mb-75 mt-n40">
            <h2 className="mb-15">Past</h2>
            {pastAppointments.map((item: any) => (
              <ListItem
                key={item.uuid}
                item={item}
                animal={animal}
                isPastRequest
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
}
