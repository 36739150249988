import './home-loader.scss';

import { generateKey } from '@/util';

function HomeLoader() {
  return (
    <div className="home-loader">
      {[1, 2, 3, 4, 5, 6].map((el) => (
        <div className="home-loader-card" key={`${generateKey()}-${el}`}>
          <div className="home-loader-card-header flex-row justify-center">
            <span className="home-loader-card-header-avatar" />
          </div>
          <div className="flex-column">
            <span className="home-loader-card-title" />
            <span className="home-loader-card-address" />
          </div>
        </div>
      ))}
    </div>
  );
}

export default HomeLoader;
