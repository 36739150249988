import './location-search.scss';

import { useEffect, useState } from 'react';
import { useAppDispatch } from '@/hooks';
import { Autocomplete } from '@react-google-maps/api';
import classNames from 'classnames';

import { generateKey } from '@/util';
import { useGoogleMaps } from '@/config/google-maps-context';

interface LocationSearchProps {
  address?: string;
  className?: string;
  clearErrors?: () => void;
  defaultValue?: string;
  displayErrorCaption?: boolean;
  editLocation: any;
  hasError?: boolean;
  hideLabel?: boolean;
  label?: string;
  placeholder?: string;
  size?: 'small';
}

export default function LocationSearch({
  address,
  className,
  clearErrors,
  defaultValue,
  displayErrorCaption,
  editLocation,
  hasError,
  hideLabel,
  label = 'Address',
  placeholder = 'Address or zipcode',
  size,
}: LocationSearchProps) {
  const { isLoaded } = useGoogleMaps();
  const dispatch = useAppDispatch();
  const [searchResult, setSearchResult] = useState<any>(address);
  const autocompleteInput = document.getElementById('autocomplete');
  const dropdown = document.querySelector('.pac-container');

  useEffect(() => {
    if (autocompleteInput) {
      autocompleteInput.addEventListener('keydown', disableFormSubmittion);
    }

    return () => {
      if (autocompleteInput) {
        autocompleteInput.removeEventListener('keydown', disableFormSubmittion);
      }
    };
  }, [autocompleteInput]);

  // Add dynamic class to dropdown component
  useEffect(() => {
    if (dropdown && size === 'small') {
      dropdown.classList.add('pac-container--small');
    }
  }, [dropdown]);

  const disableFormSubmittion = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  function onLoad(autocomplete: any) {
    setSearchResult(autocomplete);
  }

  async function onPlaceChanged() {
    if (searchResult != null) {
      const place = await searchResult.getPlace();
      popullateAddress(place);
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      dispatch(editLocation({ key: 'latitude', value: latitude }));
      dispatch(editLocation({ key: 'longitude', value: longitude }));
    }
  }

  const popullateAddress = (location: any) => {
    const { address_components: components } = location;

    const fullAddress = [] as string[];
    components.forEach((el: any) => {
      if (el.types.includes('route')) {
        fullAddress.push(el.long_name);
      }
      if (el.types.includes('street_number')) {
        fullAddress.push(el.long_name);
      }
      if (el.types.includes('postal_code')) {
        dispatch(editLocation({ key: 'postalCode', value: el.long_name }));
      }
      if (el.types.includes('locality')) {
        dispatch(editLocation({ key: 'locality', value: el.long_name }));
      }
      if (el.types.includes('administrative_area_level_1')) {
        dispatch(editLocation({ key: 'region', value: el.short_name }));
      }

      const hasStreetNumber =
        components.filter((el: any) => el.types.includes('street_number'))
          .length > 0;
      setTimeout(() => {
        dispatch(
          editLocation({
            key: 'address_1',
            value: hasStreetNumber ? fullAddress.join(' ') : 'invalid_address',
          }),
        );
      }, 500);
    });
  };

  if (!isLoaded) return;

  return (
    <div className="location-search w-100">
      {!hideLabel && (
        <div className="mb-15">
          <p className="label">{label}</p>
          {displayErrorCaption && (
            <p className={classNames(hasError && 'text-red', 'f13 mb-5')}>
              Please select a valid address from the dropdown
            </p>
          )}
        </div>
      )}
      <div id="searchColumn">
        <Autocomplete
          className={`pac-container--${size}`}
          restrictions={{ country: 'us' }}
          onPlaceChanged={onPlaceChanged}
          onLoad={onLoad}
        >
          <input
            name={generateKey().toString()} // Hack to hide the autofill popup
            onFocus={(e: any) => e.target.setAttribute('autoComplete', 'none')}
            onChange={clearErrors}
            id="location-search"
            className={classNames(
              'input-field w-100',
              className,
              `location-search--${size}`,
            )}
            placeholder={placeholder}
            role="combobox"
            aria-expanded="true"
            aria-controls="pac-container"
            type="text"
            autoComplete="off"
            defaultValue={defaultValue}
          />
        </Autocomplete>
      </div>
    </div>
  );
}
