import Icon from '@/atoms/icon';
import CheckboxWithActions from '@/organisms/checkbox-with-actions';
import { Medication } from '@/types/composite';

export const MedicationList = ({
  medications,
  selectedMedications,
  onMedicationChange,
  onDeleteMedication,
  onEditMedication,
}: {
  medications: Medication[];
  selectedMedications: Medication[];
  onMedicationChange: (medication: Medication) => void;
  onDeleteMedication: (medication: Medication) => void;
  onEditMedication: (medication: Medication) => void;
}) =>
  medications?.map((medication: Medication) => (
    <CheckboxWithActions
      key={medication.id}
      id={`medication-${medication.id}`}
      value={selectedMedications.some(
        (selectedMedication) => selectedMedication.id === medication.id,
      )}
      onClick={() => onMedicationChange(medication)}
      actions={
        <>
          <Icon
            name="bin"
            onClick={() => onDeleteMedication(medication)}
            className="pointer"
          />
          <Icon
            name="pencil"
            onClick={() => onEditMedication(medication)}
            className="pointer"
          />
        </>
      }
    >
      <div className="flex-column align-start text-black">
        <p>{medication.value}</p>
        <p className="text-left">
          {medication.dosage}, {medication.frequency?.value}
        </p>
      </div>
    </CheckboxWithActions>
  ));
