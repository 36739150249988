import DefaultAvatar from '@/atoms/default-avatar';
import { useAppSelector } from '@/hooks';

export default function ProviderTitle() {
  const { location: providerLocation } = useAppSelector(
    (state) => state.bookingForm,
  );
  const { name, links } = providerLocation || {};

  return (
    <div className="provider-title flex-column gap-16">
      <DefaultAvatar avatar={links?.image?.desktop?.large || ''} size="big" />
      <h2 className="f24 bold">{name}</h2>
    </div>
  );
}
