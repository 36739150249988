import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { editForm } from '@/store/reducers/booking-form';
import Button from '@/atoms/button';
import { isNotEmptyArray } from '@/util';
import { Service } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import SelectableItem from '../components/selectable-item';
import useBookingFlow from '../hooks/use-booking-flow';
import '../booking-flow.scss';

export default function BookingAddOns() {
  const dispatch = useAppDispatch();
  const { availableAddOns } = useAppSelector((state) => state.bookingFlow);
  const { addOns: selectedAddOns } = useAppSelector(
    (state) => state.bookingForm,
  );
  const { processNextStep } = useBookingFlow();

  const onSelectAddOn = (addOn: Service) => {
    dispatch(
      editForm({
        key: 'addOns',
        value: selectedAddOns.some((selected) => selected.uuid === addOn.uuid)
          ? selectedAddOns.filter((selected) => selected.uuid !== addOn.uuid)
          : [...selectedAddOns, addOn],
      }),
    );
  };

  const onNext = async () => {
    await processNextStep();
  };

  return (
    <>
      <Helmet title="Select add-ons for booking | PawCare" />
      <div className="add-ons flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32">
            <h2 className="f26 bold">Select add-ons</h2>
            {isNotEmptyArray(availableAddOns) ? (
              <>
                <div className="flex-column gap-12">
                  {availableAddOns.map((addOn) => {
                    const { name, description, price, uuid } = addOn;

                    return (
                      <SelectableItem
                        key={uuid}
                        onClick={() => onSelectAddOn(addOn)}
                        isActive={selectedAddOns.some(
                          (selectedAddOn) => selectedAddOn.uuid === uuid,
                        )}
                        size="large"
                      >
                        <div className="flex-row align-start justify-between gap-32 w-100">
                          <div className="flex-column align-start">
                            <h3 className="f16 bold text-black text-left">
                              {name}
                            </h3>
                            <p className="f16 fw-400 text-left text-black">
                              {description}
                            </p>
                          </div>
                          <div className="flex-column align-end">
                            <h3 className="f16 bold text-black text-left">
                              {price?.localized || '-'}
                            </h3>
                          </div>
                        </div>
                      </SelectableItem>
                    );
                  })}
                </div>
                <Button
                  text="Next"
                  onClick={onNext}
                  size="large"
                  className="desktop-next-btn w-fit"
                  variant="default"
                />
              </>
            ) : (
              <p className="f16 fw-400">No add-ons available</p>
            )}
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button text="Next" onClick={onNext} className="mt-25" />
        </div>
      </div>
    </>
  );
}
