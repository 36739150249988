import { createSlice } from '@reduxjs/toolkit';
import { Medication } from '@/types/composite';

type LastUserAppointmentState = {
  isLoading: boolean;
  medications: Medication[];
  animal: {
    uuid: string;
  };
  location: {
    name: string;
    locality: string;
    slug: string;
    links: {
      image: {
        desktop: {
          small: string;
        };
      };
    };
  } | null;
  locationUser: { uuid: string } | null;
};

const initialState: LastUserAppointmentState = {
  isLoading: false,
  location: null,
  animal: {
    uuid: '',
  },
  medications: [],
  locationUser: null,
};

const lastUserAppointment = createSlice({
  name: 'lastUserAppointment',
  initialState,
  reducers: {
    lastUserAppointmentRequest(state) {
      state.isLoading = true;
    },
    lastUserAppointmentSuccess(_, { payload }) {
      return {
        ...payload.data,
        isLoading: false,
      };
    },
    lastUserAppointmentFailure(state) {
      state.isLoading = false;
    },
  },
});

const { actions, reducer } = lastUserAppointment;

export const {
  lastUserAppointmentRequest,
  lastUserAppointmentSuccess,
  lastUserAppointmentFailure,
} = actions;

export default reducer;
