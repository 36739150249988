import './default-avatar.scss';
import classNames from 'classnames';
import Icon from '@/atoms/icon';
import { generateKey } from '@/util';
import { Animal } from '@/types/composite';

/* It accepts an avatar URL and an animal type. If avatar is null, it'll display an icon correspondent
   to the animal type provided. If animal type is also null, it'll display the paw icon
*/

interface DefaultAvatarProps {
  size: 'extra-small' | 'small' | 'medium' | 'big' | 'extra-large';
  animal?: Animal;
  className?: string;
  avatar?: string;
  isUser?: boolean;
}

export default function DefaultAvatar({
  size = 'big',
  animal,
  className,
  avatar,
  isUser = false,
}: DefaultAvatarProps) {
  const getIcon = () => {
    if (isUser) return 'user';
    const typeId = animal?.type?.id;
    if (typeId === 1) return 'cat';
    if (typeId === 2) return 'dog';
    if (typeId === 3) return 'exotic';
    if (typeId === 4) return 'largeAnimal';
    if (typeId === 5) return 'pocketPet';
    return 'pawFilled';
  };

  const getIconSize = () => {
    if (size === 'extra-small') return 10;
    if (size === 'small') return 20;
    if (size === 'medium') return 35;
    if (size === 'big') return 50;
    if (size === 'extra-large') return 80;
  };

  return (
    <div
      className={classNames(
        'default-avatar',
        `default-avatar--${size}`,
        className,
        avatar && 'default-avatar--transparent',
      )}
      style={{ backgroundImage: avatar ? `url(${avatar})` : '' }}
    >
      {!avatar && (
        <Icon
          key={generateKey()}
          name={getIcon()}
          width={getIconSize()}
          height={getIconSize()}
          fill="#fff"
        />
      )}
    </div>
  );
}
