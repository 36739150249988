import Dropdown from '@/atoms/dropdown';
import dayjs from 'dayjs';

export default function FixedTimeSelect({
  selectedDate,
  timeSlots,
  durationMinutes,
  selectedCheckin,
  selectedCheckout,
  onTimeChange,
}: {
  selectedDate: string;
  timeSlots: string[];
  selectedCheckin: string;
  selectedCheckout: string;
  durationMinutes: number;
  onTimeChange: (time: string) => void;
}) {
  const selectedDateFormatted =
    selectedDate && dayjs(selectedDate).format('MMM D');
  const selectedCheckoutTimeFormatted =
    selectedCheckout &&
    dayjs(`2000-01-01T${selectedCheckout}`).format('h:mm A');

  const timeOptions = timeSlots.map((time) => {
    const startTime = dayjs(`2000-01-01T${time}`);
    const endTime = startTime.add(durationMinutes, 'minutes');
    return {
      id: time,
      value: `${startTime.format('h:mm A')} — ${endTime.format('h:mm A')}`,
    };
  });

  return (
    <div className="flex-column gap-16">
      <div className="flex-column">
        <h3 className="f18 bold">
          Checkin{selectedDateFormatted ? `: ${selectedDateFormatted}` : ''}
        </h3>
        {selectedCheckoutTimeFormatted ? (
          <p className="f14 fw-400 text-primary-purple">
            Check out time for this appointment is{' '}
            {selectedCheckoutTimeFormatted}
          </p>
        ) : null}
      </div>
      <Dropdown
        placeholder="Select checkin time"
        variant="white"
        items={timeOptions}
        value={timeOptions.find((option) => option.id === selectedCheckin)}
        onClick={(value) => onTimeChange(value.id)}
      />
    </div>
  );
}
