import dayjs from 'dayjs';

import { Article } from '@/atoms/article';
import toast from '@/atoms/toast';

export function getHoursAndMinutes(totalMinutes: number) {
  if (!totalMinutes || Number.isNaN(totalMinutes)) return totalMinutes;

  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
}

export function formatHoursAndMinutes(totalMinutes: number) {
  if (!totalMinutes || Number.isNaN(totalMinutes)) return '';
  const { hours, minutes } = getHoursAndMinutes(totalMinutes) as {
    hours: number;
    minutes: number;
  };
  const result = `${hours > 0 ? `${hours} hr` : ''} ${
    minutes > 0 ? `${minutes} min` : ''
  }`;
  return result;
}

export const prettifyUrl = (url: string) => {
  if (!url || typeof url !== 'string') return '';

  try {
    const parsedUrl = new URL(url);
    return parsedUrl.hostname.replace('www.', '');
  } catch (error: any) {
    console.error('Invalid URL:', error.message);
    return null;
  }
};

export const prettifyHour = (str: string, getTime?: boolean) => {
  if (!str || typeof str !== 'string') return '';

  const value = getTime ? getTimeFromDate(str) : str;

  // Check correct time format and split into components
  const match = value.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/);

  if (!match) return str;

  const [, hours, , minutes] = match;
  const numHours = parseInt(hours, 10);
  const ampm = numHours < 12 ? 'AM' : 'PM';
  const adjustedHours = numHours % 12 || 12;

  return `${adjustedHours}:${minutes} ${ampm}`;
};

export const getTimeFromDate = (str: string) => {
  if (!str) return '';
  const [, hour] = str.split('T');
  return hour;
};

export const getDayFromDate = (str: string) => {
  if (!str) return '';
  const [date] = str.split('T');
  return date;
};

export const prettifyDate = (str: string) => {
  if (!str) return str;
  return `${dayjs(str).format('MMM DD, YYYY')} • ${prettifyHour(
    getTimeFromDate(str),
  )}`;
};

export const prettifyNumber = (str: string) => str.replace(/\D/g, '');

const errorsCodeWithMessage = [
  101, 102, 113, 122, 123, 128, 126, 127, 129, 130, 131, 137, 204, 1062,
];
export const displayErrorMessage = ({
  errorData,
  errorMessage,
}: {
  errorData: any;
  errorMessage: string;
}) => {
  if (errorMessage) {
    return errorMessage;
  }

  if (typeof errorData === 'string') {
    if (errorData === 'mfa does not exist') {
      return 'The code you entered is not valid! Try resending a new code.';
    }
    if (errorData === 'promotion_code does not exist') {
      return "Discount code isn't valid";
    }
  }
  const error = errorData?.[0] || {};

  if (error.code === 1062) {
    if (error.entity === 'animal_entity')
      return 'You already have a pet with that name';
    if (error.entity === 'user_entity')
      return 'Sorry, the provided email is already being used';
    if (error.entity === 'appointment_entity')
      return 'The appointment time you requested is no longer available';
    return 'Sorry, the provided address is already being used. Please provide a different location';
  }
  if (errorsCodeWithMessage.includes(error.code)) {
    return error.message;
  }
  return 'Oops! Something went wrong. Please contact us at support@mypawcare.com.';
};

export const prettifyPhoneNumber = (
  number: string,
  countryCode: number | string,
) => {
  if (+countryCode !== 1) return `+${countryCode} ${number}`;
  const first3Digits = number.split('').splice(0, 3).join('');
  const secont3Digits = number.split('').splice(3, 3).join('');
  const rest = number.split('').splice(6).join('');
  return `+${countryCode} (${first3Digits}) ${secont3Digits}-${rest}`;
};

export const getRequestStatusLabel = (status: { value: string }) => {
  if (!status?.value) return 'Booked';
  if (status.value === 'completed') return 'Paid';
  return typeof status.value === 'string'
    ? capitalizeFirstLetter(status.value)
    : status.value;
};
export const appointmentsSuccessAlert = (status: string) => {
  if (!status) return;
  let text;
  if (status === 'no-show') text = 'Appointment marked as no show';
  if (status === 'arrived') text = 'Arrival confirmed!';
  if (status === 'cancel') text = 'Appointment canceled!';
  if (status === 'decline') text = 'Appointment declined!';
  toast({ text });
};

export const addDays = (date: Date | string, days: number) => {
  if (!date) return '';
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export function capitalizeFirstLetter(str: string) {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getCardIcon = (str: string) => {
  const cardsWithIcons = [
    'amex',
    'discover',
    'elo',
    'hiper',
    'jbc',
    'maestro',
    'mastercard',
    'paypal',
    'unionpay',
    'visa',
  ];
  if (cardsWithIcons.includes(str)) return str;
  return 'card';
};

export const generateKey = () => Math.random() * 100000;

export const calculatePrice = (service: any) => {
  if (!service) return '';
  return !service.price || service.price.amount === 0
    ? 'TBD'
    : service.price.localized;
};

export const postsList = (posts: any) => {
  if (!posts) return [];
  return posts.map((post: any) => <Article key={post.url} article={post} />);
};

export const getQueryParams = (paramsString: string) => {
  if (!paramsString) return {};

  const searchParams = new URLSearchParams(paramsString);

  const paramsObject = {} as any;
  searchParams.forEach((value, key) => {
    paramsObject[key] = value;
  });
  return paramsObject;
};

// Converts from {end: '17:00:00', start: '09:00:00'} to an incremental array
// of { value: '5:00 am', valueFormatted: '05:00:00' }
export function getOpenHours(timeObj: any) {
  if (!timeObj) return [];
  const getMinutes = (time: string) =>
    parseInt(time.split(':')[0]) * 60 + parseInt(time.split(':')[1]);
  const formatTime = (minutes: number) => {
    const hh = Math.floor(minutes / 60);
    const mm = minutes % 60;
    const hh12 = hh % 12 === 0 ? 12 : hh % 12;
    const period = hh >= 12 ? 'pm' : 'am';
    return {
      value: `${hh12}:${mm.toString().padStart(2, '0')} ${period}`,
      valueFormatted: `${hh.toString().padStart(2, '0')}:${mm
        .toString()
        .padStart(2, '0')}:00`,
    };
  };

  const start = getMinutes(timeObj.start);
  const end = getMinutes(timeObj.end);
  const result = [];

  for (let minutes = start; minutes <= end; minutes += 15) {
    result.push(formatTime(minutes));
  }
  return result;
}

export const findMiddleLatLng = (array: any) => {
  if (!array || !array.length) return {};
  let middleIndex;
  if (array.length % 2 === 0) {
    middleIndex = array.length / 2 - 1;
  } else {
    middleIndex = Math.floor(array.length / 2);
  }
  return array[middleIndex];
};

export function splitByCapitalLetters(str: string) {
  if (!str || typeof str !== 'string') return str;
  return str.split(/(?=[A-Z])/).join(' ');
}

export const serviceDetails = (service: any) => {
  if (!service) return '';

  const animalType = service.animal?.type?.value;
  const animalBreed = service.breed?.value;
  const animalSize = service.size?.value;
  const animalHair = service.hair?.length?.value;

  const typeString = animalType || '';
  const breedString = animalBreed ? `, ${animalBreed}` : '';
  const sizeString = animalSize ? `, ${animalSize}` : '';
  const hairString = animalHair ? `, ${animalHair}` : '';

  return `${typeString}${breedString}${sizeString}${hairString}`;
};

// Hash strings, used for tracking data
export async function hashString(input: string) {
  if (!input || typeof input !== 'string') return null;

  const encoder = new TextEncoder();
  const data = encoder.encode(input);
  const hash = await crypto.subtle.digest('SHA-256', data);

  // Convert buffer to byte array
  const hashArray = Array.from(new Uint8Array(hash));

  // Convert bytes to hex string
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
}

/* Checks if object is empty */
export const isEmpty = (obj: any) =>
  obj &&
  Object.keys(obj).length === 0 &&
  Object.getPrototypeOf(obj) === Object.prototype;

/* Checks if object is empty */
export const isNotEmptyArray = (arr: any) =>
  arr && Array.isArray(arr) && arr.length > 0;

export const getProviderType = (type: string) => {
  if (type === 'mobile-grooming') return 4;
  return null;
};

export function isWithin24Hours(dateString: string) {
  if (!dateString || typeof dateString !== 'string') return true;

  const givenDate = new Date(dateString) as any;
  const currentDate = new Date() as any;
  const timeDifference = Math.abs(currentDate - givenDate);

  // Convert the time difference to hours
  const hoursDifference = timeDifference / (1000 * 60 * 60);
  return hoursDifference <= 24;
}

export const isPastDateTime = (date: Date) => dayjs().isAfter(date);

export const getRandomGroomer = (groomers: any) => {
  const availableGroomers = groomers.filter(
    (groomer: any) => groomer.canHandleRequestedSlot,
  );
  const randomIndex = Math.floor(Math.random() * availableGroomers.length);
  return availableGroomers[randomIndex];
};

export const isEmailAddress = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
