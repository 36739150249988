import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { editForm, resetForm } from '@/store/reducers/booking-form';
import Button from '@/atoms/button';
import { isNotEmptyArray } from '@/util';
import { Service, ServiceType } from '@/types/composite';
import SidePanel from '../components/side-panel';
import AppointmentDetails from '../components/appointment-details';
import MainPanel from '../components/main-panel';
import SelectableItem from '../components/selectable-item';
import useBookingFlow from '../hooks/use-booking-flow';
import { getServiceDuration } from '../utils';
import '../booking-flow.scss';

export default function BookingServiceSelect() {
  const dispatch = useAppDispatch();
  const { availableServices } = useAppSelector((state) => state.bookingFlow);
  const { service: selectedService } = useAppSelector(
    (state) => state.bookingForm,
  );
  const { processNextStep } = useBookingFlow();
  const canContinue = !!selectedService;

  const onSelectService = (service: Service) => {
    if (selectedService?.uuid !== service.uuid) {
      dispatch(
        resetForm({ keysToPreserve: ['location', 'animal', 'serviceType'] }),
      );
    }
    dispatch(editForm({ key: 'service', value: service }));
  };

  const onNext = async () => {
    if (!canContinue) return;
    await processNextStep();
  };

  return (
    <>
      <Helmet title="Select service for booking | PawCare" />
      <div className="service-select flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32">
            <h2 className="f26 bold">Select service</h2>
            {isNotEmptyArray(availableServices) ? (
              <>
                <div className="flex-column gap-12">
                  {availableServices.map((service) => {
                    const {
                      name,
                      description,
                      animal,
                      breed,
                      hair,
                      size,
                      price,
                      type,
                      durationMinutes,
                      group,
                    } = service;
                    const serviceType = type?.value || null;
                    const duration = getServiceDuration({
                      groomerDuration: durationMinutes,
                      otherDuration: group?.durationMinutes || null,
                      serviceType: serviceType as ServiceType,
                    });

                    const animalDetails = [
                      animal?.type?.value ? `${animal?.type?.value}s` : null,
                      breed?.value,
                      hair?.length?.value
                        ? `${hair?.length?.value} hair`
                        : null,
                      size?.value,
                    ]
                      .filter(Boolean)
                      .join(', ');

                    return (
                      <SelectableItem
                        key={service.uuid}
                        onClick={() => onSelectService(service)}
                        isActive={service.uuid === selectedService?.uuid}
                        size="large"
                      >
                        <div className="flex-row w-100 justify-between gap-16">
                          <div className="flex-column align-start w-100">
                            <div className="flex-row justify-between align-start w-100">
                              <h3 className="f16 bold text-black text-left">
                                {name}
                              </h3>
                              <h3 className="f16 bold text-black text-left">
                                {`${price?.localized}${serviceType === 'Grooming' ? '+' : ''}` ||
                                  '-'}
                              </h3>
                            </div>
                            {description && (
                              <div className="flex-row justify-between align-start w-100">
                                <p className="f16 fw-400 text-left text-black">
                                  {description}
                                </p>
                                <p className="f16 fw-400 text-primary-purple flex-shrink-0">
                                  {duration}
                                </p>
                              </div>
                            )}

                            {serviceType === 'Grooming' && (
                              <div className="flex-row justify-between text-black align-start w-100">
                                {animalDetails ? (
                                  <p className="f13 fw-400">{animalDetails}</p>
                                ) : null}
                                {!description && duration && (
                                  <p className="fw-400 text-primary-purple flex-shrink-0">
                                    {duration}
                                  </p>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </SelectableItem>
                    );
                  })}
                </div>
                <Button
                  text="Next"
                  onClick={onNext}
                  size="large"
                  className="desktop-next-btn w-fit"
                  variant={canContinue ? 'default' : 'disabled'}
                />
              </>
            ) : (
              <p className="f16 fw-400">No services available</p>
            )}
          </div>
        </MainPanel>
        <div className="mobile-next-btn">
          <Button
            variant={canContinue ? 'default' : 'disabled'}
            text="Next"
            onClick={onNext}
            className="mt-25"
          />
        </div>
      </div>
    </>
  );
}
