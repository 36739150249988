import { Report } from '@/types/composite';
import { createSlice } from '@reduxjs/toolkit';

interface InitialState {
  data: Report | null;
  isLoading: boolean;
}

const initialState: InitialState = {
  data: null,
  isLoading: false,
};

const report = createSlice({
  name: 'report',
  initialState,
  reducers: {
    getReportRequest(state) {
      state.isLoading = true;
    },
    getReportSuccess(state, { payload }) {
      state.isLoading = false;
      state.data = payload.data;
    },
    getReportFailure(state) {
      state.isLoading = false;
    },
  },
});

export const { getReportRequest, getReportSuccess, getReportFailure } =
  report.actions;

export default report.reducer;
