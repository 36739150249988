import { useEffect, useCallback } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { clearForm, restoreForm } from '@/store/reducers/booking-form';
import { clearFlow, restoreFlow } from '@/store/reducers/booking-flow';
import store from '@/store';
import { useNavigate, useLocation } from 'react-router-dom';
import { BookingFlowStep } from '@/types/others';
import {
  BOOKING_FLOW_GENERAL_STEPS,
  BOOKING_FLOW_SERVICE_STEPS,
  STEPS,
} from '../utils/flow-steps';
import { FlowManager } from '../utils/flow-manager';

const BOOKING_FLOW_STATE_STORAGE_KEY = 'state:bookingFlow';
const BOOKING_FORM_STATE_STORAGE_KEY = 'state:bookingForm';

export default function useBookingFlow() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const bookingFlowState = useAppSelector((state) => state.bookingFlow);
  const { currentStep, isLoading, error } = bookingFlowState;
  const bookingFormState = useAppSelector((state) => state.bookingForm);
  const { serviceType } = bookingFormState;

  const processNextStep = useCallback(
    async (explicitStartStep?: BookingFlowStep) => {
      try {
        const currentStep =
          explicitStartStep ?? FlowManager.getStepFromRoute(location.pathname);

        const nextStep = await FlowManager.processNextStep(
          dispatch,
          () => store.getState(),
          currentStep,
        );
        navigate(STEPS[nextStep].route);
      } catch (error) {
        console.error('Error processing next step:', error);
      }
    },
    [dispatch, navigate],
  );

  const calculateProgress = () => {
    if (!currentStep) return { percentage: 0, currentStep: 0, totalSteps: 0 };

    // If the user hasn't selected a service type yet, we just grab 'Boarding',
    // we don't care if they select a different type later on, amount of steps is similar
    const flowSteps = [
      ...BOOKING_FLOW_GENERAL_STEPS,
      ...BOOKING_FLOW_SERVICE_STEPS[serviceType || 'Boarding'],
    ];

    const currentIndex = currentStep ? flowSteps.indexOf(currentStep) : -1;
    const totalSteps = flowSteps.length;
    const percentage = Math.round(((currentIndex + 1) / totalSteps) * 100);

    return {
      percentage,
      currentStep: currentIndex + 1,
      totalSteps,
    };
  };

  useEffect(() => {
    const savedBookingFormState = sessionStorage.getItem(
      BOOKING_FORM_STATE_STORAGE_KEY,
    );
    if (savedBookingFormState) {
      try {
        const parsedBookingFormState = JSON.parse(savedBookingFormState);
        dispatch(restoreForm(parsedBookingFormState));
      } catch (e) {
        console.error('Error restoring booking form state:', e);
      }
    }

    const savedBookingFlowState = sessionStorage.getItem(
      BOOKING_FLOW_STATE_STORAGE_KEY,
    );
    if (savedBookingFlowState) {
      try {
        const parsedBookingFlowState = JSON.parse(savedBookingFlowState);
        dispatch(restoreFlow(parsedBookingFlowState));
      } catch (e) {
        console.error('Error restoring booking flow state:', e);
      }
    }
  }, []);

  useEffect(() => {
    if (currentStep) {
      sessionStorage.setItem(
        BOOKING_FORM_STATE_STORAGE_KEY,
        JSON.stringify(bookingFormState),
      );
      sessionStorage.setItem(
        BOOKING_FLOW_STATE_STORAGE_KEY,
        JSON.stringify(bookingFlowState),
      );
    }
  }, [bookingFormState, bookingFlowState]);

  const clearBookingFlow = useCallback(() => {
    sessionStorage.removeItem(BOOKING_FORM_STATE_STORAGE_KEY);
    dispatch(clearForm());
    sessionStorage.removeItem(BOOKING_FLOW_STATE_STORAGE_KEY);
    dispatch(clearFlow());
  }, [dispatch]);

  return {
    currentStep,
    isLoading,
    error,
    processNextStep,
    progress: calculateProgress(),
    clearBookingFlow,
  };
}
