import { RootState } from '@/store';
import { Dispatch } from '@reduxjs/toolkit';
import {
  getReportFailure,
  getReportRequest,
  getReportSuccess,
} from '@/store/reducers/report';
import { fetchData } from './fetchData';

export const getStayReport =
  (uuid: string) => (dispatch: Dispatch, getState: () => RootState) => {
    if (!uuid) {
      return;
    }
    return fetchData({
      endpoint: `appointment/consumer/stay-report/${uuid}`,
      actions: [getReportRequest, getReportSuccess, getReportFailure],
    })?.(dispatch, getState);
  };
