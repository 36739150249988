import { useAppDispatch, useAppSelector } from '@/hooks';
import ReactModal from 'react-modal';
import Button from '@/atoms/button';
import { useState } from 'react';
import Dropdown from '@/atoms/dropdown';
import Input from '@/atoms/input';
import { editForm } from '@/store/reducers/booking-form';
import { Medication } from '@/types/composite';

export default function AddMedicationModal({
  isOpen,
  onClose,
  editedMedication,
}: {
  isOpen: boolean;
  onClose: () => void;
  editedMedication: Medication | null;
}) {
  const dispatch = useAppDispatch();
  const { medications } = useAppSelector((state) => state.bookingForm);

  const { availableMedications } = useAppSelector((state) => state.bookingFlow);
  const { drug, frequency: frequencyOptions } = availableMedications || {};
  const drugOptions =
    drug?.filter(
      (drug: Medication) =>
        !medications?.some((medication) => medication.id === drug.id),
    ) || [];
  const [medication, setMedication] = useState<Medication>(
    editedMedication || {
      id: 0,
      value: '',
      dosage: '',
      frequency: null,
    },
  );
  const canContinue =
    !!medication.id &&
    !!medication.value &&
    !!medication.dosage &&
    !!medication.frequency?.id;

  const onSave = async () => {
    if (!canContinue) return;

    const updatedMedications = editedMedication
      ? [
          ...(medications?.filter(
            (medication) => medication.id !== editedMedication.id,
          ) || []),
          { ...medication, isChecked: true },
        ]
      : [...(medications || []), { ...medication, isChecked: true }];

    dispatch(
      editForm({
        key: 'medications',
        value: updatedMedications,
      }),
    );

    onClose();
  };

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      className="max-w-480"
    >
      <div className="flex-column gap-16">
        <h2 className="f24 bold">Add Medication</h2>
        <Dropdown
          placeholder="Select medication"
          items={drugOptions}
          variant="white"
          value={
            drug.find((drug: Medication) => drug.id === medication.id) || null
          }
          onClick={(value) =>
            setMedication({ ...medication, id: value.id, value: value.value })
          }
        />
        <Input
          placeholder="Dosage (i.e. 1 pill, 2 oz of liquid)"
          variant="white"
          value={medication.dosage || ''}
          onChange={(value) => setMedication({ ...medication, dosage: value })}
        />
        <Dropdown
          placeholder="Select frequency"
          items={frequencyOptions}
          variant="white"
          value={medication.frequency}
          onClick={(value) =>
            setMedication({ ...medication, frequency: value })
          }
        />
        <div className="flex-row gap-16">
          <Button
            size="medium"
            onClick={onSave}
            text="Save"
            variant={canContinue ? 'default' : 'disabled'}
          />
          <Button
            size="medium"
            onClick={onClose}
            text="Cancel"
            variant="white-outlined"
          />
        </div>
      </div>
    </ReactModal>
  );
}
