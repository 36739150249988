import './user-details.scss';
import { AvatarWithInfo, AvatarWithInfoProps } from '../avatar-with-info';

interface UserDetailsProps extends Omit<AvatarWithInfoProps, 'avatar'> {
  item: {
    links: {
      image: {
        desktop: {
          large: string;
        };
      };
    };
    name: string;
    address1: string;
  };
}

export function UserDetails({
  item,
  className,
  size = 'medium',
}: UserDetailsProps) {
  if (!item) return;
  return (
    <AvatarWithInfo
      avatar={item.links?.image?.desktop?.large}
      size={size}
      className={className}
    >
      <div className="flex-column">
        <h4 className="text-black">{item.name}</h4>
        <p className="user-details-address">{item.address1}</p>
      </div>
    </AvatarWithInfo>
  );
}
