import { useAppSelector } from '@/hooks';
import { formatRange, getServiceDuration } from '@/pages/booking-flow/utils';
import { ServiceType } from '@/types/composite';

export default function ServiceGroupsList() {
  const { availableServiceGroups } = useAppSelector(
    (state) => state.bookingFlow,
  );

  return (
    <div className="grid">
      {availableServiceGroups?.map((serviceGroup) => {
        const {
          isAddOn,
          name,
          description,
          animal,
          servicesPriceRange,
          type,
          durationMinutes,
          services,
        } = serviceGroup;

        if (isAddOn) return null;

        const priceRange = formatRange(servicesPriceRange);
        const serviceType = (type?.value || null) as ServiceType | null;
        const duration = getServiceDuration({
          groomerDuration: services?.[0]?.durationMinutes ?? null,
          otherDuration: durationMinutes,
          serviceType,
        });

        return (
          <div
            className="flex-row gap-16 justify-between border-bottom-grey pb-32 pt-32"
            key={serviceGroup.uuid}
          >
            <div className="flex-column min-w-0">
              <h3 className="f16 bold">{name}</h3>
              <p className="f16 fw-400">{description}</p>
              {type?.value === 'Grooming' && (
                <p className="f13 fw-400">{animal?.value}s</p>
              )}
            </div>
            <div className="flex-column align-end">
              <h3 className="f16 bold text-right">{priceRange}</h3>
              <p className="f16 fw-400 text-primary-purple flex-shrink-0">
                {duration}
              </p>
            </div>
          </div>
        );
      })}
    </div>
  );
}
