import classNames from 'classnames';
import DefaultAvatar from '@/atoms/default-avatar';
import { ReactNode } from 'react';

export interface AvatarWithInfoProps {
  avatar: any;
  className?: string;
  size?: 'medium' | 'big' | 'small' | 'extra-small' | 'extra-large';
  children: ReactNode;
}

export function AvatarWithInfo({
  avatar,
  className,
  size = 'medium',
  children,
}: AvatarWithInfoProps) {
  return (
    <div className={classNames('flex-row text-left gap-4', className)}>
      {avatar && (
        <DefaultAvatar
          className="user-details-avatar"
          size={size}
          avatar={avatar}
        />
      )}
      {children}
    </div>
  );
}
