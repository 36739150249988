import classNames from 'classnames';
import { ReactNode } from 'react';

export default function SidePanel({
  children,
  className,
}: {
  children: ReactNode;
  className?: string;
}) {
  return (
    <div
      className={classNames(
        'sidepanel flex-column align-end max-w-620 min-w-380 w-100 h-100 overflow-scroll pt-96 pr-96 pb-20 pl-20',
        className,
      )}
    >
      <div className="flex-column gap-24 max-w-322 w-100">{children}</div>
    </div>
  );
}
