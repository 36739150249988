import { createSlice } from '@reduxjs/toolkit';

interface MfaLoginFormState {
  data: {
    countryCodeId: { code: string; country: string; id: number };
    phoneNumber: string;
  };
  isLoading: boolean;
}

const initialState: MfaLoginFormState = {
  data: {
    countryCodeId: { code: '1', country: 'United States', id: 232 },
    phoneNumber: '',
  },
  isLoading: false,
};

const mfaLoginForm = createSlice({
  name: 'mfaLoginForm',
  initialState,
  reducers: {
    mfaLoginRequest(state) {
      state.isLoading = true;
    },
    mfaLoginSuccess(state) {
      state.isLoading = false;
    },
    mfaLoginFailure(state) {
      state.isLoading = false;
    },
    editForm(state, { payload }) {
      const { key, value } = payload as {
        key: keyof MfaLoginFormState['data'];
        value: string;
      };
      (state as any).data[key] = value;
    },
    clearForm(state) {
      state.data = initialState.data;
    },
  },
});

const { actions, reducer } = mfaLoginForm;

export const {
  editForm,
  clearForm,
  mfaLoginRequest,
  mfaLoginSuccess,
  mfaLoginFailure,
} = actions;

export default reducer;
