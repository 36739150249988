import { useAppDispatch, useAppSelector } from '@/hooks';
import { toggleFullScreenImage } from '@/store/reducers/ui';
import { XCircle } from '@phosphor-icons/react';
import { createPortal } from 'react-dom';
import './full-screen-image.scss';

export default function FullScreenImage() {
  const dispatch = useAppDispatch();
  const { isOpen, data } = useAppSelector((state) => state.ui.fullScreenImage);

  if (!isOpen) return null;

  return (
    <div>
      {createPortal(
        <div className="full-screen-image flex-row items-center justify-center">
          <XCircle
            size={40}
            weight="fill"
            className="close-icon pointer"
            color="white"
            onClick={() =>
              dispatch(toggleFullScreenImage({ isOpen: false, data: null }))
            }
          />
          <img src={data.image} alt="decorative" />
        </div>,
        document.body,
      )}
    </div>
  );
}
