/* eslint-disable camelcase */
import { useAppDispatch, useAppSelector } from '@/hooks';
import { loadBootstrap } from 'actions';
import { adminSignUp } from '@/actions/authentication';
import { createPet } from '@/actions/pet';
import { loadUser } from '@/actions/user';
import Button from '@/atoms/button';
import Dropdown from '@/atoms/dropdown';
import Input from '@/atoms/input';
import LocationSearch from '@/atoms/location-search';
import { editAdvancedSearch } from '@/store/reducers/advanced-search';
import { editForm as editSignupForm } from '@/store/reducers/signup-form';
import { editForm as editBookingForm } from '@/store/reducers/booking-form';
import { mobileGroomerId } from 'util/constants';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';

interface UserDataBody {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  countryCodeId: number;
  address?: {
    address: string | null;
    locality: string | null;
    region: string | null;
    postalCode: string | null;
    latitude: number | null;
    longitude: number | null;
    country_id: number;
  };
}

export default function Form({ onNext }: { onNext: () => void }) {
  const dispatch = useAppDispatch();
  const { data } = useAppSelector((state) => state.signupForm);
  const petForm = useAppSelector((state) => state.createPetForm.data);
  const { latitude, longitude, address_1, locality, region, postalCode } =
    useAppSelector((state) => state.advancedSearch);
  const countryIdOptions = useAppSelector(
    (state) => state.main.geo.countryCode,
  );
  const { countryCodeId, email, firstName, lastName, phoneNumber } = data;
  const { service } = useAppSelector((state) => state.requestAppointmentForm);
  const invalidAddress = address_1 === 'invalid_address';
  const isMobileService = service?.type?.id === mobileGroomerId;
  const canContinue =
    countryCodeId && email && firstName && lastName && phoneNumber;

  const onSignupValidate = async (e: any) => {
    e.preventDefault();

    if (!canContinue) return;

    if (isMobileService) {
      if (
        !locality ||
        !region ||
        !postalCode ||
        !latitude ||
        !longitude ||
        !address_1 ||
        address_1 === 'invalid_address'
      ) {
        return;
      }
    }

    dispatch(requestOngoing());

    let userDataBody: UserDataBody = {
      firstName,
      lastName,
      email,
      phoneNumber,
      countryCodeId: countryCodeId.id,
    };

    // If they selected a mobile service, send user's address details
    if (isMobileService) {
      userDataBody = {
        ...userDataBody,
        address: {
          address: address_1,
          locality,
          region,
          postalCode,
          latitude,
          longitude,
          country_id: 238,
        },
      };
    }

    const userCreated = await dispatch(adminSignUp(userDataBody));

    if (userCreated?.success) {
      const { token: userToken, uuid: userUuid } = userCreated.data.data;
      sessionStorage.setItem('overrideLoginActions', 'true');
      localStorage.setItem('token', userToken);
      await dispatch(loadBootstrap());

      const userData = await dispatch(loadUser(userUuid));

      if (!userData?.success) {
        dispatch(requestFulfilled());
        return;
      }

      if (userData?.success) {
        const petCreated = await dispatch(
          createPet(
            {
              breedId: petForm.breed?.id,
              sizeId: petForm.size?.id,
              typeId: petForm.type?.id,
              name: petForm.name,
            },
            [null, null, null],
          ),
        );

        if (!petCreated?.success) {
          dispatch(requestFulfilled());
          return;
        }

        if (petCreated?.success) {
          dispatch(
            editBookingForm({ key: 'animal', value: petCreated.data.data }),
          );
          await dispatch(loadUser(userUuid));
          onNext();
          dispatch(requestFulfilled());
        }
      }
    }
    dispatch(requestFulfilled());
  };

  return (
    <form onSubmit={onSignupValidate} className="h-100">
      <div className="mobile-page-container">
        <div className="flex-row">
          <Input
            id="firstName"
            label="First name"
            variant="white"
            className="mr-12 w-100"
            onChange={(value) =>
              dispatch(editSignupForm({ key: 'firstName', value }))
            }
            value={firstName}
            autoFocus
          />
          <Input
            id="lastName"
            variant="white"
            label="Last name"
            className="w-100"
            onChange={(value) =>
              dispatch(editSignupForm({ key: 'lastName', value }))
            }
            value={lastName}
          />
        </div>
        <Input
          className="mt-35"
          id="email"
          variant="white"
          type="email"
          label="Email"
          onChange={(value) =>
            dispatch(editSignupForm({ key: 'email', value }))
          }
          value={email}
        />
        {isMobileService && (
          <LocationSearch
            label="Address for mobile service"
            editLocation={editAdvancedSearch}
            displayErrorCaption
            hasError={invalidAddress}
            defaultValue={
              invalidAddress
                ? `${locality}, ${region}`
                : (address_1 ?? undefined)
            }
          />
        )}
        <div className="phone-container flex-row mt-32">
          <Dropdown
            id="countryCode"
            label="Phone"
            variant="white"
            className="country-code-input"
            items={countryIdOptions}
            onClick={(value) =>
              dispatch(editSignupForm({ key: 'countryCodeId', value }))
            }
            value={countryCodeId}
          />
          <Input
            id="phone"
            variant="white"
            className="mt-42 w-100"
            onChange={(value) =>
              dispatch(editSignupForm({ key: 'phoneNumber', value }))
            }
            value={phoneNumber}
            isNumber
          />
        </div>
      </div>
      <Button
        text="Next"
        type="submit"
        className="mt-25"
        variant={canContinue ? 'default' : 'disabled'}
      />
    </form>
  );
}
