import {
  Behavior,
  Invoice,
  MedicationOptions,
  Vaccination,
  LocationUser,
  Service,
  TimeSlot,
  ServiceType,
  ServiceGroup,
  Animal,
  Policy,
} from '@/types/composite';
import { BookingFlowStep } from '@/types/others';
import { createSlice } from '@reduxjs/toolkit';

interface BookingFlowState {
  currentStep: BookingFlowStep | null;
  isLoading: boolean;
  error: string | null;
  availableServiceGroups: ServiceGroup[];
  availablePets: Animal[];
  availableServiceTypes: ServiceType[];
  availableServices: Service[];
  availableKennelUpgrades: Service[];
  availableAddOns: Service[];
  availableGroomers: LocationUser[];
  availableSlots: TimeSlot[];
  availableBehaviors: Behavior[];
  availableMedications: MedicationOptions;
  availableVaccinations: Vaccination[];
  isNewCustomer: boolean;
  serviceNameQuantified: string;
  invoice: {
    invoice: Invoice;
    policy: Policy;
  } | null;
}

const initialState: BookingFlowState = {
  isLoading: false,
  currentStep: null,
  error: null,
  availableServiceGroups: [],
  availablePets: [],
  availableServiceTypes: [],
  availableServices: [],
  availableKennelUpgrades: [],
  availableAddOns: [],
  availableGroomers: [],
  availableSlots: [],
  availableBehaviors: [],
  availableMedications: { drug: [], frequency: [] },
  availableVaccinations: [],
  isNewCustomer: true,
  serviceNameQuantified: '',
  invoice: null,
};

const bookingFlow = createSlice({
  name: 'bookingFlow',
  initialState,
  reducers: {
    setCurrentStep(state, { payload }) {
      state.currentStep = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    setError(state, { payload }) {
      state.error = payload;
    },
    setStepData(state, { payload }) {
      const { key, value } = payload as {
        key: keyof BookingFlowState;
        value: BookingFlowState[keyof BookingFlowState];
      };
      (state[key] as any) = value;
    },
    clearFlow() {
      return { ...initialState };
    },
    restoreFlow(_, { payload }) {
      return payload;
    },
  },
});

const { actions, reducer } = bookingFlow;

export const {
  setCurrentStep,
  setIsLoading,
  setError,
  setStepData,
  clearFlow,
  restoreFlow,
} = actions;

export default reducer;
