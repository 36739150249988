import { useState } from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import { useAppDispatch, useAppSelector } from '@/hooks';

import Button from '@/atoms/button';
import Checkbox from '@/atoms/checkbox';
import { DateInput } from '@/atoms/date-input';
import Dropdown from '@/atoms/dropdown';
import Icon from '@/atoms/icon';
import Input from '@/atoms/input';
import { editForm } from '@/store/reducers/create-pet-form';
import { capitalizeFirstLetter, isNotEmptyArray } from '@/util';

import { startsWithFilter } from './util';

interface MobileProps {
  data: any;
  onSubmit: () => void;
  breedOptions: any;
  hairLengthOptions: any;
}

export default function Mobile({
  data,
  onSubmit,
  breedOptions,
  hairLengthOptions,
}: MobileProps) {
  const dispatch = useAppDispatch();
  const { core } = useAppSelector((state) => state.main);
  // Flow gets divided into steps on mobile
  const [step, setStep] = useState(1);
  const {
    breed,
    dateOfBirth,
    gender,
    hairLength,
    name,
    size,
    isSterilized,
    type,
    vaccinations,
  } = data;
  const vaccinationForAnimalType = core.vaccination.filter(
    (el) => el.animalTypeId === type?.id,
  );

  const vaccinationIdsList = vaccinations
    .map((vaccination: any) =>
      vaccination?.id && vaccination?.value ? vaccination.id : null,
    )
    .filter((vaccination: any) => !!vaccination);

  const onAddVaccine = () => {
    dispatch(
      editForm({
        key: 'vaccinations',
        value: [...vaccinations, { uuid: crypto.randomUUID() }],
      }),
    );
  };

  const pageTitle = {
    1: 'What is your pet’s name?',
    2: `Tell us more about ${capitalizeFirstLetter(name)}`,
    3: `Tell us more about ${capitalizeFirstLetter(name)}`,
    4: `${capitalizeFirstLetter(name)}'s medical history`,
  };

  const canContinue = () => {
    if (step === 1) {
      if (name) return true;
      return false;
    }
    if (step === 2) {
      if (type) return true;
      return false;
    }
    if (step === 3) {
      if (gender) return true;
      return false;
    }
    if (step === 4) {
      return true;
    }
  };

  const updateStep = (e: any, goBack: any) => {
    if (e) {
      e.preventDefault();
    }
    if (step === 4 && !goBack) {
      return onSubmit();
    }
    if (goBack) {
      return setStep(step - 1);
    }
    setStep(step + 1);
  };

  const vaccinationsWithUUID = vaccinations.map(
    (vaccination: {
      uuid?: string;
      id: number;
      value: string;
      expirationDate: string | null;
    }) => ({
      ...vaccination,
      uuid: vaccination.uuid || crypto.randomUUID(),
    }),
  );

  return (
    <div className="create-pet-mobile flex-column justify-between align-start">
      <div className="w-100 h-100">
        {step !== 1 && (
          <button
            onClick={() => updateStep(null, true)}
            className="no-style-btn"
          >
            <Icon
              name="arrowLeft"
              className="pointer"
              fill="black"
              width={25}
              height={25}
            />
          </button>
        )}
        <h1 className="create-pet-mobile-title">{pageTitle[step]}</h1>
        <form
          onSubmit={updateStep}
          className="flex-column justify-between create-pet-mobile-form h-100"
        >
          <div className="create-pet-mobile-form-container">
            {step === 1 && (
              <Input
                id="name"
                className="mt-0 w-100"
                onChange={(value: string) =>
                  dispatch(editForm({ key: 'name', value }))
                }
                value={name}
                placeholder="Pet name"
                autoFocus
              />
            )}
            {(step === 2 || step === 3) && (
              <Dropdown
                className="w-100 mt-0"
                items={core.type}
                placeholder="Pet type"
                onClick={(value) => {
                  dispatch(editForm({ key: 'type', value }));
                  dispatch(editForm({ key: 'dateOfBirth', value: null }));
                  dispatch(editForm({ key: 'size', value: null }));
                  dispatch(editForm({ key: 'breed', value: null }));
                }}
                value={type?.value}
              />
            )}
            {step === 3 && (
              <>
                <Typeahead
                  id="breed"
                  labelKey="value"
                  className="mt-10 w-100 input-field"
                  options={breedOptions}
                  placeholder="Breed"
                  filterBy={startsWithFilter}
                  onChange={(value: string) =>
                    dispatch(editForm({ key: 'breed', value: value[0] }))
                  }
                  selected={breed ? [breed] : []}
                />
                <Dropdown
                  placeholder="Size"
                  className="w-100 mt-10"
                  items={core.size}
                  onClick={(value) =>
                    dispatch(editForm({ key: 'size', value }))
                  }
                  value={size?.value}
                />
                <Dropdown
                  placeholder={
                    hairLengthOptions[0]?.value
                      ? 'Hair length'
                      : 'Not applicable'
                  }
                  className="w-100 mt-10"
                  items={hairLengthOptions}
                  disableDropdown={!hairLengthOptions.length}
                  onClick={(value) =>
                    dispatch(editForm({ key: 'hairLength', value }))
                  }
                  value={hairLength?.value}
                />
                <DateInput
                  className="mt-10"
                  placeholder="Date of birth"
                  maxDate={new Date() as any}
                  onChange={(value: string) =>
                    dispatch(editForm({ key: 'dateOfBirth', value }))
                  }
                  value={dateOfBirth}
                />
                <Dropdown
                  placeholder="Gender"
                  className="w-100 mt-10"
                  items={core.gender}
                  onClick={(value) =>
                    dispatch(editForm({ key: 'gender', value }))
                  }
                  value={gender?.value}
                />
              </>
            )}
            {step === 4 && (
              <div className="flex-column">
                <Checkbox
                  id="neutered"
                  size="small"
                  className="mb-8"
                  checkmarkColor="orange"
                  variant="block"
                  contentPosition="left"
                  value={isSterilized?.isChecked}
                  onClick={({ isChecked }) =>
                    dispatch(
                      editForm({ key: 'isSterilized', value: isChecked }),
                    )
                  }
                  text="Spay / Neutered"
                />

                <div className="vaccination-form-container flex-column mt-32 mb-25">
                  <h3 className="f22 mb-15 mt-10">Vaccinations</h3>

                  {isNotEmptyArray(vaccinations) && (
                    <div className="flex-row gap-12 mt-12">
                      <h4 className="type-title f18 bold">Type</h4>
                      <h4 className="f18 bold">Expiration</h4>
                    </div>
                  )}

                  {vaccinationsWithUUID.map((vaccination: any, index: any) => (
                    <div
                      className="flex-row gap-12 mt-12"
                      key={vaccination.uuid}
                    >
                      <Dropdown
                        placeholder="Vaccination"
                        className="vaccination-select"
                        items={vaccinationForAnimalType.filter(
                          (vaccinationOption) =>
                            vaccinationOption?.id === vaccination?.id ||
                            !vaccinationIdsList.includes(vaccinationOption?.id),
                        )}
                        size="medium"
                        onClick={(value) => {
                          const updatedVaccination = {
                            ...vaccinationsWithUUID[index],
                            ...value,
                          };
                          const vaccinationsCopy =
                            vaccinationsWithUUID.slice(0);
                          vaccinationsCopy[index] = updatedVaccination;
                          return dispatch(
                            editForm({
                              key: 'vaccinations',
                              value: vaccinationsCopy,
                            }),
                          );
                        }}
                        value={
                          vaccination?.id && vaccination?.value
                            ? vaccination
                            : null
                        }
                      />
                      <DateInput
                        className="expiration-date-input"
                        placeholder="Expiration date"
                        onChange={(date) => {
                          const updatedVaccination = {
                            ...vaccinationsWithUUID[index],
                            expirationDate: date,
                          };
                          const vaccinationsCopy =
                            vaccinationsWithUUID.slice(0);
                          vaccinationsCopy[index] = updatedVaccination;
                          return dispatch(
                            editForm({
                              key: 'vaccinations',
                              value: vaccinationsCopy,
                            }),
                          );
                        }}
                        value={
                          vaccination?.expirationDate
                            ? vaccination.expirationDate
                            : null
                        }
                        size="medium"
                      />
                    </div>
                  ))}
                  <div className="flex-row mt-12">
                    <Button
                      className="add-vaccine-button fw-400"
                      text="Add vaccine"
                      size="large"
                      variant="light-gray"
                      icon="add"
                      iconProps={{ style: { fill: '#63637b' } }}
                      iconPosition="start"
                      onClick={onAddVaccine}
                      type="button"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="create-pet-mobile-button-container b-white">
            <Button
              variant={canContinue() ? 'default' : 'disabled'}
              text="Next"
              type="submit"
              className="w-100"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
