import { Helmet } from 'react-helmet-async';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { isNotEmptyArray, isWithin24Hours } from '@/util';
import { DefaultCard } from '@/organisms/default-card';
import AddNewCard from '@/organisms/add-new-card';
import Warning24HoursModal from '@/organisms/warning-24-hours-modal';
import PaymentMethodModal from '@/organisms/payment-method-modal';
import { useState } from 'react';
import { bookAppointment } from '@/actions/booking';
import { requestFulfilled, requestOngoing } from '@/store/reducers/ui';
import { setStepData } from '@/store/reducers/booking-flow';
import useBookingFlow from '../hooks/use-booking-flow';
import MainPanel from '../components/main-panel';
import AppointmentDetails from '../components/appointment-details';
import SidePanel from '../components/side-panel';
import '../booking-flow.scss';

export default function BookingPayment() {
  const dispatch = useAppDispatch();
  const { data, paymentMethod } = useAppSelector((state) => state.user);
  const waivers = data?.waivers;
  const { cards } = paymentMethod;
  const hasSignedWaiver = isNotEmptyArray(waivers) && !!waivers[0].dateCreated;
  const { isNewCustomer } = useAppSelector((state) => state.bookingFlow);
  const { location: providerLocation, dateStart } = useAppSelector(
    (state) => state.bookingForm,
  );
  const { publicSettings } = providerLocation || {};
  const [isPaymentMethodModalOpen, setIsPaymentMethodModalOpen] =
    useState(false);
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const { processNextStep } = useBookingFlow();

  const requireAnyCustomerToAddCard =
    publicSettings?.ccRequiredForBookings === true;
  const canOldCustomerContinueWithoutCard =
    !isNewCustomer && publicSettings?.ccRequiredForNewClientsBookings === true;
  const canContinueWithoutCard =
    !requireAnyCustomerToAddCard || canOldCustomerContinueWithoutCard;

  const canContinue = isNotEmptyArray(cards) || canContinueWithoutCard;

  const onSubmit = () => {
    if (!canContinue) return;
    if (isWithin24Hours(dateStart || '')) {
      setIsWarningModalOpen(true);
    } else {
      onCreateAppointment();
    }
  };

  const onCreateAppointment = async () => {
    if (!canContinue) return;
    dispatch(requestOngoing());
    const bookAppointmentCall = await dispatch(bookAppointment());
    dispatch(requestFulfilled());
    if (bookAppointmentCall?.success) {
      dispatch(
        setStepData({
          key: 'serviceNameQuantified',
          value: bookAppointmentCall.data?.data?.serviceNameQuantified || '',
        }),
      );
      processNextStep();
    }
  };

  return (
    <>
      <Helmet title="Select payment method for booking | PawCare" />
      <div className="payment flex-row w-100">
        <SidePanel>
          <AppointmentDetails />
        </SidePanel>
        <MainPanel>
          <div className="flex-column gap-32 max-w-510">
            <div className="flex-column">
              <h2 className="f26 bold">Complete booking</h2>
              <p className="f16 fw-400">
                Your card will not be charged for the service until after your
                appointment.
              </p>
            </div>
            <div className="payment-info-form">
              {isNotEmptyArray(cards) ? (
                <DefaultCard
                  onChangePaymentMethod={() =>
                    setIsPaymentMethodModalOpen(true)
                  }
                  onSubmit={onSubmit}
                  primaryButtonText="Complete booking"
                  hideDiscount
                  hasSignedWaiver={hasSignedWaiver}
                />
              ) : (
                <AddNewCard
                  onSubmit={onSubmit}
                  primaryButtonText="Complete booking"
                  hideDiscount
                  hasSignedWaiver={hasSignedWaiver}
                  canContinueWithoutCard={canContinueWithoutCard}
                />
              )}
            </div>
            {isPaymentMethodModalOpen && (
              <PaymentMethodModal
                isOpen={isPaymentMethodModalOpen}
                openModal={setIsPaymentMethodModalOpen}
              />
            )}
            {isWarningModalOpen && (
              <Warning24HoursModal
                isOpen={isWarningModalOpen}
                openModal={setIsWarningModalOpen}
                onConfirm={onCreateAppointment}
              />
            )}
          </div>
        </MainPanel>
      </div>
    </>
  );
}
