import {
  Animal,
  Medication,
  Service,
  ServiceType,
  LocationUser,
  LocationType,
} from '@/types/composite';
import { createSlice } from '@reduxjs/toolkit';

interface BookingFormState {
  location: LocationType | null;
  animal: Animal | null;
  serviceType: ServiceType | null;
  service: Service | null;
  locationUser: LocationUser | null;
  addOns: Service[];
  dateStart: string | null;
  dateEnd: string | null;
  medications: (Medication & { isChecked: boolean })[];
  note: string | null;
}

const initialState: BookingFormState = {
  location: null,
  animal: null,
  serviceType: null,
  service: null,
  locationUser: null,
  addOns: [],
  dateStart: null,
  dateEnd: null,
  medications: [],
  note: null,
};

const bookingForm = createSlice({
  name: 'bookingForm',
  initialState,
  reducers: {
    editForm(state, { payload }) {
      const { key, value } = payload as {
        key: keyof BookingFormState;
        value: BookingFormState[keyof BookingFormState];
      };
      (state[key] as any) = value;
    },
    clearForm() {
      return { ...initialState };
    },
    restoreForm(_, { payload }) {
      return payload;
    },
    resetForm(state, { payload }) {
      const keysToPreserve =
        payload.keysToPreserve as (keyof BookingFormState)[];
      const newState = { ...initialState };

      keysToPreserve.forEach((key) => {
        newState[key] = state[key] as any;
      });

      return newState;
    },
  },
});

const { actions, reducer } = bookingForm;

export const { editForm, clearForm, restoreForm, resetForm } = actions;

export default reducer;
