import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import dayjs from 'dayjs';
import { useAppSelector } from '@/hooks';
import { useEffect, useMemo } from 'react';
import useWindowDimensions from '@/helpers/use-window-dimensions';
import { dayjsToDate, isOneDayApart } from '../../util';
import './date-time-calendar.scss';

export default function DateTimeCalendar({
  selectedDate,
  selectedDateRange,
  onDateSelect,
  onDateRangeSelect,
  mode = 'single',
  disableDefaultStartDate = false,
}: {
  selectedDate?: string;
  selectedDateRange?: { from: string; to: string };
  onDateSelect?: (date: string) => void;
  onDateRangeSelect?: (range: { from: string; to: string }) => void;
  mode?: 'single' | 'range';
  disableDefaultStartDate?: boolean;
}) {
  const { availableSlots } = useAppSelector((state) => state.bookingFlow);
  const { dateStart } = useAppSelector((state) => state.bookingForm);
  const { width } = useWindowDimensions();
  const isMobile = width < 640;

  const availableDates = useMemo(
    () => availableSlots.map((slot) => dayjsToDate(slot.date)),
    [availableSlots],
  );

  const initialDate = useMemo(() => {
    if (dateStart) return dayjsToDate(dateStart);
    return dayjsToDate();
  }, [dateStart]);

  const disabledDays = useMemo(
    () => [
      ...(availableDates.length > 0
        ? [{ before: dayjs().startOf('day').toDate() }]
        : []),
      (date: Date) =>
        !availableDates.some(
          (availableDate) =>
            dayjs(availableDate).format('YYYY-MM-DD') ===
            dayjs(date).format('YYYY-MM-DD'),
        ),
    ],
    [availableDates],
  );

  useEffect(() => {
    const initialDateStr = dayjs(initialDate).format('YYYY-MM-DD');

    if (mode === 'single' && !selectedDate && !disableDefaultStartDate) {
      onDateSelect?.(initialDateStr);
    } else if (
      mode === 'range' &&
      !selectedDateRange?.from &&
      !disableDefaultStartDate
    ) {
      onDateRangeSelect?.({ from: initialDateStr, to: '' });
    }
  }, [
    initialDate,
    onDateSelect,
    onDateRangeSelect,
    selectedDate,
    selectedDateRange,
    mode,
  ]);

  const onSelect = (
    date: Date | { from: Date; to: Date } | undefined,
    selectedDate: Date,
  ) => {
    if (!date) return;

    if (mode === 'single' && !('from' in date)) {
      const selectedDateStr = dayjs(date).format('YYYY-MM-DD');
      onDateSelect?.(selectedDateStr);
    } else if (mode === 'range') {
      // undefined || {from: '', to: ''}
      if (
        !selectedDateRange ||
        (selectedDateRange?.from && selectedDateRange?.to)
      ) {
        onDateRangeSelect?.({
          from: dayjs(selectedDate).format('YYYY-MM-DD'),
          to: '',
        });
      }
      // undefined || {from: 10/10/10, to: ''}
      if (selectedDateRange?.from && !selectedDateRange?.to && 'from' in date) {
        onDateRangeSelect?.({
          from: dayjs(date.from).format('YYYY-MM-DD'),
          to: dayjs(date.to).format('YYYY-MM-DD'),
        });
      }
    }
  };

  return (
    <div className="w-100">
      {mode === 'single' ? (
        <DayPicker
          mode="single"
          selected={dayjsToDate(selectedDate)}
          onSelect={onSelect as any}
          disabled={disabledDays}
          numberOfMonths={isMobile ? 1 : 2}
          defaultMonth={dayjsToDate(selectedDate)}
          formatters={{
            formatWeekdayName: (date) => dayjs(date).format('dd')[0],
            formatDay: (date) => date.getDate().toString(),
          }}
        />
      ) : (
        <DayPicker
          mode="range"
          className={
            selectedDateRange?.from &&
            selectedDateRange?.to &&
            isOneDayApart(
              dayjsToDate(selectedDateRange.from),
              dayjsToDate(selectedDateRange.to),
            )
              ? 'style-start-end-knobs'
              : ''
          }
          required
          selected={
            selectedDateRange
              ? {
                  from: dayjsToDate(selectedDateRange.from),
                  to: selectedDateRange.to
                    ? dayjsToDate(selectedDateRange.to)
                    : undefined,
                }
              : undefined
          }
          onSelect={onSelect as any}
          disabled={disabledDays}
          numberOfMonths={isMobile ? 1 : 2}
          defaultMonth={dayjsToDate(selectedDateRange?.from)}
          formatters={{
            formatWeekdayName: (date) => dayjs(date).format('dd')[0],
            formatDay: (date) => date.getDate().toString(),
          }}
        />
      )}
    </div>
  );
}
