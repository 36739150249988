import useBookingFlow from '../hooks/use-booking-flow';

export default function BookingFlowProgressBar() {
  const { progress } = useBookingFlow();
  const { totalSteps, percentage } = progress;

  if (totalSteps === 0) return null;

  return (
    <div className="w-100 h-8 b-white flex-shrink-0">
      <div className="h-100 b-green" style={{ width: `${percentage}%` }} />
    </div>
  );
}
