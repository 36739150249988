import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';

import Checkout from '@/organisms/checkout';
import Landing from '@/organisms/landing';
import LoginMfaInfo from './login-mfa-info';

export default function LoginMfa() {
  const [searchParams] = useSearchParams();
  const showAppointmentPanel =
    searchParams.get('appointment') || searchParams.get('appointment-signup');

  const LayoutComponent = showAppointmentPanel ? Checkout : Landing;

  return (
    <>
      <Helmet title="Phone verification | PawCare">
        <link rel="canonical" href="https://mypawcare.com/login/mfa" />
      </Helmet>
      <LayoutComponent removeOutterPadding>
        <LoginMfaInfo />
      </LayoutComponent>
    </>
  );
}
