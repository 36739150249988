import { isNotEmptyArray } from '@/util';
import { Tooltip } from '@mui/material';
import Icon from '@/atoms/icon';
import { useAppSelector } from '@/hooks';
import { Divider } from '@/atoms/divider';
import classNames from 'classnames';
import { Invoice, Policy, ServiceType } from '@/types/composite';
import { getDisclaimerMessage } from '@/organisms/invoice-details/util';

export default function InvoiceDetails({
  isLoading,
  invoiceData,
  size = 'small',
  serviceType,
}: {
  isLoading?: boolean;
  invoiceData?: {
    invoice: Invoice | null;
    policy: Policy | null;
  } | null;
  size?: 'small' | 'large';
  serviceType: ServiceType | null;
}) {
  const { service } = useAppSelector((state) => state.bookingForm);
  const { invoice: bookingInvoice } = useAppSelector(
    (state) => state.bookingFlow,
  );
  const { publicSettings } = useAppSelector((state) => state.provider.data);
  const invoiceCheckData = invoiceData || bookingInvoice;
  const transaction = invoiceCheckData?.invoice?.transaction;
  const total = invoiceCheckData?.invoice?.total;
  const processingFeeDescription =
    'This helps us run our platform and offer services like payment processing.';
  const disclaimerMessage = getDisclaimerMessage({
    serviceType,
    publicSettings,
  });

  if (isLoading) {
    return <p className="f16 fw-400">Loading...</p>;
  }

  return (
    <div
      className={classNames(
        'flex-column',
        size === 'small' && 'gap-24',
        size === 'large' && 'gap-16',
      )}
    >
      <div
        className={classNames(
          'flex-column',
          size === 'small' && 'gap-8',
          size === 'large' && 'gap-16',
        )}
      >
        {transaction && isNotEmptyArray(transaction.details) ? (
          transaction.details.map((detail) => (
            <div
              className="flex-row justify-between gap-8 w-100"
              key={`${detail.description}-${detail.price?.localized}`}
            >
              <div className="flex-row gap-8">
                <p className="f16 fw-400">{detail.description}</p>
                {detail.description === 'Processing Fee' && (
                  <Tooltip
                    title={processingFeeDescription}
                    className="flex flex-row items-center"
                  >
                    <div>
                      <Icon name="info" />
                    </div>
                  </Tooltip>
                )}
              </div>
              <p className="f16 fw-400">{detail.price?.localized}</p>
            </div>
          ))
        ) : (
          <div className="flex-row justify-between gap-8 w-100">
            <p className="f16 fw-400">
              {service?.name || 'Service not selected'}
            </p>
            <p className="f16 fw-400">—</p>
          </div>
        )}
      </div>

      <Divider />

      <div className="flex-column gap-12">
        <div className="flex-row justify-between gap-8 w-100">
          <p
            className={classNames(
              'bold',
              size === 'small' && 'f16',
              size === 'large' && 'f18',
            )}
          >
            Est. total
          </p>
          <p
            className={classNames(
              'bold',
              size === 'small' && 'f16',
              size === 'large' && 'f18',
            )}
          >
            {total?.localized || '—'}
          </p>
        </div>
        {disclaimerMessage && (
          <p className="f14 fw-400 italic text-primary-purple">
            {disclaimerMessage}
          </p>
        )}
      </div>
    </div>
  );
}
