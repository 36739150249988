import { FormEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { paymentInit } from '@/actions/payment';
import StripeWidget from '@/organisms/stripe-widget';

const publishableKey =
  process.env.REACT_APP_MODE === 'development'
    ? 'pk_test_51Mrio5JIlaytPc9F0rVdGA6Nv1InO4QxErdUwrk1LYQmcPnv385vdTgnJmTwFWjBTJiYWrVY0Qep8VH0EPITnpLz00oQWf8szs'
    : 'pk_live_51Mrio5JIlaytPc9FcL3vwA7I8mIJpRvgz98uo5g6lVu71sJm80JuiJM0c8TNjazfpXKYM64eMtTsjoY6ZHAgSlgR005Up9CbVE';

const stripePromise = loadStripe(publishableKey);

interface AddNewCardProps {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  primaryButtonText?: string;
  runPaymentInit?: boolean;
  canSubmit?: boolean;
  hideCopy?: boolean;
  preSubmissionAction?: () => void;
  hideDiscount?: boolean;
  label?: string;
  hasSignedWaiver?: boolean;
  canContinueWithoutCard?: boolean;
}

export default function AddNewCard({
  onSubmit,
  primaryButtonText,
  runPaymentInit = true,
  canSubmit,
  hideCopy,
  preSubmissionAction,
  hideDiscount,
  label = 'Add payment method',
  hasSignedWaiver,
  canContinueWithoutCard,
}: AddNewCardProps) {
  const dispatch = useAppDispatch();
  const clientSecret = useAppSelector(
    (state) => state.main.paymentInitData?.stripe?.intent?.secret,
  );

  useEffect(() => {
    if (runPaymentInit) dispatch(paymentInit());
  }, []);

  return (
    <>
      {label && <p className="f18 bold add-new-card-label mb-15">{label}</p>}
      <Elements stripe={stripePromise} options={{ clientSecret }}>
        <StripeWidget
          canSubmit={canSubmit}
          onSubmit={onSubmit}
          hideCopy={hideCopy}
          preSubmissionAction={preSubmissionAction}
          primaryButtonText={primaryButtonText}
          hideDiscount={hideDiscount}
          hasSignedWaiver={hasSignedWaiver}
          canContinueWithoutCard={canContinueWithoutCard}
          className="white"
        />
      </Elements>
    </>
  );
}
