import './settings.scss';

import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ReactModal from 'react-modal';
import { useAppDispatch, useAppSelector } from '@/hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import useWindowDimensions from '@/helpers/use-window-dimensions';

import DefaultAvatar from '@/atoms/default-avatar';
import Icon from '@/atoms/icon';
import PaymentMethodModal from '@/organisms/payment-method-modal';
import EditUserModal from '@/organisms/user-details/user-edit';
import VerifyNumberModal from '@/organisms/verify-number-modal';
import { initializeForm } from '@/store/reducers/create-pet-form';
import { getCardIcon } from '@/util';
import { IconName } from '@/atoms/icon/icons';
import UpdatePassword from '@/pages/update-password/update-password';
import EditPetModal from '@/pages/pet-profile/components/edit-pet-modal';

function Settings() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const isMobile = width < 600;
  const { data: user } = useAppSelector((state) => state.user);
  const [isStripeModalOpen, openStripeModal] = useState(false);
  const [editedPet, setEditedPet] = useState<any>(null);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isUserEditModalOpen, setIsUserEditModalOpen] = useState(false);

  const backLink = user.animals?.[0]?.uuid
    ? `/user/${user.uuid}/pet/${user.animals?.[0]?.uuid}`
    : '/create-pet';
  const cards = user?.payment?.methods?.cards;
  const card = cards?.length > 0 ? cards[0] : null;
  const cardBrand = card?.brand;
  const cardLast4 = card?.last4;
  const cardExpirationMonth = card?.expiration?.month;
  const cardExpirationYear = card?.expiration?.year;

  const openEditModalForPet = (pet: any) => {
    dispatch(
      initializeForm({
        name: pet.name,
        breed: pet.breed,
        size: pet.size,
        hairLength: pet.hairLength,
        dateOfBirth: pet.dateOfBirth,
        gender: pet.gender,
        type: pet.type,
        ...pet.animal,
        vaccinations: pet.vaccinations,
        isSterilized: {
          id: pet.sterilization.isSterilized,
          value: pet.sterilization.isSterilized ? 'Yes' : 'No',
        },
      }),
    );
    setEditedPet(pet); // Set the currently edited pet
  };

  const closeEditModal = () => {
    setEditedPet(null); // Reset the currently edited pet
  };

  const togglePasswordModal = () => {
    setIsPasswordModalOpen(!isPasswordModalOpen);
  };

  const toggleUserEditModal = () => {
    setIsUserEditModalOpen(!isUserEditModalOpen);
  };

  return (
    <div className="settings margin-center p-15">
      <Helmet title="Settings | PawCare">
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_DOMAIN}${location.pathname}`}
        />
      </Helmet>
      <div className="w-100">
        <div className="settings-header">
          {isMobile ? (
            <button onClick={() => navigate(backLink)} className="no-style-btn">
              <Icon
                name="arrowLeft"
                className="pointer mb-20"
                fill="black"
                width={25}
                height={25}
              />
            </button>
          ) : (
            <button
              className="f13 text-grey-dark border-none b-transparent p-0"
              onClick={() => navigate(backLink)}
            >
              Profile <span className="breadcrumb-separator" /> Settings
            </button>
          )}
        </div>
        <h1 className="settings-title f26 mb-20">Settings</h1>
        <div className="settings-wrapper overflow-hidden radius-20 pb-15 mt-10 border-light-purple">
          <div className="settings-section b-white flex-row justify-between">
            <div className="settings-section-header flex-row justify-between w-100">
              <span className="f15 bold mr-10">
                <Icon
                  className="mr-12"
                  name="account"
                  width={36}
                  height={36}
                  fill="black"
                />
              </span>

              <h4>Account details</h4>
              <button
                className="no-style-btn text-orange f16 fw-100 float-right pointer settings-button"
                onClick={() => toggleUserEditModal()}
              >
                Edit
              </button>
            </div>
          </div>
          {user.animals &&
            user.animals.map((pet: any) => (
              <div key={pet.uuid} className="settings-section">
                <div className="settings-section-header flex-row justify-between w-100">
                  <span className="f15 bold mr-20">
                    <DefaultAvatar
                      size="small"
                      className="pet-avatar"
                      avatar={pet.links?.image?.desktop?.large}
                    />
                  </span>
                  <h4>{pet.name} details</h4>
                  <button
                    className="no-style-btn text-orange f16 fw-100 float-right pointer settings-button"
                    onClick={() => openEditModalForPet(pet)}
                  >
                    Edit
                  </button>
                </div>
              </div>
            ))}
          <div className="settings-section">
            <div className="settings-section-header flex-row justify-between w-100">
              <div className="float-left mt-5 w-100">
                {card ? (
                  <div className="">
                    <span className="left mr-25 f15 bold mr-20">
                      <Icon name={getCardIcon(cardBrand) as IconName} />
                    </span>
                    <p className="bold f13">**** **** **** {cardLast4}</p>
                    <p className="f11 fw-100">
                      Expires {cardExpirationMonth}/{cardExpirationYear}
                    </p>
                  </div>
                ) : (
                  <p>No payment card on file.</p>
                )}
              </div>
              <button
                className="no-style-btn text-orange f16 fw-100 float-right pointer settings-button"
                onClick={() => {
                  openStripeModal(true);
                }}
              >
                Edit
              </button>
            </div>
          </div>
          <div className="settings-section no-border-bottom">
            <div className="settings-section-header flex-row justify-between w-100">
              <span className="f15 bold">
                <Icon className="mr-12" name="lock" />
              </span>
              <h4 className="left">Password</h4>
              <button
                className="no-style-btn text-orange f16 fw-100 float-right pointer settings-button"
                onClick={togglePasswordModal}
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <VerifyNumberModal />
      <ReactModal
        ariaHideApp={false}
        isOpen={!!editedPet}
        onRequestClose={closeEditModal}
      >
        {editedPet && (
          <EditPetModal petId={editedPet.uuid} openModal={setEditedPet} />
        )}
      </ReactModal>
      <ReactModal
        ariaHideApp={false}
        isOpen={isPasswordModalOpen}
        onRequestClose={togglePasswordModal}
      >
        <UpdatePassword onSuccess={togglePasswordModal} />
      </ReactModal>
      <ReactModal
        ariaHideApp={false}
        isOpen={isUserEditModalOpen}
        onRequestClose={toggleUserEditModal}
      >
        <EditUserModal openModal={toggleUserEditModal} />
      </ReactModal>
      <PaymentMethodModal
        isOpen={isStripeModalOpen}
        openModal={openStripeModal}
        modalTitle="Update Payment"
      />
    </div>
  );
}

export default Settings;
