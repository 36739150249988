import dayjs from 'dayjs';
import { useAppSelector } from '@/hooks';
import classNames from 'classnames';

export default function AppointmentDate({
  size = 'small',
}: {
  size?: 'small' | 'large';
}) {
  const { dateStart, dateEnd, serviceType } = useAppSelector(
    (state) => state.bookingForm,
  );

  const date = !dateStart
    ? 'Date not selected'
    : `${
        serviceType === 'Boarding'
          ? dayjs(dateStart).format('MMM DD, YYYY')
          : dayjs(dateStart).format('MMM DD, YYYY • h:mm A')
      }
    ${
      dateEnd
        ? ` — ${
            serviceType === 'Boarding'
              ? dayjs(dateEnd).format('MMM DD, YYYY')
              : dayjs(dateEnd).format('h:mm A')
          }`
        : ''
    }`;

  return (
    <p
      className={classNames(
        'fw-400',
        size === 'small' && 'f16 text-primary-purple',
        size === 'large' && 'f13',
      )}
    >
      {date}
    </p>
  );
}
